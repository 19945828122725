import { AuthProvider } from 'hooks/useAuth'
import { StoreProvider } from 'hooks/useStore'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './fonts/roc-grotesk/stylesheet.css'
import '@fontsource/roboto'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'react-phone-input-2/lib/style.css'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/scrollbar'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'rc-slider/assets/index.css'
import 'utils/font-awesome'

import './index.css'
import { ApolloProvider } from '@apollo/client'
import Apollo from 'graphql/apollo'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import * as Sentry from '@sentry/react'
import moment from 'moment-timezone'

moment.tz.setDefault()

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
})

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
			<AuthProvider>
				<ApolloProvider client={Apollo.getInstance().client}>
					<StoreProvider>
						<App />
					</StoreProvider>
				</ApolloProvider>
			</AuthProvider>
		</LocalizationProvider>
	</React.StrictMode>
)
