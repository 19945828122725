import { IconName } from '../../types/icons'
import { ReactSVG } from 'react-svg'

export interface IconSvgProps {
	icon: IconName
	className?: string
	width?: number
	height?: number
}

export function IconSvg({ icon, width, height, ...props }: IconSvgProps) {
	return (
		<ReactSVG
			{...props}
			src={`/images/common/icon-svg/icon-${icon}.svg`}
			beforeInjection={svg => {
				let style = ''
				if (width) {
					style += `width: ${width}px;`
				}
				if (height) {
					style += `height: ${height}px;`
				}

				svg.setAttribute('style', style)
			}}
		/>
	)
}
