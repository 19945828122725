import { Outlet } from 'react-router-dom'
import { Logo } from 'components/common'

export function PayoutLayout() {
	return (
		<div className="h-screen">
			<div className="min-h-full flex items-stretch relative">
				<div className="absolute z-10 top-8 left-4 xs:left-8">
					<a href={import.meta.env.VITE_LANDING_URL}>
						<Logo className="h-7" />
					</a>
				</div>
				<div className="flex-1 flex flex-col">
					<Outlet />
				</div>
			</div>
		</div>
	)
}
