import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import classNames from 'classnames'

export function EmptyLayout() {
	return (
		<div>
			<Helmet
				bodyAttributes={{
					class: classNames('bg-primary-background'),
				}}
			/>
			<main className="pb-4">
				{/* Start main area*/}
				<Outlet />
				{/* End main area */}
			</main>
		</div>
	)
}
