import { EventPermissionId, EventUserInfo, User } from 'graphql/generated/scheme'

export const GetRepScoreIcon = (score: number) => {
	const repscoreFrames = ['0-10', '11-20', '21-30', '31-40', '41-50', '51-60', '61-70', '71-80', '81-90', '91-100']

	for (const frame of repscoreFrames) {
		const start = Number(frame.split('-')[0])
		const end = Number(frame.split('-')[1])

		if (score >= start && score <= end) {
			return `rep-score-${frame}`
		}
	}

	return repscoreFrames[0]
}

export const CalculateRepPointsDiscountedPrice = (
	points: number,
	discountPercentage: number,
	baseCostPerPoint: number
) => {
	return ((100 - discountPercentage) * (points * baseCostPerPoint)) / 100
}

export const GetTicketNumber = (ticketId?: string | null) => {
	return (ticketId ?? '').toString().split('-')[0].substring(0, 8).toUpperCase()
}

type EventPermissionCheckType = {
	createdBy: Partial<User> | null
	coHosts: Partial<{ nodes: Partial<User>[] }> | null
	promoters: Partial<{ nodes: Partial<User>[] }> | null
	attendeeInfo: Partial<{ attendeeInfo: Partial<EventUserInfo> | null }> | null
}
export type EventPermissionExtended = EventPermissionId | 'CanFilterStatistics' | 'CanAddVip' | 'CanAddCohost'

export const GetEventPermissions = (
	event: Partial<EventPermissionCheckType>,
	userId: string
): EventPermissionExtended[] => {
	if (event?.createdBy?.userId === userId) {
		return [...Object.values(EventPermissionId), 'CanFilterStatistics', 'CanAddVip', 'CanAddCohost']
	}

	if (event?.coHosts?.nodes?.some(x => x.userId === userId)) {
		return ((event.attendeeInfo?.attendeeInfo?.permissions as EventPermissionExtended[]) ?? []).concat([
			'CanFilterStatistics',
			'CanAddVip',
			'CanAddCohost',
		])
	}

	if (event?.promoters?.nodes?.some(x => x.userId === userId)) {
		return ((event.attendeeInfo?.attendeeInfo?.permissions as EventPermissionExtended[]) ?? []).concat([
			'CanAddVip',
		])
	}

	return event.attendeeInfo?.attendeeInfo?.permissions ?? []
}

export const HasEventPermission = (
	event: Partial<EventPermissionCheckType>,
	userId: string,
	permission: EventPermissionExtended
) => {
	return GetEventPermissions(event, userId).includes(permission)
}

export const HasAnyEventPermission = (
	event: Partial<EventPermissionCheckType>,
	userId: string,
	permissions: EventPermissionExtended[]
) => {
	for (const permission of permissions) {
		if (HasEventPermission(event, userId, permission)) {
			return true
		}
	}

	return false
}

/**
 * Download client side content as file
 */
export const DownloadBlob = (content: any, filename: string, contentType: string) => {
	// Create a blob
	const blob = new Blob([content], { type: contentType })
	const url = URL.createObjectURL(blob)

	// Create a link to download it
	const pom = document.createElement('a')
	pom.href = url
	pom.setAttribute('download', filename)
	pom.click()
}
