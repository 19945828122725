import { PropsWithChildren, Fragment, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import classNames from 'classnames'

import { useStore } from 'hooks/useStore'
import { IconSvg } from './IconSvg'

interface Props {
	open: boolean
	title?: string
	setOpen?: (...args: any) => any
	onOpened?: (...args: any) => any
	onClosed?: (...args: any) => any
	blur?: boolean
	loading?: boolean
	size?: string
	className?: string
	hideCloseButton?: boolean
}

export function ConfirmationModal({
	children,
	open,
	setOpen,
	title = '',
	onOpened,
	onClosed,
	blur,
	loading,
	size = '',
	className = '',
	hideCloseButton = true,
}: PropsWithChildren<Props>) {
	const { setBlur } = useStore()

	useEffect(() => {
		return () => {
			onClosed?.(false)
			setBlur(false)
		}
	}, [])

	useEffect(() => {
		if (open) {
			onOpened?.(open)
		} else {
			onClosed?.(open)
		}
		setBlur(blur && open)
	}, [open])

	return (
		<Transition show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				onClose={state => {
					if (!loading) {
						setOpen?.(state)
					}
				}}
			>
				<div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
					</TransitionChild>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							className={classNames(
								'relative inline-block align-bottom overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[300px] w-full',
								{
									'sm:!max-w-4xl': size === 'lg',
									'sm:!max-w-2xl': size === 'md',
									'sm:!max-w-md max-w-sm': size === 'sm',
								}
							)}
						>
							<div className="absolute top-6 right-4 z-[1]">
								<button
									onClick={() => {
										setOpen?.(false)
									}}
									className={classNames(
										'w-10 h-10 rounded-full flex justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none',
										{ '!hidden': hideCloseButton }
									)}
								>
									<IconSvg icon="close" className="w-6 h-6" />
								</button>
							</div>
							<div
								className={classNames('modal-content px-6 py-8 bg-primary-tile rounded-2xl', className)}
							>
								{!!title && (
									<div className="modal-header font-semibold text-xl mb-4 px-10">
										<DialogTitle as="h3">{title}</DialogTitle>
									</div>
								)}
								<div className="modal-body">{children}</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}
