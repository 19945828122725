import { createContext, useContext, useMemo, useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'

import { useLocalStorage } from './useLocalStorage'
import * as authService from 'services/auth'
import { AuthResponse } from 'models/response'
import { trueErrorMessage } from 'utils/error'

const AuthContext = createContext<any>(null)

export const AuthProvider = ({ children }: any) => {
	const [token, setToken] = useLocalStorage('token', null)
	const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', null)
	const [authInfo, setAuthInfo] = useLocalStorage('authInfo', null)
	const [registerUserName, setRegisterUserName] = useState<any>(null)
	const [requireOtp, setRequireOtp] = useState<any>(null)
	const [isSignedUp, setIsSignedUp] = useLocalStorage('isSignedUp', false)

	useEffect(() => {
		if (authInfo) {
			Sentry.setUser({
				id: authInfo.userId,
				email: authInfo.email,
				username: authInfo.userName,
			})
		} else {
			Sentry.setUser(null) // Clear user data when logged out
		}
	}, [authInfo])

	const clearAuth = () => {
		setToken(null)
		setRefreshToken(null)
		setAuthInfo(null)
		setRequireOtp(null)

		const authKeys = ['token', 'refreshToken', 'authInfo']
		authKeys.forEach(x => localStorage.removeItem(x))
	}

	const register = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		setRegisterUserName(data.userName)
		authService
			.passwordRegister(data)
			.then((response: any) => {
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo({
					...response,
					agreeToTermsAndConditions: data.agreeToTermsAndConditions, //Temp agree while agree mutation was called
				})
				setIsSignedUp(true)
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const resendRegisterEmail = (onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordResendRegisterEmail({ userName: registerUserName, refreshToken })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const login = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordSignIn(data)
			.then((response: any) => {
				if (response.skipMultiFactorAuthentication) {
					setRequireOtp(false)
					setToken(response.token)
				} else {
					setRequireOtp(true)
				}
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				// Identify user to Sentry here as well
				Sentry.setUser({
					id: response.userId,
					email: response.email,
					username: response.userName,
				})

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const imLogin = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordImSignIn(data)
			.then((response: any) => {
				if (response.skipMultiFactorAuthentication) {
					setRequireOtp(false)
					setToken(response.token)
				} else {
					setRequireOtp(true)
				}
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				// Identify user to Sentry here as well
				Sentry.setUser({
					id: response.userId,
					email: response.email,
					username: response.userName,
				})

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const verifyOtp = (
		otp: string,
		purpose: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.verifyOtp({ refreshToken, otp, purpose })
			.then((response: any) => {
				setRequireOtp(false)
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const resendOtp = (onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.resendOtp({ refreshToken })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const verifyEmailToken = (
		token: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.verifyEmailToken({ refreshToken: token })
			.then((response: any) => {
				setRequireOtp(false)
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const validateToken = (
		token: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.validateToken({ token })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const checkEmailAvailability = (
		data: any,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.checkEmailAvailability(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const logout = (
		onSuccess?: (data: AuthResponse) => void,
		onError?: (reason: string) => void,
		onFinal?: () => void
	) => {
		authService
			.logout()
			.then((response: any) => {
				onSuccess?.(response)
			})
			.catch((error: any) => {
				onError?.(trueErrorMessage(error.data))
			})
			.finally(() => {
				onFinal?.()
			})
	}

	const forgetPassword = (data: any, onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.passwordForget(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const recoverPassword = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordRecover(data)
			.then((response: any) => {
				setRequireOtp(true)
				setRefreshToken(response.refreshToken)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const startUpdatePassword = (onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.startUpdatePassword()
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const updatePassword = (data: any, onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.updatePassword(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const value = useMemo(
		() => ({
			requireOtp,
			token,
			refreshToken,
			authInfo,
			setToken,
			setRefreshToken,
			setAuthInfo,
			setIsSignedUp,
			isSignedUp,
			register,
			resendRegisterEmail,
			login,
			verifyOtp,
			resendOtp,
			verifyEmailToken,
			logout,
			forgetPassword,
			recoverPassword,
			clearAuth,
			validateToken,
			checkEmailAvailability,
			startUpdatePassword,
			updatePassword,
			imLogin,
		}),
		[requireOtp, token, refreshToken, JSON.stringify(authInfo)]
	)
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
	return useContext(AuthContext)
}
