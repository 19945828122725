import { PropsWithChildren, Fragment } from 'react'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import { IconSvg } from 'components/common'

interface Props {
	open: boolean
	setOpen?: (...args: any) => any
	onSuccess?: (emailAddress: string) => void
}

export function ActivatePieModal({ open, setOpen }: PropsWithChildren<Props>) {
	const navigate = useNavigate()

	return (
		<Transition show={open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => null}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<DialogBackdrop className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
					</TransitionChild>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[320px] sm:w-full rounded-2xl bg-white">
							<div className="absolute top-4 right-4 z-[1]">
								<button
									onClick={() => setOpen?.(false)}
									className="flex justify-center items-center focus:outline-none"
								>
									<IconSvg icon="close" className="w-6 h-6 text-primary-tile" />
								</button>
							</div>
							<div className="modal-content">
								<div className="modal-header relative rounded-t-2xl overflow-hidden">
									<img className="w-full" src="/images/common/pie-hub/bg-join-modal.jpg" alt="" />
								</div>
								<div className="relative">
									<div className="-mt-20 pb-9 max-w-[300px] mx-auto">
										<DialogTitle as="h3" className="text-primary-panel font-bold text-2xl">
											Congrats! You were awarded a Pie!
										</DialogTitle>
										<div className="text-sm leading-[18px] text-black-50 text-center mt-3 px-8">
											You've met the requirements to skip the waiting list wist.
										</div>
										<div className="text-m text-primary text-center font-bold mt-3 px-8">
											Please connect your paypal account so that you may collect earnings
										</div>
									</div>
									<div className="modal-body text-left pt-2 px-6 pb-8">
										<div className="text-center">
											<button
												className="px-6 rounded-3xl transition-all duration-300 bg-primary h-12 hover:bg-primary-hover disabled:backdrop-blur-lg font-bold w-full"
												type="button"
												onClick={() => {
													navigate('/pie-hub')
													setOpen?.(false)
												}}
											>
												Activate
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}
