import { Fragment, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { debounce } from 'lodash'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { Menu, Transition } from '@headlessui/react'

import { AvatarImage, IconSvg, PieImage, SimpleTable } from 'components/common'
import { PieType, UserTier, useGetPieBySlugOrIdLazyQuery, useGetPiesLazyQuery } from 'graphql/generated/scheme'
import { DEFAULT_INFORMATION } from 'utils/constants'
import { currencyFormat } from 'utils/common'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { trueErrorMessage } from 'utils/error'

interface Props {
	id?: any
	loading?: boolean
	autoSuggestion?: boolean
	className?: string
	placeholder?: any
	excludeValues?: any
	onValueChange?: (...args: any) => any
	defaultValue?: any
	disabled?: boolean
}

const validationSchema = Yup.object().shape({
	q: Yup.string(),
})

export function PieSearchInput({
	id,
	loading,
	autoSuggestion,
	className,
	placeholder,
	excludeValues,
	onValueChange,
	defaultValue,
	disabled,
}: Props) {
	const [selectedItem, setSelectedItem] = useState<any>(null)
	const [pies, setPies] = useState<Array<any>>([])
	const [openSuggestion, setOpenSuggestion] = useState<boolean>(false)
	const { register, setValue } = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur',
	})
	const [
		getPies,
		{ data: dataPies, loading: loadingPies, error: errorPies, called: calledPies, refetch: refetchPies },
	] = useGetPiesLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
	})
	const [getPieBySlugOrId] = useGetPieBySlugOrIdLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		nextFetchPolicy: 'no-cache',
		onCompleted: dataPie => {
			const oPie = dataPie?.pie?.bySlugOrId
			setValue('q', oPie?.displayName)
			setSelectedItem(oPie)
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})
	const handleClickOutside = () => {
		setOpenSuggestion(false)
	}
	const ref = useOutsideClick(handleClickOutside)
	const debounceDropDown = useCallback(
		debounce((nextValue, isEnterKey) => {
			if (!!autoSuggestion && !loadingPies && !isEnterKey) {
				if ((nextValue || '').trim().length > 0) {
					setOpenSuggestion(true)
					if (calledPies) {
						refetchPies({
							keyword: nextValue,
						})
					} else {
						getPies({
							variables: {
								first: 10,
								type: PieType.Featured,
								keyword: nextValue,
								repbetsPieFirst: false,
							},
						})
					}
				} else {
					setPies([])
				}
			}
		}, 1000),
		[]
	)

	useEffect(() => {
		if (defaultValue) {
			getPieBySlugOrId({ variables: { slugOrId: defaultValue } as any })
		}
	}, [defaultValue])

	useEffect(() => {
		if (dataPies) {
			setPies(dataPies?.pie?.search2?.nodes.filter(item => !excludeValues.includes(item.pieId)) || [])
		}
		if (errorPies) {
			toast.error(errorPies.message)
		}
	}, [dataPies, errorPies])

	const handleInputOnchange = (e: any) => {
		const { value } = e.target
		debounceDropDown(value, e.key === 'Enter')
	}

	return (
		<div ref={ref}>
			<Menu as="div" className="relative">
				<div className="relative">
					<div className="absolute top-1/2 left-4 -mt-4">
						<PieImage
							className="w-8 h-8 rounded-md overflow-hidden flex-shrink-0"
							images={[selectedItem?.pieAvatarUrl]}
						/>
					</div>
					<input
						id={id}
						className={classNames(
							'px-4 pl-14 py-3 border-2 border-white-10 bg-white-10 rounded-lg text-white placeholder-white-50 placeholder:italic placeholder:font-light block w-full h-12',
							className
						)}
						placeholder={placeholder}
						type="text"
						{...register('q')}
						onKeyUp={handleInputOnchange}
						disabled={loading || loadingPies || disabled}
					/>
				</div>
				{!!autoSuggestion && (
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
						show={openSuggestion}
					>
						<Menu.Items
							className="absolute right-0 rounded-lg shadow-lg bg-indigo-60b focus:outline-none mt-2 w-full z-10 py-4"
							static
						>
							<SimpleTable
								noHeader
								columns={[
									{
										content: {
											className: 'col-span-5',
											formatter: (item: any) => {
												return (
													<div className="flex space-x-3 items-top">
														<div className="relative flex-shrink-0">
															<AvatarImage
																className="h-11 w-11 rounded-full object-cover"
																src={item.createdBy?.avatarUrl}
																alt={item.createdBy?.name}
															/>
															{item.createdBy?.isVerified && (
																<div className="absolute bottom-0 right-0">
																	<IconSvg
																		icon="verified"
																		className={classNames('text-[#308DE8]', {
																			'!text-yellow-400':
																				UserTier.Premium ===
																				item.createdBy.tier,
																		})}
																	/>
																</div>
															)}
														</div>
														<div>
															<div className="font-bold leading-5">
																{item.createdBy?.name || DEFAULT_INFORMATION.name}
															</div>
															<div className="flex items-center space-x-1 mt-1 text-secondary-text">
																<IconSvg icon="rank-small" />
																<span className="text-sm font-normal">
																	{item.rank || DEFAULT_INFORMATION.rank}
																</span>
															</div>
														</div>
													</div>
												)
											},
										},
									},
									{
										content: {
											className: 'col-span-5',
											formatter: (item: any) => {
												return (
													<div>
														<div className="leading-5 font-normal">{item.displayName}</div>
														<div className="flex items-center space-x-4 mt-2">
															<div className="font-bold">
																{currencyFormat(item.slicePrice?.value)}
															</div>
															{/* TODO: Replace item.rank with 24h change value */}
															{/* <div className="flex items-center space-x-1">
																<IconSvg
																	icon="decrease"
																	className={classNames({
																		hidden: item.rank > 0,
																	})}
																/>
																<IconSvg
																	icon="increase"
																	className={classNames({
																		hidden: item.rank <= 0,
																	})}
																/>
																<div
																	className={classNames('font-bold', {
																		'text-secondary-red': item.rank <= 0,
																		'text-secondary-green': item.rank > 0,
																	})}
																>
																	{item.rank || DEFAULT_INFORMATION.rank}
																</div>
															</div> */}
														</div>
													</div>
												)
											},
										},
									},
									{
										content: {
											className: 'col-span-2 flex justify-end',
											formatter: (item: any) => {
												return (
													<PieImage
														className="w-11 h-11 rounded-lg overflow-hidden flex-shrink-0"
														images={[item.pieAvatarUrl]}
													/>
												)
											},
										},
									},
								]}
								row={{
									className: 'items-center',
									formatter: (index: number, item: any, htmlContent: any, row: any) => {
										return (
											<Menu.Item key={index} as={Fragment}>
												{({ active }) => (
													<button
														type="button"
														className={classNames(
															`hover:bg-white-5 grid grid-cols-12 w-full text-left`,
															{ 'bg-white-5': active },
															row?.className
														)}
														onClick={() => {
															setOpenSuggestion(false)
															setValue('q', item.displayName)
															setSelectedItem(item)
															onValueChange?.(item)
														}}
													>
														{htmlContent}
													</button>
												)}
											</Menu.Item>
										)
									},
								}}
								loading={loading || loadingPies}
								currentPage={0}
								data={pies}
								totalCount={10}
								pageSize={10}
								noDataText="No search results"
							/>
						</Menu.Items>
					</Transition>
				)}
			</Menu>
		</div>
	)
}
