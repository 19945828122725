import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

import { useAuth } from 'hooks/useAuth'
import {
	FullEvent,
	SearchEventTypeEnum,
	useGetCurrentUserLazyQuery,
	useGetManagedClubsLazyQuery,
	useGetMyEventsLazyQuery,
	useGetMyPiesLazyQuery,
	useGetTermsConditionsAndPrivacyQuery,
	useGetTransactionFeesLazyQuery,
} from 'graphql/generated/scheme'
import Apollo from 'graphql/apollo'
import { useLocalStorage } from './useLocalStorage'
import { trueErrorMessage } from 'utils/error'
import { ManageClubType } from 'graphql/type'

const emptyAppearance = { background: '', napkin: '', plate: '', crust: '', filling: '', topping: '', fork: '' }

const defaultRequirement = {
	type: undefined,
	amount: undefined,
	pieId: undefined,
	achievementIds: [],
}

const StoreContext = createContext<any>(null)

TimeAgo.addDefaultLocale(en)

export const StoreProvider = ({ children }: any) => {
	const apolloClient = Apollo.getInstance().client
	const { authInfo, setAuthInfo, clearAuth } = useAuth()
	const [title, setTitle] = useState<any>(null)
	const [header, setHeader] = useState<any>(null)
	const [hasBackArrow, setHasBackArrow] = useState<any>(true)
	const [backTo, setBackTo] = useState<any>(null)
	const [blur, setBlur] = useState<boolean>(false)
	const [pieHubDetail, setPieHubDetail] = useState<any>(null)
	const [pieHubAppearance, setPieHubAppearance] = useState<any>(emptyAppearance)
	const [pieHubSlices, setPieHubSlices] = useState<any>(null)
	const [pieHubReward, setPieHubReward] = useLocalStorage('pieHubReward', null)
	const [pieHubAchievement, setPieHubAchievement] = useLocalStorage('pieHubAchievement', null)
	const [loadingLogout, setLoadingLogout] = useState<boolean>(false)
	const [myPies, setMyPies] = useLocalStorage('myPies', null)
	const [myPie, setMyPie] = useLocalStorage('myPie', null)
	const [selectedPie, setSelectedPie] = useLocalStorage('selectedPie', null)
	const [myModeratorPies, setMyModeratorPies] = useLocalStorage('myModeratorPies', null)
	const [user, setUser] = useLocalStorage('user', null)
	const [allPies, setAllPies] = useState<any>(null)
	const [currentTerms, setCurrentTerms] = useState<any>(null)
	const [openDeleteAccount, setOpenDeleteAccount] = useState<boolean>(false)
	const [didYouKnowContent, setDidYouKnowContent] = useState<any>(null)
	const [transactionFees, setTransactionFees] = useState<any>(null)
	const [hasShowedOnboadingAlready, setHasShowedOnboadingAlready] = useLocalStorage('hasShowedOnboadingAlready', null)
	const [openCongratulationsModal, setOpenCongratulationsModal] = useState<boolean>(false)
	const [congratulationsModalContent, setCongratulationsModalContent] = useState<any>(null)
	const [payouts, setPayouts] = useState<any>(null)
	const [transferringPieInfo, setTransferringPieInfo] = useState<any>(null)
	const [myEvents, setMyEvents] = useState<any[]>([])
	const [myClubs, setMyClubs] = useState<ManageClubType[]>([])
	const [selectedClub, setSelectedClub] = useLocalStorage('selectedClub', null)
	const [selectedEvent, setSelectedEvent] = useState<Partial<FullEvent>>()

	const [getCurrentUser, { loading: loadingUser, refetch: refetchUser }] = useGetCurrentUserLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			const me = data?.me || null
			if (me) {
				setUser(me)
				setAuthInfo({
					...authInfo,
					agreeToTermsAndConditions: me.agreeToTermsAndConditions,
					isComplete: !!me.name && !!me.userName && !!me.dateOfBirth && !!me.phoneNumber && !!me.gender,
				})
			}
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})
	const [getMyEvents, { loading: loadingMyEvents, refetch: refetchMyEvents }] = useGetMyEventsLazyQuery({
		variables: {
			first: 5,
			criteria: {
				eventType: SearchEventTypeEnum.All,
			},
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setMyEvents(data?.event?.myEvents?.nodes ?? [])
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})

	const [getMyClubs, { loading: loadingMyClubs, refetch: refetchMyClubs }] = useGetManagedClubsLazyQuery({
		variables: {},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setMyClubs(data?.me?.managedClubs ?? [])
			setSelectedClub(data?.me?.managedClubs?.[0])
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})

	const [getMyPies, { loading: loadingMyPies, refetch: refetchMyPies }] = useGetMyPiesLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			const myPiesData = data?.pie?.myPies?.nodes?.filter(item => !item.isTransfering) || []
			const myModeratorPiesData = data?.pie?.myModeratorPies?.nodes || []
			const allPiesData = myPiesData?.concat(myModeratorPiesData)
			setMyPies(myPiesData || null)
			setMyModeratorPies(myModeratorPiesData || null)
			setAllPies(allPiesData)

			const transferredPie = myPies.find(
				(item: any) => item.pieId === transferringPieInfo?.find((pItem: any) => pItem.key === 'PieId')?.value
			)
			if (transferredPie) {
				setSelectedPie(transferredPie)
				setTransferringPieInfo(null)
			} else {
				const updateSelectedPie = allPiesData.find((x: any) => x.pieId === selectedPie?.pieId)
				if (updateSelectedPie) {
					setSelectedPie(updateSelectedPie)
				} else {
					setSelectedPie(allPiesData[0])
				}
			}
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})
	const { loading: loadingTermsConditionsAndPrivacy } = useGetTermsConditionsAndPrivacyQuery({
		onCompleted: data => {
			setCurrentTerms(data?.termsAndConditions?.current || null)
		},
	})
	const [getTransactionFees, { loading: transactionFeesLoading, refetch: transactionFeesRefetch }] =
		useGetTransactionFeesLazyQuery({
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache',
			onCompleted: data => {
				setTransactionFees(data?.paypal.options || null)
			},
			onError: error => {
				toast.error(trueErrorMessage(error.message))
			},
		})

	const clearStore = () => {
		setLoadingLogout(true)
		apolloClient.clearStore().finally(() => {
			setHeader(null)
			setHasBackArrow(true)
			setBlur(false)
			setPieHubDetail(null)
			setPieHubAppearance(emptyAppearance)
			setPieHubSlices(null)
			setLoadingLogout(false)
			setMyPies(null)
			setUser(null)
			setHasShowedOnboadingAlready(null)
			setPayouts(null)
			setMyPie(null)
			setSelectedPie(null)
			setMyModeratorPies(null)
			setAllPies(null)
			setBackTo(null)
			clearAuth()
		})
	}

	useEffect(() => {
		if (myPies?.length) {
			setMyPie(myPies[0])
		}
	}, [myPies])

	const value = useMemo(
		() => ({
			header,
			setHeader,
			hasBackArrow,
			setHasBackArrow,
			blur,
			setBlur,
			pieHubDetail,
			setPieHubDetail,
			emptyAppearance,
			pieHubAppearance,
			setPieHubAppearance,
			pieHubSlices,
			setPieHubSlices,
			user,
			setUser,
			getCurrentUser,
			refetchUser,
			loadingUser,
			clearStore,
			loadingLogout,
			setLoadingLogout,
			title,
			setTitle,
			myPies,
			setMyPies,
			getMyPies,
			refetchMyPies,
			loadingMyPies,
			myModeratorPies,
			myPie,
			setMyPie,
			selectedPie,
			setSelectedPie,
			currentTerms,
			openDeleteAccount,
			setOpenDeleteAccount,
			loadingTermsConditionsAndPrivacy,
			didYouKnowContent,
			setDidYouKnowContent,
			pieHubReward,
			setPieHubReward,
			pieHubAchievement,
			setPieHubAchievement,
			transactionFees,
			setTransactionFees,
			getTransactionFees,
			transactionFeesLoading,
			transactionFeesRefetch,
			defaultRequirement,
			hasShowedOnboadingAlready,
			setHasShowedOnboadingAlready,
			openCongratulationsModal,
			setOpenCongratulationsModal,
			congratulationsModalContent,
			setCongratulationsModalContent,
			payouts,
			setPayouts,
			backTo,
			setBackTo,
			transferringPieInfo,
			setTransferringPieInfo,
			allPies,
			myEvents,
			loadingMyEvents,
			refetchMyEvents,
			getMyEvents,
			getMyClubs,
			refetchMyClubs,
			loadingMyClubs,
			myClubs,
			selectedClub,
			setSelectedClub,
			selectedEvent,
			setSelectedEvent,
		}),
		[
			header,
			hasBackArrow,
			blur,
			JSON.stringify(pieHubDetail),
			JSON.stringify(pieHubAppearance),
			JSON.stringify(pieHubSlices),
			JSON.stringify(user),
			loadingUser,
			loadingLogout,
			title,
			JSON.stringify(myPies),
			loadingMyPies,
			JSON.stringify(myModeratorPies),
			myPie,
			selectedPie,
			JSON.stringify(currentTerms),
			openDeleteAccount,
			loadingTermsConditionsAndPrivacy,
			didYouKnowContent,
			JSON.stringify(pieHubReward),
			JSON.stringify(pieHubAchievement),
			transactionFeesLoading,
			JSON.stringify(transactionFees),
			hasShowedOnboadingAlready,
			openCongratulationsModal,
			JSON.stringify(congratulationsModalContent),
			JSON.stringify(payouts),
			backTo,
			transferringPieInfo,
			allPies,
			myEvents,
			loadingMyEvents,
			myClubs,
			loadingMyClubs,
			selectedClub,
			selectedEvent,
		]
	)
	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStore = () => {
	return useContext(StoreContext)
}
