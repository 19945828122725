import { useLocation, Navigate, useParams, useSearchParams } from 'react-router-dom'
import { boolean } from 'boolean'

import { useAuth } from 'hooks/useAuth'
import { objectToSearchString } from 'serialize-query-params'
// import { useStore } from 'hooks/useStore'

export function InviteGuard({ children }: { children: JSX.Element }) {
	const { id } = useParams()
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const email = searchParams.get('email')
	const searchParamsString = objectToSearchString({ email })
	const { token, authInfo, requireOtp } = useAuth()
	// const { user } = useStore()

	if (requireOtp) {
		return (
			<Navigate
				to={`/auth/mfa${searchParamsString ? `?${searchParamsString}` : ''}`}
				state={{ from: location, inviteId: id }}
				replace
			/>
		)
	} else {
		if (token && authInfo) {
			const { isDeleted, isComplete } = authInfo
			if (boolean(isDeleted)) {
				return (
					<Navigate
						to={`/auth/recover${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location, inviteId: id }}
						replace
					/>
				)
			}
			if (!boolean(isComplete)) {
				return (
					<Navigate
						to={`/auth/sign-up-2${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location, inviteId: id }}
						replace
					/>
				)
			}
			// if (user?.canCreatePies) {
			// 	return <Navigate to="/pie-hub" state={{ from: location, inviteId: id }} replace />
			// }
		} else {
			return (
				<Navigate
					to={`/auth/sign-up${searchParamsString ? `?${searchParamsString}` : ''}`}
					state={{ from: location, inviteId: id }}
					replace
				/>
			)
		}
	}
	return children
}
