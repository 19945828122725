import { Navigate, useLocation, useSearchParams } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'

export function PaymentGuard({ children }: { children: JSX.Element }) {
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const urlToken = searchParams.get('urlToken')
	const { token, requireOtp } = useAuth()

	if (requireOtp) {
		return <Navigate to="/auth/mfa" state={{ from: location }} replace />
	} else {
		if (!token && !urlToken) {
			return <Navigate to="/auth/login" state={{ from: location }} replace />
		}
	}
	return children
}
