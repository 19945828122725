import { useSearchParams, Navigate } from 'react-router-dom'

export function PayoutProcessGuard({ children }: { children: JSX.Element; extended?: boolean }) {
	const [searchParams] = useSearchParams()
	const code = searchParams.get('code')

	if (!code) {
		return <Navigate to="/404" state={{ from: location }} replace />
	}
	return children
}
