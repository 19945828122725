import { ApolloError } from '@apollo/client'

const defaultErrorMessage = `Oops! Looks like something went wrong. Please try again or contact support.` // errorCode
function messageOrDefault(message: string | undefined): string {
	return message ?? defaultErrorMessage
}

export function trueErrorMessage(error: string | ApolloError) {
	console.error('Unexpected error', error)
	if (error instanceof ApolloError) {
		if (error.message) {
			return messageOrDefault(error.message)
		} else {
			const errorCode = (error.networkError as any)?.result?.errors[0]?.message
			if (errorCode) {
				return messageOrDefault(errorCode)
			}
		}
	} else {
		if (error) {
			return messageOrDefault(error)
		}
	}

	return defaultErrorMessage
}
