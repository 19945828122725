import { PropsWithChildren, useState } from 'react'
import classNames from 'classnames'
import isUrlHttp from 'is-url-http'

import { DEFAULT_INFORMATION } from 'utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
	images: any[]
	className?: string
	forceUri?: boolean
	enabledWaiting?: boolean
}

export function PieImage({ images, className, forceUri, enabledWaiting = false, ...props }: PropsWithChildren<Props>) {
	const [loading, setLoading] = useState<boolean>(true)
	let mediaDomain = `${import.meta.env.VITE_MEDIA_URL}/`
	if (forceUri) {
		mediaDomain = ''
	}

	const isNotEmptyImages = () => {
		return images.some(item => !!item)
	}

	return (
		<div {...props} className={classNames('bg-white-25', className)}>
			<div className="relative w-full h-full">
				{isNotEmptyImages() ? (
					images.map((image: string, index: number) => (
						<div
							key={index}
							className={classNames('w-full h-full', { 'absolute top-0 left-0': index !== 0 })}
						>
							{!!image && (
								<img
									className="w-full h-full object-cover"
									src={`${
										!isUrlHttp(image) && image.indexOf('data:') === -1 ? mediaDomain : ''
									}${image}?t=${new Date().getTime()}`}
									onLoad={() => {
										setLoading(false)
									}}
									onError={() => {
										setLoading(true)
									}}
								/>
							)}
						</div>
					))
				) : (
					<div className={classNames('w-full h-full')}>
						<img className="w-full h-full object-cover" src={DEFAULT_INFORMATION.pieImageUrl} />
					</div>
				)}
				{enabledWaiting && loading && isNotEmptyImages() && (
					<>
						<div className={classNames('w-full h-full absolute top-0 left-0')}>
							<img className="w-full h-full object-cover" src={DEFAULT_INFORMATION.pieImageUrl} />
						</div>
						<div className="flex justify-center items-center py-4 absolute top-0 left-0 w-full h-full bg-black bg-opacity-50">
							<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
						</div>
					</>
				)}
			</div>
		</div>
	)
}
