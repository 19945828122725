import { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, NavLink } from 'react-router-dom'
import { Dialog, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import { useStore } from 'hooks/useStore'
import { Logo, IconSvg } from 'components/common'
import { NAVIGATION_ITEMS } from 'utils/constants'

export function PublicLayout() {
	const { header } = useStore()
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

	return (
		<div>
			<Helmet
				bodyAttributes={{
					class: classNames(
						'bg-primary-background'
						// , {
						// 	'sm:overflow-visible overflow-hidden':
						// 		isAllowComponent({
						// 			excludeRoutes: ['/reward/:id', '/rewards/:id', '/pies/:id'],
						// 		}) && isIOS,
						// }
					),
				}}
			/>
			<div className="flex min-h-screen max-w-[1440px] mx-auto">
				<Transition show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<DialogBackdrop className="fixed inset-0 bg-black-75" />
						</TransitionChild>
						<TransitionChild
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div className="relative flex-1 flex flex-col w-full bg-black-75 focus:outline-none">
								<TransitionChild
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute bottom-6 right-1/2 -mr-6 pt-2">
										<button
											type="button"
											className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close Sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</TransitionChild>
								<div className="flex-1 h-0 p-6">
									<div className="px-4 flex items-center justify-center">
										<a href="/">
											<Logo className="h-8" />
										</a>
									</div>
									<nav
										className="flex-1 mt-8 max-h-[calc(100vh-32px-56px-32px-56px)] overflow-auto"
										aria-label="Sidebar"
									>
										<div className="space-y-4">
											{NAVIGATION_ITEMS.map(item => (
												<NavLink
													key={item.name}
													to={item.href}
													className={({ isActive }) =>
														`group flex flex-col items-center px-4 py-3 transition-all duration-300 text-xl justify-center ${
															isActive
																? 'text-white'
																: 'text-secondary-text hover:text-white'
														}`
													}
													reloadDocument
												>
													{({ isActive }) => (
														<>
															<IconSvg
																icon={item.icon}
																className={classNames('w-6', {
																	'text-primary': isActive,
																	'text-secondary-text': !isActive,
																})}
																aria-hidden="true"
															/>
															{item.name}
														</>
													)}
												</NavLink>
											))}
										</div>
									</nav>
								</div>
							</div>
						</TransitionChild>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:flex lg:flex-shrink-0 min-[1440px]:w-[1440px] w-full fixed top-0 left-1/2 -translate-x-1/2">
					<div className="flex flex-col w-[232px] absolute top-0 left-0 h-screen">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex-1 flex flex-col min-h-0 px-4">
							<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
								<div className="px-4">
									<a href="/">
										<Logo className="sm:h-7 h-6" />
									</a>
								</div>
								<nav className="flex-1 mt-8" aria-label="Sidebar">
									<div className="space-y-2">
										{NAVIGATION_ITEMS.map(item => (
											<NavLink
												key={item.name}
												to={item.href}
												className={({ isActive }) =>
													classNames(
														'group flex items-center px-4 py-3 transition-all duration-300 font-bold',
														{
															'text-primary': isActive,
															'text-secondary-text hover:text-primary': !isActive,
															'pointer-events-none text-white-10': item.disabled,
														}
													)
												}
											>
												{() => (
													<>
														<IconSvg
															icon={item.icon}
															className={classNames('mr-2 w-6')}
															aria-hidden="true"
														/>
														{item.name}
													</>
												)}
											</NavLink>
										))}
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col min-w-0 flex-1 lg:pl-[232px]">
					<div className="h-[72px]">
						<div className="min-[1440px]:w-[1440px] w-full fixed top-0 left-1/2 -translate-x-1/2 z-[3]">
							<div className="lg:flex-none flex justify-between items-center lg:w-[calc(100%-232px)] w-full absolute right-0 top-0 bg-primary-background">
								<div className="lg:hidden">
									<div className="flex items-center sm:px-8 py-4 space-x-4 px-4">
										<div>
											<button
												type="button"
												className="h-10 w-10 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
												onClick={() => setSidebarOpen(true)}
											>
												<span className="sr-only">Open Sidebar</span>
												<Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
										<div>
											<a href="/">
												<Logo className="sm:h-7 h-6" />
											</a>
										</div>
									</div>
								</div>
								<div className="lg:flex justify-start items-center px-8 py-4 text-white-75 hidden">
									{header !== null && (
										<div className="text-2xl h-10 flex items-center whitespace-nowrap">
											{header}
										</div>
									)}
								</div>
								<div className="sm:px-8 py-4 px-4">
									<div className="flex items-center sm:space-x-10 space-x-4">
										<a href={`/auth/login`} className="font-medium">
											Login
										</a>
										<a
											href={`/auth/sign-up`}
											className="sm:px-6 px-4 rounded-3xl h-10 flex items-center justify-center bg-primary font-medium whitespace-nowrap"
										>
											Join Now
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<main className="pb-4">
						{/* Start main area*/}
						<Outlet />
						{/* End main area */}
					</main>
				</div>
			</div>
		</div>
	)
}
