import { matchPath, useLocation } from 'react-router-dom'

export function ComponentGuard({
	children,
	includeRoutes,
	excludeRoutes,
}: {
	children: JSX.Element
	includeRoutes?: any[]
	excludeRoutes?: any[]
}) {
	const { pathname } = useLocation()
	let isValid = false
	if (includeRoutes) {
		isValid = !!includeRoutes.find(route => matchPath(route, pathname))
	}
	if (excludeRoutes) {
		isValid = !excludeRoutes.find(route => matchPath(route, pathname))
	}
	return isValid ? children : null
}

export function useAllowComponent({ includeRoutes, excludeRoutes }: { includeRoutes?: any[]; excludeRoutes?: any[] }) {
	const { pathname } = useLocation()
	let isValid = false
	if (includeRoutes) {
		isValid = !!includeRoutes.find(route => matchPath(route, pathname))
	}
	if (excludeRoutes) {
		isValid = !excludeRoutes.find(route => matchPath(route, pathname))
	}
	return isValid
}
