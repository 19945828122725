import { Navigate, useLocation, matchPath } from 'react-router-dom'

import { OnboardingProcess, PieOperationalState } from 'graphql/generated/scheme'
import { useStore } from 'hooks/useStore'
import { PieHubProvider } from 'hooks/usePieHub'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function PieHubGuard({ children }: { children: JSX.Element; extended?: boolean }) {
	const { pathname } = useLocation()
	const {
		user,
		loadingMyPies,
		pieHubDetail,
		setPieHubReward,
		setPieHubAchievement,
		setPieHubDetail,
		setPieHubAppearance,
		emptyAppearance,
		setPieHubSlices,
		myPie,
		transferringPieInfo,
		allPies,
	} = useStore()

	if (loadingMyPies && !myPie && !user) {
		return (
			<div className="flex justify-center items-center py-4 h-screen">
				<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
			</div>
		)
	}

	if (
		[OnboardingProcess.Complete, OnboardingProcess.None].includes(myPie?.onBoardingProcess) &&
		!transferringPieInfo &&
		!!allPies?.length
	) {
		if (myPie.operationalState === PieOperationalState.Pending) {
			return <Navigate to="/payment/checkout" state={{ isInternal: true }} replace />
		}
		const matchedSpecialRoutes = ['/pie-hub/rewards/create', '/pie-hub/achievements/create']
		const matchedRoutes = [
			'/pie-hub/overview',
			'/pie-hub/overview/edit/:id',
			'/pie-hub/sales',
			'/pie-hub/send/:id',
			'/pie-hub/rewards',
			'/pie-hub/rewards/:id',
			'/pie-hub/rewards/edit/:id',
			'/pie-hub/achievements',
			'/pie-hub/achievements/:id',
			'/pie-hub/achievements/edit/:id',
			...matchedSpecialRoutes,
		]
		const isNotMatchedSpecialRoutes = !matchedSpecialRoutes.find(route => matchPath(route, pathname))
		if (isNotMatchedSpecialRoutes) {
			setPieHubAchievement(null)
			setPieHubReward(null)
		}

		const isNotMatched = !matchedRoutes.find(route => matchPath(route, pathname))
		if (isNotMatched) {
			return <Navigate to="/pie-hub/overview" state={{ pieId: myPie.pieId }} replace />
		}
	} else {
		const matchedMainRoutes = ['/pie-hub']
		const matchedSpecialRoutes = ['/pie-hub/details', '/pie-hub/appearance', '/pie-hub/slices', '/pie-hub/summary']
		const matchedRoutes = [...matchedMainRoutes, ...matchedSpecialRoutes]

		const isNotMatchedMainRoutes = !matchedMainRoutes.find(route => matchPath(route, pathname))
		const isNotMatchedSpecialRoutes = !matchedSpecialRoutes.find(route => matchPath(route, pathname))
		if (isNotMatchedSpecialRoutes) {
			setPieHubDetail(null)
			setPieHubAppearance(emptyAppearance)
			setPieHubSlices(null)
		}
		const isNotMatched = !matchedRoutes.find(route => matchPath(route, pathname))

		if (
			!allPies?.length &&
			!matchPath('/pie-hub', pathname) &&
			(isNotMatched ||
				(!user?.canCreatePies &&
					isNotMatchedMainRoutes &&
					[OnboardingProcess.Complete, OnboardingProcess.None].includes(myPie?.onBoardingProcess)))
		) {
			return <Navigate to="/pie-hub" state={{}} replace />
		}

		const [, , ...stepMatchedRoutes] = matchedRoutes
		const isStepMatched = !!stepMatchedRoutes.find(route => matchPath(route, pathname))
		if (isStepMatched && !pieHubDetail) {
			return <Navigate to="/pie-hub/details" state={{}} replace />
		}
	}
	return <PieHubProvider>{children}</PieHubProvider>
}
