import axios, { AxiosResponse } from 'axios'

import * as authService from 'services/auth'
import { AuthResponse } from 'models/response'

const instance = axios.create({
	baseURL: `/api`,
	headers: {
		'Content-Type': 'application/json',
	},
})

const identifyUnauthorizeResponse = (response: AxiosResponse) => {
	if (response.status === 401) {
		return true
	}

	if (
		response.data?.errors &&
		response.data?.errors.find(
			(x: { extensions: { code: string } }) => x.extensions?.code === 'AUTH_NOT_AUTHENTICATED'
		)
	) {
		return true
	}

	return false
}

const handleUnauthorizeResponse = async (response: AxiosResponse, config: any) => {
	// Token was expired
	if (identifyUnauthorizeResponse(response) && !config._retry) {
		config._retry = true
		try {
			const res = await authService.refresh(authService.getRefreshToken())
			const { token, refreshToken } = res as any as AuthResponse
			authService.setToken(token)
			authService.setRefreshToken(refreshToken)
			return instance(config)
		} catch (_error) {
			localStorage.clear()
			window.location.reload()

			return Promise.reject(_error)
		}
	}
}

instance.interceptors.request.use(
	(config: any) => {
		const newConfig = { ...config }
		const token = authService.getToken()
		if (token) {
			newConfig.headers.Authorization = `Bearer ${token}`
		}
		return newConfig
	},
	(error: any) => {
		return Promise.reject(error)
	}
)

instance.interceptors.response.use(
	(response: AxiosResponse) => {
		if (identifyUnauthorizeResponse(response)) {
			return handleUnauthorizeResponse(response, response.config)
		}

		return response.data
	},
	async (error: any) => {
		const { config: originalConfig, response } = error
		if (!originalConfig.url.startsWith('/auth') && response) {
			return handleUnauthorizeResponse(response, originalConfig)
		}

		return Promise.reject(response)
	}
)

export default instance
