import { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import type { PDFDocumentProxy } from 'pdfjs-dist'
import classNames from 'classnames'
import { IconSvg } from '.'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules'

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (typeof Promise.withResolvers === 'undefined') {
	if (window)
		// @ts-expect-error This does not exist outside of polyfill which this is doing
		window.Promise.withResolvers = function () {
			let resolve, reject
			const promise = new Promise((res, rej) => {
				resolve = res
				reject = rej
			})
			return { promise, resolve, reject }
		}
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/legacy/build/pdf.worker.min.mjs', import.meta.url).toString()

type Props = {
	file: any
	width?: number
}

export function PdfPreview({ file, width }: Props) {
	const [numPages, setNumPages] = useState<number>(1)
	const [showZoom, setShowZoom] = useState<boolean>(false)

	const onDocumentLoadSuccess = ({ numPages: nextNumPages }: PDFDocumentProxy): void => {
		setNumPages(nextNumPages)
		setShowZoom(true)
	}

	return (
		<div className="pdf-view relative">
			<Swiper
				direction={'vertical'}
				slidesPerView={'auto'}
				freeMode={true}
				scrollbar={true}
				mousewheel={true}
				modules={[FreeMode, Scrollbar, Mousewheel]}
				className="mySwiper"
			>
				<SwiperSlide>
					<Document file={file} onLoadError={console.error} onLoadSuccess={onDocumentLoadSuccess}>
						{Array.from({ length: numPages }, (_, index) => (
							<Page
								key={`page_${index + 1}`}
								pageNumber={index + 1}
								// renderAnnotationLayer={false}
								// renderTextLayer={false}
								width={width || 600}
								className={'border'}
								// scale={2}
							/>
						))}
					</Document>
				</SwiperSlide>
			</Swiper>
			<a
				className={classNames(
					'absolute top-6 right-2 z-10 w-8 h-8 rounded-full flex justify-center items-center transition-all duration-300 bg-black bg-opacity-30 text-white font-bold hover:bg-opacity-40 disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg',
					{ '!hidden': !showZoom }
				)}
				href={file}
				target="_blank"
			>
				<IconSvg icon="search" width={20} />
			</a>
		</div>
	)
}
