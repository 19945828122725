import { PropsWithChildren, Fragment, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import classNames from 'classnames'
import TimeAgo from 'javascript-time-ago'

import { IconSvg, AvatarImage, PieImage, SimpleTable } from 'components/common'
import {
	NotificationType,
	UserTier,
	useGetNotificationsQuery,
	useReadAllNotificationMutation,
	useReadNotificationMutation,
} from 'graphql/generated/scheme'
import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'

const timeAgo = new TimeAgo('en-US')

interface Props {
	position?: string
}

const LIMIT = 10

export function NotificationDropdown({ position }: PropsWithChildren<Props>) {
	const { token } = useAuth()
	const { user, setTransferringPieInfo } = useStore()
	const navigate = useNavigate()
	const [notifications, setNotifications] = useState<Array<any>>([])
	const [countUnreadNotifications, setCountUnreadNotifications] = useState<number>(0)
	const { loading } = useGetNotificationsQuery({
		variables: {
			first: LIMIT,
		},
		pollInterval: 5000,
		skip: !token || !user,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setNotifications(data.notification?.listNotifications2?.nodes || [])
			setCountUnreadNotifications(data.notification?.countUnreadNotifications)
		},
	})
	const [readNotification, { loading: readNotificationLoading }] = useReadNotificationMutation()
	const [readAllNotification, { loading: readAllNotificationLoading }] = useReadAllNotificationMutation({
		onCompleted: () => {
			setNotifications(notifications.map(item => ({ ...item, isRead: true })))
		},
	})

	return (
		<div>
			<Menu as="div" className="relative text-left">
				<div className="flex items-center">
					<MenuButton className="relative">
						<IconSvg icon="notifications" />
						{!!countUnreadNotifications && (
							<span className="absolute -top-1 -right-1 bg-primary rounded-[10px] text-xs leading-[14px] text-center px-1 py-[1px] font-bold">
								{countUnreadNotifications}
							</span>
						)}
					</MenuButton>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<MenuItems
						className={classNames(
							'absolute lg:-right-2 -right-14 rounded-lg shadow-lg bg-[#302645] focus:outline-none mt-5 pb-6 lg:w-[573px] w-[350px] z-10 overflow-hidden',
							{
								'origin-top-right': position !== 'top',
								'origin-bottom-right bottom-full': position === 'top',
							}
						)}
					>
						<div className="flex items-center justify-between px-3 py-4">
							<MenuItem>
								<Link
									to={`/profile/notifications`}
									className="text-primary hover:text-primary-hover transition-all duration-300 font-bold"
								>
									View all
								</Link>
							</MenuItem>
							<div>
								<button
									className="text-primary hover:text-primary-hover transition-all duration-300 font-bold"
									type="button"
									onClick={() => readAllNotification()}
									disabled={readAllNotificationLoading}
								>
									Mark all as read
								</button>
							</div>
						</div>
						<div className="lg:max-h-[700px] max-h-[500px] overflow-auto">
							<SimpleTable
								noBorder
								noHeader
								columns={[
									{
										content: {
											className: 'align-top col-span-12 !p-0 text-left',
											formatter: (item: any) => {
												return (
													<div className="flex space-x-3 items-top pr-10">
														<div className="flex-shrink-0">
															{[NotificationType.ReceiveAchievement].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<AvatarImage
																	className="h-11 w-11 rounded-lg object-cover overflow-hidden bg-white-25"
																	src={
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) =>
																				pItem.key === 'AchievementAvatar'
																		)?.value
																	}
																/>
															)}
															{[
																NotificationType.ReceiveReward,
																NotificationType.GenerateRewardWinnersReminder,
																NotificationType.AwardedReward,
																NotificationType.RewardLoser,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<AvatarImage
																	className="h-11 w-11 rounded-lg object-cover overflow-hidden bg-white-25"
																	src={
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'RewardAvatar'
																		)?.value
																	}
																/>
															)}
															{[
																NotificationType.CreatePie,
																NotificationType.NewWisherAdded,
																NotificationType.WishedPieActivated,
																NotificationType.BuySlices,
																NotificationType.PieOwnershipTransferRequest,
																NotificationType.PieOwnershipTransferAccepted,
																NotificationType.PieOwnershipTransferRejected,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<PieImage
																	className="w-11 h-11 rounded-lg overflow-hidden"
																	images={[
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'PieAvatar'
																		)?.value,
																	]}
																/>
															)}
															{[NotificationType.SomeoneBuySlices].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="relative">
																	<AvatarImage
																		className="h-11 w-11 rounded-full object-cover"
																		src={
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'BuyerAvatar'
																			)?.value
																		}
																	/>
																	{item.content.defaultPayload.customProperties.find(
																		(pItem: any) => pItem.key === 'BuyerIsVerified'
																	)?.value && (
																		<div className="absolute bottom-0 right-0">
																			<IconSvg
																				icon="verified"
																				className={classNames(
																					'text-[#308DE8]',
																					{
																						'!text-yellow-400':
																							UserTier.Premium ===
																							item.content.defaultPayload.customProperties.find(
																								(pItem: any) =>
																									pItem.key ===
																									'BuyerTier'
																							)?.value,
																					}
																				)}
																			/>
																		</div>
																	)}
																</div>
															)}
															{[
																NotificationType.SendSlices,
																NotificationType.AwardPie,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="relative">
																	<AvatarImage
																		className="h-11 w-11 rounded-full object-cover"
																		src={
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'ReceiverAvatar'
																			)?.value
																		}
																	/>
																	{item.content.defaultPayload.customProperties.find(
																		(pItem: any) =>
																			pItem.key === 'ReceiverIsVerified'
																	)?.value && (
																		<div className="absolute bottom-0 right-0">
																			<IconSvg
																				icon="verified"
																				className={classNames(
																					'text-[#308DE8]',
																					{
																						'!text-yellow-400':
																							UserTier.Premium ===
																							item.content.defaultPayload.customProperties.find(
																								(pItem: any) =>
																									pItem.key ===
																									'ReceiverTier'
																							)?.value,
																					}
																				)}
																			/>
																		</div>
																	)}
																</div>
															)}
															{[NotificationType.ReceiveSlices].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="relative">
																	<AvatarImage
																		className="h-11 w-11 rounded-full object-cover"
																		src={
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'SenderAvatar'
																			)?.value
																		}
																	/>
																	{item.content.defaultPayload.customProperties.find(
																		(pItem: any) => pItem.key === 'SenderIsVerified'
																	)?.value && (
																		<div className="absolute bottom-0 right-0">
																			<IconSvg
																				icon="verified"
																				className={classNames(
																					'text-[#308DE8]',
																					{
																						'!text-yellow-400':
																							UserTier.Premium ===
																							item.content.defaultPayload.customProperties.find(
																								(pItem: any) =>
																									pItem.key ===
																									'SenderTier'
																							)?.value,
																					}
																				)}
																			/>
																		</div>
																	)}
																</div>
															)}
															{[
																NotificationType.UserInviteAccepted,
																NotificationType.UserPassedWaitingList,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<img
																	className="w-11 h-11 rounded-2xl overflow-hidden"
																	src="/images/default/pie-hub.png"
																/>
															)}
															{[NotificationType.UserInvitesIncreased].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<img
																	className="w-11 h-11 rounded-2xl overflow-hidden"
																	src="/images/logos/repbets-badge.svg"
																/>
															)}
															{[
																NotificationType.EventCoHostInvitation,
																NotificationType.EventPromoteInvitation,
																NotificationType.EventPending,
																NotificationType.EventApproved,
																NotificationType.EventDeclined,
																NotificationType.EventInvitation,
																NotificationType.EventStarted,
																NotificationType.EventRoleUpdate,
																NotificationType.EventCheckinSuccess,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<AvatarImage
																	className="h-11 w-11 rounded-lg object-cover overflow-hidden bg-white-25"
																	src={
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'EventAvatar'
																		)?.value
																	}
																/>
															)}
														</div>
														<div>
															{[NotificationType.ReceiveAchievement].includes(
																item.content?.defaultPayload?.notificationType
															) && <div className="font-bold">Achievement</div>}
															{[
																NotificationType.ReceiveReward,
																NotificationType.GenerateRewardWinnersReminder,
																NotificationType.AwardedReward,
																NotificationType.RewardLoser,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && <div className="font-bold">Reward</div>}
															{[
																NotificationType.CreatePie,
																NotificationType.NewWisherAdded,
																NotificationType.WishedPieActivated,
																NotificationType.BuySlices,
																NotificationType.PieOwnershipTransferRequest,
																NotificationType.PieOwnershipTransferAccepted,
																NotificationType.PieOwnershipTransferRejected,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="font-bold">
																	{
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'PieName'
																		)?.value
																	}
																</div>
															)}
															{[NotificationType.SomeoneBuySlices].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="font-bold">
																	{
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'BuyerName'
																		)?.value
																	}
																</div>
															)}
															{[
																NotificationType.SendSlices,
																NotificationType.AwardPie,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="font-bold">
																	{
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'ReceiverName'
																		)?.value
																	}
																</div>
															)}
															{[NotificationType.ReceiveSlices].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="font-bold">
																	{
																		item.content.defaultPayload.customProperties.find(
																			(pItem: any) => pItem.key === 'SenderName'
																		)?.value
																	}
																</div>
															)}
															{[
																NotificationType.UserInviteAccepted,
																NotificationType.UserPassedWaitingList,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && <div className="font-bold">Pie Invite</div>}
															{[NotificationType.UserInvitesIncreased].includes(
																item.content?.defaultPayload?.notificationType
															) && <div className="font-bold">Repbets</div>}
															{[
																NotificationType.EventCoHostInvitation,
																NotificationType.EventPromoteInvitation,
																NotificationType.EventPending,
																NotificationType.EventApproved,
																NotificationType.EventDeclined,
																NotificationType.EventInvitation,
																NotificationType.EventStarted,
																NotificationType.EventRoleUpdate,
																NotificationType.EventCheckinSuccess,
															].includes(
																item.content?.defaultPayload?.notificationType
															) && (
																<div className="font-bold">
																	{item.content.defaultPayload.customProperties.find(
																		(pItem: any) => pItem.key === 'EventName'
																	)?.value || 'Event'}
																</div>
															)}
															<div className="text-sm leading-[18px] text-left font-normal mt-1">
																{item.content.defaultMessage}
															</div>
															<div className="text-xs leading-[18px] font-normal mt-1 text-secondary-text">
																{timeAgo.format(new Date(item.createdAt))}
															</div>
														</div>
													</div>
												)
											},
										},
									},
								]}
								row={{
									className: 'items-center',
									formatter: (index: number, item: any, htmlContent: any) => {
										return (
											<MenuItem key={index} as={Fragment}>
												{() => (
													<button
														type="button"
														className={classNames(
															'block px-3 hover:bg-white-5 w-full relative',
															{
																'text-white': !item.isRead,
																'text-white-50': item.isRead,
															}
														)}
														onClick={() => {
															readNotification({
																variables: { id: item.notificationId },
															})
															switch (item.content?.defaultPayload?.notificationType) {
																case NotificationType.CreatePie:
																	navigate('/pie-hub/overview')
																	break
																case NotificationType.ReceiveSlices:
																case NotificationType.BuySlices:
																case NotificationType.SendSlices:
																	navigate('/slices')
																	break
																case NotificationType.SomeoneBuySlices:
																	navigate('/pie-hub/sales')
																	break
																case NotificationType.ReceiveReward:
																case NotificationType.GenerateRewardWinnersReminder:
																case NotificationType.AwardedReward:
																case NotificationType.RewardLoser:
																	navigate(
																		`/pies/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) => pItem.key === 'PieSlug'
																			)?.value
																		}/rewards/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'RewardSlug'
																			)?.value
																		}`
																	)
																	break
																case NotificationType.ReceiveAchievement:
																	navigate(
																		`/profile?achievement=${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'AchievementId'
																			)?.value
																		}`
																	)
																	break
																case NotificationType.UserInviteAccepted:
																case NotificationType.UserPassedWaitingList:
																case NotificationType.NewWisherAdded:
																	navigate('/pie-hub')
																	break
																case NotificationType.UserInvitesIncreased:
																	navigate('/profile/invites')
																	break
																case NotificationType.AwardPie:
																	navigate('/pie-hub/details')
																	break
																case NotificationType.WishedPieActivated:
																	navigate(
																		`/pies/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) => pItem.key === 'PieSlug'
																			)?.value
																		}`
																	)
																	break
																case NotificationType.PieOwnershipTransferRequest:
																	setTransferringPieInfo(
																		item.content.defaultPayload.customProperties
																	)
																	navigate('/pie-hub')
																	break
																case NotificationType.EventCoHostInvitation:
																case NotificationType.EventPromoteInvitation:
																case NotificationType.EventInvitation:
																	navigate(
																		`/accept-invite/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'FormattedInviteId'
																			)?.value
																		}`
																	)
																	break
																case NotificationType.EventApproved:
																case NotificationType.EventDeclined:
																case NotificationType.EventStarted:
																case NotificationType.EventCheckinSuccess:
																	navigate(
																		`/events/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'EventSlug'
																			)?.value
																		}`
																	)
																	break
																case NotificationType.EventRoleUpdate: {
																	const roleType =
																		item.content.defaultPayload.customProperties
																			.find(
																				(pItem: any) => pItem.key === 'RoleType'
																			)
																			?.value?.toLowerCase()
																	const attendeeRole =
																		item.content.defaultPayload.customProperties
																			.find(
																				(pItem: any) =>
																					pItem.key === 'AttendeeRole'
																			)
																			?.value?.toLowerCase()
																	let navigateUrlRoot = ''

																	if (
																		['cohost', 'promoter'].includes(roleType) ||
																		['coordinator'].includes(attendeeRole)
																	) {
																		navigateUrlRoot = '/rep-club'
																	}
																	navigate(
																		`${navigateUrlRoot}/events/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'EventSlug'
																			)?.value
																		}`
																	)
																	break
																}
																case NotificationType.EventPending:
																	navigate(
																		`/rep-club/events/${
																			item.content.defaultPayload.customProperties.find(
																				(pItem: any) =>
																					pItem.key === 'EventSlug'
																			)?.value
																		}/approval`
																	)
																	break
																default:
																	navigate('/profile/notifications')
															}
														}}
														onMouseEnter={() =>
															((window as any).notificationItem = setTimeout(() => {
																if (!readNotificationLoading && !item.isRead) {
																	readNotification({
																		variables: { id: item.notificationId },
																	})
																	setNotifications(
																		notifications.map(nItem => {
																			if (
																				item.notificationId ===
																				nItem.notificationId
																			) {
																				return { ...nItem, isRead: true }
																			}
																			return nItem
																		})
																	)
																}
															}, 2000))
														}
														onMouseLeave={() =>
															clearTimeout((window as any).notificationItem)
														}
													>
														<div className="grid grid-cols-12 py-4 transition-all duration-300 border-t border-white-10">
															{htmlContent}
														</div>
														{!item.isRead && (
															<button
																type="button"
																className="w-12 h-full absolute right-0 top-0"
																onClick={e => {
																	e.stopPropagation()
																	readNotification({
																		variables: {
																			id: item.notificationId,
																		},
																	})
																	setNotifications(
																		notifications.map(nItem => {
																			if (
																				item.notificationId ===
																				nItem.notificationId
																			) {
																				return { ...nItem, isRead: true }
																			}
																			return nItem
																		})
																	)
																}}
																disabled={readNotificationLoading}
															>
																<span className="w-3 h-3 bg-primary rounded-full absolute right-6 top-1/2 -mt-1.5 hover:bg-primary-hover transition-all duration-300" />
															</button>
														)}
													</button>
												)}
											</MenuItem>
										)
									},
								}}
								loading={loading}
								data={notifications}
								noPaging
								noDataText="No notifications"
							/>
						</div>
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	)
}
