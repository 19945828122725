import { PropsWithChildren, Fragment, useState, useEffect } from 'react'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import OtpInput from 'react-otp-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { IconSvg } from 'components/common'

interface Props {
	open: boolean
	setOpen?: (...args: any) => any
	handleOnSubmit?: (...args: any) => any
	handleOnResend?: (...args: any) => any
}

const validationSchema = Yup.object().shape({
	otp: Yup.string().required('').min(6, ''),
})

export function OtpModal({ open, setOpen, handleOnSubmit, handleOnResend }: PropsWithChildren<Props>) {
	const [submitting, setSubmitting] = useState(false)
	const [resending, setResending] = useState(false)
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues,
		reset,
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onChange',
	})

	useEffect(() => {
		reset({ otp: '' })
	}, [])

	const onSubmit = (values: any) => {
		setSubmitting(true)
		handleOnSubmit?.(values, setSubmitting)
	}

	const onResend = () => {
		setResending(true)
		handleOnResend?.(setResending)
	}

	return (
		<Transition show={open} as={Fragment}>
			<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => {}}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
					</TransitionChild>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
							<div className="absolute top-6 right-4 z-[1]">
								<button
									onClick={() => setOpen?.(false)}
									className="w-10 h-10 rounded-full flex justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
								>
									<IconSvg icon="close" className="w-6 h-6" />
								</button>
							</div>
							<div className="modal-content p-8 pb-10 bg-primary-tile rounded-2xl">
								<div className="modal-header font-medium text-xl leading-6 mb-8">
									<DialogTitle as="h3">OTP Confirm</DialogTitle>
								</div>
								<div className="modal-body">
									<div className="w-full mt-16">
										<div className="text-white text-center mb-4">Enter your code</div>
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="space-y-4">
												{!(getValues('otp')?.length > 6) && (
													<div>
														<Controller
															control={control}
															name="otp"
															render={({ field }) => (
																<OtpInput
																	value={field.value}
																	onChange={(otp: string) => field.onChange(otp)}
																	numInputs={6}
																	containerStyle="!grid grid-cols-6 gap-2"
																	inputStyle="px-4 py-3 border-2 border-white-10 bg-white-10 rounded-lg text-white placeholder-white-50 placeholder:italic placeholder:font-light block !w-12 h-12 text-center"
																	renderInput={props => (
																		<input {...props} type="tel" />
																	)}
																/>
															)}
														/>
														{errors.otp && (
															<div className="text-secondary-red mt-2 text-sm">
																{errors.otp.message as string}
															</div>
														)}
													</div>
												)}
											</div>
											<div className="mt-6">
												<button
													type="submit"
													className="px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-primary text-white font-bold block w-full h-12 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg"
													disabled={!isValid || submitting}
												>
													{submitting && (
														<FontAwesomeIcon
															icon={['fas', 'spinner']}
															size="lg"
															spin
															className="mr-1"
														/>
													)}
													Continue
												</button>
											</div>
										</form>
										<div className="mt-16">
											<h3 className="text-center text-white mb-6">Didn't get the code?</h3>
											<div className="text-center">
												<button
													className="px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-white-10 text-white font-bold block w-full h-12 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
													onClick={() => onResend()}
													disabled={resending}
												>
													{resending && (
														<FontAwesomeIcon
															icon={['fas', 'spinner']}
															size="lg"
															spin
															className="mr-1"
														/>
													)}
													Resend
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}
