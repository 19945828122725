import {
	ApolloClient,
	ApolloLink,
	from,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	ServerError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as authService from 'services/auth'
import * as Sentry from '@sentry/browser'
import { trueErrorMessage } from 'utils/error'

export default class Apollo {
	private static instance: Apollo

	public client: ApolloClient<NormalizedCacheObject>

	httpLink = new HttpLink({
		uri: '/api/graphql',
		headers: {
			'Content-Type': 'application/json',
		},
	})

	authLink = new ApolloLink((operation, forward) => {
		// add the authorization to the headers
		const token = authService.getToken()
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		}))

		return forward(operation)
	})

	errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
		if (
			(networkError as ServerError)?.statusCode === 401 ||
			graphQLErrors?.some(
				err =>
					['AUTH_NOT_AUTHORIZED', 'AUTH_NOT_AUTHENTICATED'].includes(err.extensions?.code as string) ||
					err.message === 'Unauthorized'
			)
		) {
			Sentry.captureMessage('Unauthorized access attempt', {
				level: 'warning',
				extra: {
					operationName: operation.operationName,
					variables: operation.variables,
				},
			})

			localStorage.clear()
			window.location.reload()
			return forward(operation)
		}

		if (graphQLErrors) {
			graphQLErrors.forEach(error => {
				Sentry.captureMessage('GraphQL Error', {
					level: 'error',
					extra: {
						operationName: operation.operationName,
						variables: operation.variables,
						message: error.message,
						path: error.path,
						locations: error.locations,
						extensions: error.extensions,
					},
				})
			})
		}

		if (networkError) {
			Sentry.captureMessage('Network Error', {
				level: 'error',
				extra: {
					operationName: operation.operationName,
					variables: operation.variables,
					message: networkError.message,
					name: networkError.name,
					stack: networkError.stack,
				},
			})
		}
	})

	afterwareLink = new ApolloLink((operation, forward) => {
		return forward(operation).map(response => {
			if (response.errors) {
				response.errors.forEach(error => {
					Sentry.captureMessage(trueErrorMessage(error.message), {
						level: 'error',
						extra: {
							operationName: operation.operationName,
							variables: operation.variables,
							message: error.message,
							path: error.path,
							locations: error.locations,
							extensions: error.extensions,
						},
					})
				})
			}
			return response
		})
	})

	private constructor() {
		this.client = new ApolloClient({
			link: from([this.authLink, this.errorLink, this.afterwareLink, this.httpLink]),
			cache: new InMemoryCache(),
		})
	}

	static getInstance(): Apollo {
		if (!this.instance) {
			this.instance = new Apollo()
		}
		return this.instance
	}
}
