import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetIndividualAssetQuery } from 'graphql/generated/scheme'
import { groupBy, isEmpty, sortBy } from 'lodash'
import { createContext, useContext, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { ASSET_CATEGORY_ITEMS } from 'utils/constants'
import { trueErrorMessage } from 'utils/error'
import { useStore } from './useStore'

const PieHubContext = createContext<any>(null)

export const PieHubProvider = ({ children }: any) => {
	const { pieHubAppearance, setPieHubAppearance } = useStore()
	const [appearanceAssets, setAppearanceAssets] = useState<any>(null)
	const { loading: getIndividualAssetLoading } = useGetIndividualAssetQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setAppearanceAssets(groupBy(sortBy(data.individual_asset?.list, ['priority']), 'category'))
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})

	const randomPieHubAppearance = () => {
		if (!isEmpty(appearanceAssets)) {
			const asset = { ...pieHubAppearance }
			ASSET_CATEGORY_ITEMS.map(category => {
				const assetList = [...appearanceAssets[category.value.toUpperCase()]]
				if (assetList.length) {
					const index = Math.floor(Math.random() * assetList.length)
					asset[category.value] = assetList[index]
				}
				return category
			})
			setPieHubAppearance(asset)
		}
	}

	const value = useMemo(
		() => ({
			appearanceAssets,
			setAppearanceAssets,
			getIndividualAssetLoading,
			randomPieHubAppearance,
		}),
		[appearanceAssets, getIndividualAssetLoading]
	)

	if (!appearanceAssets) {
		return (
			<div className="mt-4 lg:w-[calc(100%-232px)]">
				<div className="flex justify-center items-center py-4">
					<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
				</div>
			</div>
		)
	}

	return <PieHubContext.Provider value={value}>{children}</PieHubContext.Provider>
}

export const usePieHub = () => {
	return useContext(PieHubContext)
}
