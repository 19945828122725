import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { IconSvg } from 'components/common'
import { Fragment, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

interface Props {
	position?: string
}

export default function CreateActionDropdown({ position }: PropsWithChildren<Props>) {
	return (
		<div>
			<Menu as="div" className="relative text-left">
				<div className="flex items-center">
					<MenuButton className="relative">
						<IconSvg icon="add-2" />
					</MenuButton>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<MenuItems
						className={classNames(
							'absolute right-0 rounded-lg shadow-box bg-primary-tile focus:outline-none mt-1 px-4 pb-[16px] pt-[20px] w-[220px] z-10 ring-1 ring-black ring-opacity-5',
							{
								'origin-top-right': position !== 'top',
								'origin-bottom-right bottom-full': position === 'top',
							}
						)}
					>
						<div className="space-y-1 mb-2">
							<p className="text-base font-medium mb-4">Create</p>
							<MenuItem>
								{({ active }) => (
									<Link
										to="/rep-club/events/create?tab=info"
										className={classNames('py-3 flex space-x-2 items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
										})}
									>
										<IconSvg icon="event" className="text-white w-5" aria-hidden="true" />
										<span>Event</span>
									</Link>
								)}
							</MenuItem>
							{import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true' && (
								<>
									<MenuItem>
										{({ active }) => (
											<Link
												to="/my/rep-clubs"
												className={classNames('py-3 flex space-x-2 items-center', {
													'text-white font-bold': active,
													'text-white-75 hover:text-white hover:font-bold': !active,
												})}
											>
												<IconSvg icon="rewards" className="text-white w-5" aria-hidden="true" />
												<span>Offer</span>
											</Link>
										)}
									</MenuItem>
									<MenuItem>
										{({ active }) => (
											<Link
												to="/my/rep-clubs"
												className={classNames('py-3 flex space-x-2 items-center', {
													'text-white font-bold': active,
													'text-white-75 hover:text-white hover:font-bold': !active,
												})}
											>
												<IconSvg icon="cup" className="text-white w-5" aria-hidden="true" />
												<span>Achievement</span>
											</Link>
										)}
									</MenuItem>
								</>
							)}
						</div>
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	)
}
