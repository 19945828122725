import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import reactStringReplace from 'react-string-replace'
import axios from 'axios'

import { useStore } from 'hooks/useStore'
import { ContentModal } from 'components/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
	label: any
	params?: any
}

export function TermsOfServiceAndPrivacyBlock({ label, params }: Props) {
	const { currentTerms } = useStore()
	const scrollToRef = useRef<HTMLDivElement>(null)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [term, setTerm] = useState<string>('')
	const [allowOk, setAllowOk] = useState<boolean>(false)
	const [acceptTerm, setAcceptTerm] = useState<boolean>(false)
	const [acceptPrivacy, setAcceptPrivacy] = useState<boolean>(false)
	const [termsOfServiceContent, setTermsOfServiceContent] = useState<any>('')
	const [privacyPolicyContent, setPrivacyPolicyContent] = useState<any>('')

	useEffect(() => {
		const axiosConfig = {
			headers: {
				'Content-Type': 'text/html',
				'Access-Control-Allow-Origin': '*',
			},
		}
		if (currentTerms) {
			axios.get(currentTerms.termsAndConditionsUrl, axiosConfig).then(response => {
				setTermsOfServiceContent(response.data)
			})
			// .catch(error => {})
			axios.get(currentTerms.privacyPolicyUrl, axiosConfig).then(response => {
				setPrivacyPolicyContent(response.data)
			})
			// .catch(error => {})
		}
	}, [currentTerms])

	const handleScroll = (event: any) => {
		const isBottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight
		if (isBottom) {
			setAllowOk(true)
		}
	}

	const renderLabel = () =>
		reactStringReplace(label, /#(\w+)/g, (match, i) => (
			<a
				key={i}
				className="text-primary cursor-pointer"
				onClick={() => {
					setTerm(match === 'termsOfService' ? 'Terms of Service' : 'Privacy Policy')
					setOpenModal(true)
					setTimeout(() => {
						scrollToRef.current?.scrollTo(0, 0)
					}, 100)
				}}
			>
				{params[match]}
			</a>
		))

	const createMarkup = (html: any) => {
		return { __html: html }
	}

	return (
		<div>
			<div className="flex justify-start">
				<span className="transition-all duration-300 text-sm text-center px-4">{renderLabel()}</span>
			</div>
			<ContentModal title={term} open={openModal} setOpen={setOpenModal} hideCloseButton size="lg">
				<div
					className="text-left h-[calc(100vh-300px)] overflow-auto"
					ref={scrollToRef}
					onScroll={handleScroll}
				>
					<div className={classNames({ hidden: term === 'Privacy Policy' })}>
						{termsOfServiceContent ? (
							<div dangerouslySetInnerHTML={createMarkup(termsOfServiceContent)} />
						) : (
							<div className="flex justify-center items-center py-4">
								<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
							</div>
						)}
					</div>
					<div className={classNames({ hidden: term === 'Terms of Service' })}>
						{privacyPolicyContent ? (
							<div dangerouslySetInnerHTML={createMarkup(privacyPolicyContent)} />
						) : (
							<div className="flex justify-center items-center py-4">
								<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="text-white" />
							</div>
						)}
					</div>
				</div>
				<div className="mt-10 flex justify-center">
					<button
						onClick={() => {
							setOpenModal(false)
							setAllowOk(false)
							if (term === 'Terms of Service') {
								setAcceptTerm(true)
							}
							if (term === 'Privacy Policy') {
								setAcceptPrivacy(true)
							}
						}}
						className="w-32 h-10 rounded-full flex justify-center items-center transition-all duration-300 bg-primary text-white font-bold hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg"
						disabled={
							!allowOk &&
							((!acceptTerm && term === 'Terms of Service' && termsOfServiceContent) ||
								(!acceptPrivacy && term === 'Privacy Policy' && privacyPolicyContent))
						}
					>
						OK
					</button>
				</div>
			</ContentModal>
		</div>
	)
}
