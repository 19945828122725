import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { boolean } from 'boolean'

import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'
import { ActivatePieModal, ContentModal, PieImage } from 'components/common'
import { UpdateTermsOfServiceAndPrivacyModal, DeleteAccountBlock } from 'containers/common'
import { ComponentGuard } from 'guards'
import { OnboardingProcess } from 'graphql/generated/scheme'
import { numberFormat } from 'utils/common'

export function EventLayout() {
	const navigate = useNavigate()
	const { token, authInfo } = useAuth()
	const {
		blur,
		loadingLogout,
		openDeleteAccount,
		setOpenDeleteAccount,
		refetchUser,
		refetchMyPies,
		myPie,
		hasShowedOnboadingAlready,
		setHasShowedOnboadingAlready,
		openCongratulationsModal,
		setOpenCongratulationsModal,
		congratulationsModalContent,
		setCongratulationsModalContent,
	} = useStore()
	const [openActivatePieModal, setOpenActivatePieModal] = useState<boolean>(false)

	useEffect(() => {
		if (token && authInfo) {
			const { isDeleted, isComplete } = authInfo
			refetchUser()
			if (!boolean(isDeleted) && boolean(isComplete)) {
				refetchMyPies()
			}
		}
	}, [token, JSON.stringify(authInfo)])

	useEffect(() => {
		if (
			myPie &&
			![OnboardingProcess.Complete, OnboardingProcess.None].includes(myPie.onBoardingProcess) &&
			!hasShowedOnboadingAlready
		) {
			setTimeout(() => {
				setOpenActivatePieModal(true)
				setHasShowedOnboadingAlready(true)
			}, 1000)
		}
	}, [myPie])

	return (
		<div className={classNames({ blur })}>
			<Helmet
				bodyAttributes={{
					class: classNames('bg-primary-background'),
				}}
			/>
			<div className="min-h-screen mx-auto">
				<main className="pb-20">
					{/* Start main area*/}
					<Outlet />
					{/* End main area */}
				</main>
			</div>
			<UpdateTermsOfServiceAndPrivacyModal />
			<ActivatePieModal open={openActivatePieModal} setOpen={setOpenActivatePieModal} />
			<ContentModal
				open={openCongratulationsModal}
				setOpen={setOpenCongratulationsModal}
				isModal
				className="!bg-primary-panel"
			>
				<div>
					<div className="flex flex-col items-center space-y-5">
						<div className="max-w-xs">
							<div className="flex justify-center items-center">
								<PieImage
									className="w-[150px] h-[150px] rounded-xl overflow-hidden flex-shrink-0"
									images={[congratulationsModalContent?.pie?.pieAvatarUrl]}
								/>
							</div>
							<div className="pt-8">
								<h3 className="text-2xl font-medium mb-5 flex justify-center items-center gap-2">
									Congratulations!
								</h3>
								<p>
									You have successfully purchased{' '}
									{numberFormat(congratulationsModalContent?.sliceAmount || 0)} slice
									{congratulationsModalContent?.sliceAmount > 1 ? 's' : ''} of{' '}
									{congratulationsModalContent?.pie?.displayName} Pie.
								</p>
							</div>
							<div className="flex justify-center items-center mt-5">
								<img
									className="w-full"
									src="/images/common/user-guide/img-reward-graz.png"
									alt="Congratulations reward"
								/>
							</div>
						</div>
						<div className="max-w-md">
							<div>
								{congratulationsModalContent?.source === 'reward'
									? 'Each Pie has its own exclusive rewards. Ready to discover more Pies?'
									: 'When you acquire slices of a Pie, you unlock rewards. Each Pie has its own exclusive rewards.'}
							</div>
						</div>
					</div>
					<div className="mt-8 grid grid-cols-1 gap-4">
						<button
							type="button"
							className={classNames(
								'px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-primary text-white font-bold block w-full h-12 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg',
								{
									'!bg-secondary-button hover:!bg-secondary-button-hover order-last':
										congratulationsModalContent?.source === 'reward',
								}
							)}
							onClick={() => {
								setOpenCongratulationsModal(false)
								setCongratulationsModalContent(null)
								navigate('/home')
							}}
						>
							Explore More Pies
						</button>
						<button
							type="button"
							className={classNames(
								'px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-primary text-white font-bold block w-full h-12 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg',
								{
									'!bg-secondary-button hover:!bg-secondary-button-hover':
										congratulationsModalContent?.source !== 'reward',
								}
							)}
							onClick={() => {
								setOpenCongratulationsModal(false)
								setCongratulationsModalContent(null)
								navigate('/rewards')
							}}
						>
							Explore Rewards
						</button>
					</div>
				</div>
			</ContentModal>
			<ComponentGuard includeRoutes={['/profile/information', '/profile/personal']}>
				<DeleteAccountBlock openDelete={openDeleteAccount} setOpenDelete={setOpenDeleteAccount} />
			</ComponentGuard>
			{loadingLogout && (
				<div className="flex justify-center items-center py-4 w-full h-full fixed top-0 left-0 bg-primary-panel bg-opacity-90 z-[9999]">
					<FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin className="text-white" />
				</div>
			)}
		</div>
	)
}
