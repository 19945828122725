import { useLocation, Navigate, matchPath } from 'react-router-dom'
import { boolean } from 'boolean'

import { useAuth } from 'hooks/useAuth'
import { objectToSearchString } from 'serialize-query-params'
import { useLocalStorage } from 'hooks/useLocalStorage'

export function AuthGuard({ children, exceptedRoutes }: { children: JSX.Element; exceptedRoutes?: any[] }) {
	const location = useLocation()
	const { pathname } = location
	const { token, authInfo, requireOtp } = useAuth()
	const [isSignedUp] = useLocalStorage('isSignedUp', false)

	if (requireOtp) {
		return <Navigate to="/auth/mfa" state={{ from: location }} replace />
	} else {
		if (token && authInfo) {
			const { isDeleted, isComplete } = authInfo
			if (boolean(isDeleted)) {
				return <Navigate to="/auth/recover" state={{ from: location }} replace />
			}
			if (!boolean(isComplete)) {
				return <Navigate to="/auth/sign-up-2" state={{ from: location }} replace />
			}
		} else {
			const to = `${location.pathname}${location.search}`
			const searchParamsString = objectToSearchString({ to })
			// const matchedRoutes = ['/pies/:id', '/rewards/:id', '/pies/:id/rewards/:rewardId']
			// const isMatched = !!matchedRoutes.find(route => matchPath(route, to))
			// if (!isMatched) {
			// 	searchParamsString = ''
			// }
			if (exceptedRoutes?.find(route => matchPath(route, pathname))) {
				return children
			}

			if (isSignedUp) {
				return (
					<Navigate
						to={`/auth/login${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location }}
						replace
					/>
				)
			} else {
				return (
					<Navigate
						to={`/auth/sign-up${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location }}
						replace
					/>
				)
			}
		}
	}
	return children
}
