import { useLocation, Navigate, useSearchParams } from 'react-router-dom'
import { searchStringToObject, objectToSearchString } from 'serialize-query-params'
import { boolean } from 'boolean'

import { useAuth } from 'hooks/useAuth'

export function MfaGuard({ children }: { children: JSX.Element }) {
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const a = searchParams.get('a')
	const to = searchParams.get('to')
	const searchParamsString = objectToSearchString({ a, to })
	const { from, inviteId } = location.state || {}
	const { token, requireOtp, authInfo } = useAuth()

	if (!requireOtp) {
		if (token && authInfo) {
			const { isDeleted, isComplete } = authInfo
			if (boolean(isDeleted)) {
				return (
					<Navigate
						to={`/auth/recover${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location, inviteId }}
						replace
					/>
				)
			}
			if (!boolean(isComplete)) {
				return (
					<Navigate
						to={`/auth/sign-up-2${searchParamsString ? `?${searchParamsString}` : ''}`}
						state={{ from: location, inviteId }}
						replace
					/>
				)
			}
			if (from?.pathname) {
				const { urlToken, ...params } = searchStringToObject(from.search) || {}
				const searchString = objectToSearchString(params)
				if (urlToken) {
					return <Navigate to={`${from.pathname}${searchString ? '?' + searchString : ''}`} replace />
				}
			}
			if (inviteId) {
				return <Navigate to={`/accept-invite/${inviteId}`} state={{ from: location }} replace />
			}
			if (['join-now'].includes(a!)) {
				return <Navigate to={`/pie-hub?a=${a}`} replace />
			}
			if (to) {
				return <Navigate to={to} replace />
			}
			return <Navigate to="/" state={{ from: location }} replace />
		}
		return <Navigate to="/auth/login" state={{ from: location }} replace />
	}

	return children
}
