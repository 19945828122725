import { PropsWithChildren, Fragment, useState, useEffect } from 'react'
import {
	Dialog,
	DialogBackdrop,
	DialogTitle,
	Menu,
	MenuButton,
	MenuItem,
	MenuItems,
	Transition,
	TransitionChild,
} from '@headlessui/react'
import classNames from 'classnames'
import * as Yup from 'yup'

import { AvatarImage, IconSvg } from 'components/common'
import { UsersSuggestion } from 'containers/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserTier } from 'graphql/generated/scheme'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
	excludedData?: any[]
	selectedData?: any[]
	open: boolean
	title?: string
	setOpen: (...args: any) => any
	onSave?: (...args: any) => any
	loading?: boolean
	emailInviteDescription?: string
	multiple?: boolean
}

const validationSchema = Yup.object().shape({
	emailAddress: Yup.string()
		.transform((_value, originalValue) => originalValue?.toLowerCase())
		.email('Your email address is invalid. Please try again.')
		.required('Email is required'),
})

export function ChooseUserModal({
	excludedData,
	selectedData,
	open,
	setOpen,
	title = '',
	onSave,
	loading,
	emailInviteDescription,
	multiple = true,
}: PropsWithChildren<Props>) {
	const [selectedItems, setSelectedItems] = useState<Array<any>>([])
	const [inputEvent, setInputEvent] = useState<{ value: string; key: string } | any>(null)
	const [openInviteEmailModal, setOpenInviteEmailModal] = useState<boolean>(false)
	const [editingIndex, setEditingIndex] = useState<any>(null)
	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({
		resolver: yupResolver(validationSchema),
	})

	useEffect(() => {
		setSelectedItems(selectedData ?? [])
	}, [selectedData])

	useEffect(() => {
		if (!open) {
			setSelectedItems([])
		}
	}, [open])

	const onSubmit = (formData: any) => {
		const { emailAddress } = formData
		if (editingIndex !== null) {
			setSelectedItems([
				...selectedItems.map((item: any, index: number) => {
					if (index === editingIndex) {
						return { emailAddress }
					}
					return item
				}),
			])
			setEditingIndex(null)
		} else {
			if (
				!selectedItems.some(selectedItem => selectedItem.emailAddress === emailAddress) &&
				!excludedData?.some(excludedItem => excludedItem.emailAddress === emailAddress)
			) {
				if (multiple) {
					setSelectedItems([...selectedItems, { emailAddress }])
				} else {
					setSelectedItems([{ emailAddress }])
				}
			}
		}
		setOpenInviteEmailModal?.(false)
	}

	return (
		<>
			<Transition show={open} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => setOpen?.(false)}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
						</TransitionChild>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[403px] max-w-[320px] sm:w-full">
								<div className="absolute top-6 right-4">
									<button
										onClick={() => setOpen?.(false)}
										className="w-10 h-10 rounded-full flex justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
									>
										<IconSvg icon="close" className="w-6 h-6" />
									</button>
								</div>
								<div className="modal-content p-8 px-12 bg-primary-tile rounded-2xl">
									<div className="modal-header font-medium text-xl leading-6 mb-8">
										<DialogTitle as="h3">{title}</DialogTitle>
									</div>
									<div className="modal-body">
										<div className="mb-2 flex items-center justify-center">
											<div className="max-w-xs w-full">
												<UsersSuggestion
													onSelected={item => {
														if (!multiple) {
															setSelectedItems([item])
															return
														}
														if (
															!selectedItems.some(
																selectedItem => selectedItem.userId === item.userId
															) &&
															!excludedData?.some(
																excludedItem => excludedItem.userId === item.userId
															)
														) {
															setSelectedItems([...selectedItems, item])
														}
													}}
													onClosed={() => setInputEvent(null)}
													inputEvent={inputEvent}
													selectedData={selectedItems}
													excludedData={excludedData}
												>
													<div className="relative">
														<div className="absolute top-1/2 left-2 -mt-3">
															<IconSvg icon="search" />
														</div>
														<input
															className={classNames(
																'border-0 rounded-[20px] placeholder-opacity-60 placeholder-white-50 placeholder:italic placeholder:font-light bg-white-10 px-4 pl-9 h-10 w-full focus:ring-0'
															)}
															placeholder="Search"
															type="search"
															onKeyUp={(e: any) => {
																const { value } = e.target
																const key = e.key
																setInputEvent({ value, key })
															}}
														/>
													</div>
												</UsersSuggestion>
											</div>
										</div>
										{loading ? (
											<div className="flex justify-center items-center">
												<FontAwesomeIcon
													icon={['fas', 'spinner']}
													size="lg"
													spin
													className="text-white"
												/>
											</div>
										) : (
											<>
												{selectedItems?.length ? (
													<div className="py-4">
														{selectedItems.map((item: any, index: number) => (
															<div
																key={index}
																className="flex items-center justify-between gap-8 p-2 border border-transparent hover:bg-white-10 hover:border-white-25 transition-all duration-300 rounded-lg group"
															>
																<div className="flex flex-row space-x-2 items-center min-w-0">
																	<div className="relative flex-shrink-0">
																		{!item.name ? (
																			<div className="h-11 w-11 rounded-full bg-[#A88DE033] border border-[#A88DE01A]" />
																		) : (
																			<>
																				<AvatarImage
																					className="h-11 w-11 rounded-full object-cover"
																					src={item.avatarUrl}
																					alt={item.name}
																				/>
																				{item.isVerified && (
																					<div className="absolute bottom-0 right-0">
																						<IconSvg
																							icon="verified"
																							className={classNames(
																								'text-[#308DE8]',
																								{
																									'!text-yellow-400':
																										UserTier.Premium ===
																										item.tier,
																								}
																							)}
																						/>
																					</div>
																				)}
																			</>
																		)}
																	</div>
																	<div
																		className="font-bold text-sm line-clamp-2"
																		title={item.name || item.emailAddress}
																	>
																		{item.name || item.emailAddress}
																	</div>
																</div>
																<div className="flex items-center justify-end gap-4">
																	<IconSvg
																		icon="check"
																		className="group-hover:hidden"
																	/>
																	<div className="group-hover:flex hidden items-center justify-end">
																		<Menu as="div" className="relative text-left">
																			<div>
																				<MenuButton className="transition-all duration-300 rounded-full flex items-center justify-center">
																					<IconSvg icon="more" />
																				</MenuButton>
																			</div>
																			<Transition
																				as={Fragment}
																				enter="transition ease-out duration-100"
																				enterFrom="transform opacity-0 scale-95"
																				enterTo="transform opacity-100 scale-100"
																				leave="transition ease-in duration-75"
																				leaveFrom="transform opacity-100 scale-100"
																				leaveTo="transform opacity-0 scale-95"
																			>
																				<MenuItems
																					className={classNames(
																						'absolute right-0 rounded-lg shadow-lg bg-indigo-60b focus:outline-none mt-2 py-4 w-[200px] z-10',
																						{
																							'origin-top-right': true,
																							'origin-bottom-right bottom-full':
																								false,
																						}
																					)}
																				>
																					<div>
																						{!item.userId && (
																							<MenuItem>
																								{({ active }) => (
																									<button
																										type="button"
																										className={classNames(
																											'px-4 py-2 flex space-x-2 hover:bg-indigo-40b transition-all duration-300 w-full',
																											{
																												'text-white':
																													active,
																												'text-white-75 hover:text-white':
																													!active,
																											}
																										)}
																										onClick={() => {
																											reset({
																												emailAddress:
																													item.emailAddress,
																											})
																											setOpenInviteEmailModal(
																												true
																											)
																											setEditingIndex(
																												index
																											)
																										}}
																									>
																										<IconSvg
																											icon="edit-alt"
																											className="text-white w-6"
																											aria-hidden="true"
																										/>
																										<span>
																											Edit
																										</span>
																									</button>
																								)}
																							</MenuItem>
																						)}
																						<MenuItem>
																							{({ active }) => (
																								<button
																									type="button"
																									onClick={() => {
																										const newItems =
																											[
																												...(selectedItems ||
																													[]),
																											]
																										newItems.splice(
																											index,
																											1
																										)
																										setSelectedItems(
																											newItems
																										)
																									}}
																									className={classNames(
																										'px-4 py-2 flex space-x-2 hover:bg-indigo-40b transition-all duration-300 w-full',
																										{
																											'text-white':
																												active,
																											'text-white-75 hover:text-white':
																												!active,
																										}
																									)}
																								>
																									<IconSvg
																										icon="delete"
																										className="text-secondary-red w-6"
																										aria-hidden="true"
																									/>
																									<span>Delete</span>
																								</button>
																							)}
																						</MenuItem>
																					</div>
																				</MenuItems>
																			</Transition>
																		</Menu>
																	</div>
																</div>
															</div>
														))}
													</div>
												) : (
													<div className="p-4 text-center">No data found</div>
												)}
											</>
										)}
										<div className="mt-2 space-y-3">
											<button
												type="button"
												className="px-6 rounded-3xl transition-all duration-300 bg-primary h-10 w-full hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg font-bold"
												disabled={loading}
												onClick={() => {
													setOpen(false)
													onSave?.(selectedItems)
												}}
											>
												Confirm
											</button>
											<button
												type="button"
												className="px-6 rounded-3xl transition-all duration-300 bg-white-10 h-10 w-full border border-white-50 hover:bg-white-25 disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg font-bold"
												disabled={loading}
												onClick={() => {
													reset({ emailAddress: '' })
													setOpenInviteEmailModal(true)
												}}
											>
												Invite by Email
											</button>
										</div>
									</div>
								</div>
							</div>
						</TransitionChild>
					</div>
				</Dialog>
			</Transition>

			<Transition show={openInviteEmailModal} as={Fragment}>
				<Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={() => {}}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
						</TransitionChild>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="relative inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[403px] max-w-[320px] sm:w-full">
								<div className="absolute top-6 right-4">
									<button
										onClick={() => setOpenInviteEmailModal?.(false)}
										className="w-10 h-10 rounded-full flex justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
									>
										<IconSvg icon="close" className="w-6 h-6" />
									</button>
								</div>
								<div className="modal-content p-8 px-12 bg-primary-tile rounded-2xl">
									<div className="modal-header font-medium text-xl leading-6 mb-8">
										<DialogTitle as="h3">{title}</DialogTitle>
									</div>
									<div className="modal-body">
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="space-y-6">
												<div>
													<label
														htmlFor="emailAddress"
														className="text-white-75 font-medium block text-xs text-left"
													>
														Email Address
													</label>
													<div className="mt-2">
														<div className="relative">
															<input
																id="emailAddress"
																type="text"
																className="px-4 py-3 border-2 !border-white-10 bg-white-10 rounded-lg placeholder-white-50 placeholder:italic placeholder:font-light block w-full h-12 peer disabled:pr-11 focus:ring-0"
																placeholder="contact@example.com"
																{...register('emailAddress')}
															/>
															<div className="absolute top-1/2 right-4 -mt-3 peer-disabled:block hidden">
																<IconSvg icon="lock" className="text-white-50" />
															</div>
														</div>
														{errors.emailAddress && (
															<div className="text-secondary-red mt-2 text-sm">
																{errors.emailAddress.message as string}
															</div>
														)}
													</div>
												</div>
												{!!emailInviteDescription && (
													<div className="p-6 rounded-lg bg-[#381572] border border-[#A88DE033] text-sm">
														{emailInviteDescription}
													</div>
												)}
											</div>
											<div className="mt-6 text-center">
												<div className="space-y-5 text-center">
													<button
														type="submit"
														className="px-6 rounded-3xl transition-all duration-300 bg-primary h-10 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg w-full font-bold"
														disabled={!isValid}
													>
														Confirm
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</TransitionChild>
					</div>
				</Dialog>
			</Transition>
		</>
	)
}
