import classNames from 'classnames'
import { EventAttendeeState } from 'graphql/generated/scheme'

interface Props {
	state?: EventAttendeeState
	className?: string
}

const EVENT_STATE: any = {
	[EventAttendeeState.Approved]: 'RSVP Approved',
	// [EventAttendeeState.UnableRsvpListFull]: 'List Full',
	[EventAttendeeState.Declined]: 'Not Selected',
	[EventAttendeeState.InviteeAccepted]: 'RSVP Pending',
}

export function RSVPStatus({ state, className }: Props) {
	return (
		state &&
		EVENT_STATE[state] && (
			<div
				className={classNames(
					'w-[140px] text-sm font-bold rounded-lg py-3.5 border border-solid flex justify-center items-center backdrop-blur-2xl relative overflow-hidden bg-[#100526]/60',
					className,
					{
						'border-[#19E57F] text-[#19E57F]': state === EventAttendeeState.Approved,
						// 'border-[#31B5FF] text-[#31B5FF]':
						// 	state === EventAttendeeState.UnableRsvpListFull,
						'border-[#F54E4E] text-[#F54E4E]': state === EventAttendeeState.Declined,
						'border-[#ECD664] text-[#ECD664]': state === EventAttendeeState.InviteeAccepted, // Pending status
					}
				)}
			>
				<div
					className={classNames('absolute top-0 left-0 w-full h-full', {
						'bg-[#19E57F]/20': state === EventAttendeeState.Approved,
						// 'bg-[#31B5FF33]':
						// 	state === EventAttendeeState.UnableRsvpListFull,
						'bg-[#F54E4E]/20': state === EventAttendeeState.Declined,
						'bg-[#ECD664]/20': state === EventAttendeeState.InviteeAccepted, // Pending status
					})}
				/>
				<div className="relative">{EVENT_STATE[state]}</div>
			</div>
		)
	)
}

const ATTENDEE_STATE: any = {
	[EventAttendeeState.Approved]: 'Approved',
	[EventAttendeeState.Declined]: 'Declined',
	[EventAttendeeState.InviteeAccepted]: 'Pending',
}

export function AttendeeStatus({ state, className }: Props) {
	return (
		state &&
		ATTENDEE_STATE[state] && (
			<div
				className={classNames(
					'w-[85px] h-[34px] text-sm font-bold rounded-lg border-2 border-solid flex justify-center items-center backdrop-blur-2xl relative overflow-hidden bg-[#100526]/60',
					className,
					{
						'border-[#19E57F] text-[#19E57F]': state === EventAttendeeState.Approved,
						'border-[#F54E4E] text-[#F54E4E]': state === EventAttendeeState.Declined,
						'border-[#ECD664] text-[#ECD664]': state === EventAttendeeState.InviteeAccepted,
					}
				)}
			>
				<div
					className={classNames('absolute top-0 left-0 w-full h-full', {
						'bg-[#19E57F]/20': state === EventAttendeeState.Approved,
						'bg-[#F54E4E]/20': state === EventAttendeeState.Declined,
						'bg-[#ECD664]/20': state === EventAttendeeState.InviteeAccepted,
					})}
				/>
				<div className="relative">{ATTENDEE_STATE[state]}</div>
			</div>
		)
	)
}
