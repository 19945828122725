import { Fragment, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { Menu, MenuItem, MenuItems, Transition } from '@headlessui/react'

import { AvatarImage, IconSvg, SimpleTable } from 'components/common'
import { UserTier, useSearchUsersLazyQuery } from 'graphql/generated/scheme'
import { useOutsideClick } from 'hooks/useOutsideClick'

interface Props {
	inputEvent?: { value: string; key: string }
	onSelected?: (...args: any) => any
	onClosed?: (...args: any) => any
	selectedData?: any[]
	excludedData?: any[]
}

export function UsersSuggestion({
	children,
	inputEvent,
	onSelected,
	onClosed,
	selectedData,
	excludedData,
}: PropsWithChildren<Props>) {
	const [users, setUsers] = useState<Array<any>>([])
	const [openUsersSuggestion, setOpenUsersSuggestion] = useState<boolean>(false)
	const [
		searchUsers,
		{ data: dataUsers, loading: loadingUsers, error: errorUsers, called: calledUsers, refetch: refetchUsers },
	] = useSearchUsersLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only',
	})

	const handleClickOutside = () => {
		setOpenUsersSuggestion(false)
	}
	const ref = useOutsideClick(handleClickOutside)
	const debounceDropDown = useCallback(
		debounce((nextValue, isEnterKey) => {
			if (!loadingUsers && !isEnterKey) {
				if ((nextValue || '').trim().length > 0) {
					setOpenUsersSuggestion(true)
					if (calledUsers) {
						refetchUsers({
							keyword: nextValue,
						})
					} else {
						searchUsers({
							variables: {
								offset: 0,
								limit: 10,
								keyword: nextValue,
							},
						})
					}
				} else {
					setUsers([])
				}
			}
		}, 1000),
		[]
	)

	useEffect(() => {
		if (inputEvent) {
			debounceDropDown(inputEvent.value, inputEvent.key === 'Enter')
		}
	}, [inputEvent])

	useEffect(() => {
		if (!openUsersSuggestion) {
			onClosed?.()
		}
	}, [openUsersSuggestion])

	useEffect(() => {
		if (dataUsers) {
			setUsers(dataUsers?.users?.nodes || [])
		}
		if (errorUsers) {
			toast.error(errorUsers.message)
		}
	}, [dataUsers, errorUsers])

	return (
		<div ref={ref}>
			<Menu as="div" className="relative">
				{children}
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
					show={openUsersSuggestion}
				>
					<MenuItems
						className={classNames(
							'absolute rounded-lg shadow-lg bg-[#3B2763] focus:outline-none mt-2 w-full z-10 max-h-[300px] overflow-auto'
						)}
						static
					>
						<SimpleTable
							noHeader
							noBorder
							columns={[
								{
									content: {
										className: 'col-span-12 px-2 py-2',
										formatter: (item: any) => {
											return (
												<div className="flex items-center justify-between gap-8">
													<div className="flex flex-row space-x-2 items-start">
														<div className="relative flex-shrink-0">
															<AvatarImage
																className="h-11 w-11 rounded-full object-cover"
																src={item.avatarUrl}
																alt={item.name}
															/>
															{item.isVerified && (
																<div className="absolute bottom-0 right-0">
																	<IconSvg
																		icon="verified"
																		className={classNames('text-[#308DE8]', {
																			'!text-yellow-400':
																				UserTier.Premium === item.tier,
																		})}
																	/>
																</div>
															)}
														</div>
														<div className="space-y-0.5">
															<div
																className="font-bold text-sm line-clamp-2"
																title={item.name}
															>
																{item.name}
															</div>
															<div
																className="text-xs font-normal text-indigo-40w"
																title={item.emailAddress}
															>
																{item.emailAddress}
															</div>
															<div className="text-xs font-normal" title={item.userName}>
																@{item.userName}
															</div>
														</div>
													</div>
													<div>
														<IconSvg
															icon="radio-unchecked"
															className={classNames({
																hidden: selectedData?.some(
																	selectedItem => selectedItem.userId === item.userId
																),
															})}
														/>
														<IconSvg
															icon="radio-checked"
															className={classNames({
																hidden: !selectedData?.some(
																	selectedItem => selectedItem.userId === item.userId
																),
															})}
														/>
													</div>
												</div>
											)
										},
									},
								},
							]}
							row={{
								className: 'items-center',
								formatter: (index: number, item: any, htmlContent: any, row: any) => {
									return (
										<MenuItem key={index} as={Fragment}>
											{({ active }) => (
												<button
													className={classNames(
														`hover:bg-white-5 grid grid-cols-12 w-full text-left p-2`,
														{ 'bg-white-5': active },
														row?.className
													)}
													type="button"
													onClick={() => {
														setOpenUsersSuggestion(false)
														onSelected?.(item)
													}}
												>
													{htmlContent}
												</button>
											)}
										</MenuItem>
									)
								},
							}}
							loading={loadingUsers}
							currentPage={0}
							data={users.filter(
								item => !excludedData?.some(excludedItem => excludedItem.userId === item.userId)
							)}
							totalCount={10}
							pageSize={10}
							noDataText="No search results"
						/>
					</MenuItems>
				</Transition>
			</Menu>
		</div>
	)
}
