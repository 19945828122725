import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-hot-toast'

import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'
import { ContentModal } from 'components/common'
import { TermsOfServiceAndPrivacyBlock } from 'containers/common'
import { useAgreeToTermsAndConditionsMutation } from 'graphql/generated/scheme'

const validationSchema = Yup.object().shape({
	agreedTerms: Yup.bool().oneOf([true], 'Field must be checked'),
})

export function UpdateTermsOfServiceAndPrivacyModal() {
	const { authInfo, setAuthInfo } = useAuth()
	const { refetchUser, currentTerms } = useStore()
	const [openUpdateTermsModal, setOpenUpdateTermsModal] = useState<boolean>(false)
	const [
		agreeToTermsAndConditions,
		{
			data: dataAgreeToTermsAndConditions,
			loading: loadingAgreeToTermsAndConditions,
			error: errorAgreeToTermsAndConditions,
		},
	] = useAgreeToTermsAndConditionsMutation()
	const {
		handleSubmit,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onChange',
	})

	useEffect(() => {
		if (authInfo) {
			setOpenUpdateTermsModal(!authInfo.agreeToTermsAndConditions)
		} else {
			setOpenUpdateTermsModal(true)
		}
	}, [JSON.stringify(authInfo)])

	useEffect(() => {
		if (dataAgreeToTermsAndConditions) {
			toast.success('You have agreed to our Terms of service and Privacy Policy.')
			refetchUser()
			setAuthInfo({
				...authInfo,
				agreeToTermsAndConditions: true,
			})
			setOpenUpdateTermsModal(false)
		}
		if (errorAgreeToTermsAndConditions) {
			toast.error(errorAgreeToTermsAndConditions.message)
		}
	}, [dataAgreeToTermsAndConditions, errorAgreeToTermsAndConditions])

	const onSubmit = () => {
		const { version } = currentTerms
		agreeToTermsAndConditions({ variables: { version } })
	}

	return (
		<ContentModal title="There's something new!" open={openUpdateTermsModal} hideCloseButton>
			<div className="space-y-5 text-left">
				<div>We've updated Our Terms of Service and Privacy Policy. Please read and confirm!</div>
				<div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="space-y-4">
							<TermsOfServiceAndPrivacyBlock
								label="By clicking update, you agree to #termsOfService and #privacyPolicy."
								params={{
									termsOfService: 'Our Terms of Service',
									privacyPolicy: 'Privacy Policy',
								}}
							/>
						</div>
						<div className="space-y-2 mt-8">
							<button
								type="submit"
								className="w-full px-6 rounded-3xl transition-all duration-300 bg-primary h-10 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg sm:min-w-[200px] font-bold"
								disabled={!isValid || loadingAgreeToTermsAndConditions}
							>
								{loadingAgreeToTermsAndConditions && (
									<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="mr-1" />
								)}
								Update
							</button>
						</div>
					</form>
				</div>
			</div>
		</ContentModal>
	)
}
