import { Fragment, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, NavLink, Link, useNavigate, useParams } from 'react-router-dom'
import { Dialog, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { boolean } from 'boolean'

import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'
import { Logo, IconSvg, ActivatePieModal, ContentModal, PieImage } from 'components/common'
import { NAVIGATION_ITEMS } from 'utils/constants'
import {
	SearchBar,
	ProfileDropdown,
	NotificationDropdown,
	TrendingPiesBlock,
	UpdateTermsOfServiceAndPrivacyModal,
	DeleteAccountBlock,
	DidYouKnowBlock,
} from 'containers/common'
import { ComponentGuard } from 'guards'
import { EventPermissionId, OnboardingProcess } from 'graphql/generated/scheme'
import { numberFormat } from 'utils/common'
import CreateActionDropdown from 'containers/common/CreateActionDropdown'
import { HasAnyEventPermission } from 'services/helper'

export function MainLayout() {
	const navigate = useNavigate()
	const { id } = useParams()
	const { token, authInfo } = useAuth()
	const {
		header,
		hasBackArrow,
		backTo,
		blur,
		loadingLogout,
		openDeleteAccount,
		setOpenDeleteAccount,
		refetchUser,
		refetchMyPies,
		refetchMyEvents,
		refetchMyClubs,
		myPie,
		hasShowedOnboadingAlready,
		setHasShowedOnboadingAlready,
		openCongratulationsModal,
		setOpenCongratulationsModal,
		congratulationsModalContent,
		setCongratulationsModalContent,
		selectedEvent,
		user,
	} = useStore()
	const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)
	const [openActivatePieModal, setOpenActivatePieModal] = useState<boolean>(false)

	useEffect(() => {
		if (token && authInfo) {
			const { isDeleted, isComplete } = authInfo
			refetchUser()
			if (!boolean(isDeleted) && boolean(isComplete)) {
				refetchMyPies()
				refetchMyEvents()
				refetchMyClubs()
			}
		}
	}, [token, JSON.stringify(authInfo)])

	useEffect(() => {
		if (
			myPie &&
			![OnboardingProcess.Complete, OnboardingProcess.None].includes(myPie.onBoardingProcess) &&
			!hasShowedOnboadingAlready
		) {
			setTimeout(() => {
				setOpenActivatePieModal(true)
				setHasShowedOnboadingAlready(true)
			}, 1000)
		}
	}, [myPie])

	return (
		<div className={classNames({ blur })}>
			<Helmet
				bodyAttributes={{
					class: classNames(
						'bg-primary-background'
						// , {
						// 	'sm:overflow-visible overflow-hidden':
						// 		isAllowComponent({
						// 			excludeRoutes: ['/reward/:id', '/rewards/:id', '/pies/:id'],
						// 		}) && isIOS,
						// }
					),
				}}
			/>
			<div className="flex min-h-screen max-w-[1440px] mx-auto">
				<Transition show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<DialogBackdrop className="fixed inset-0 bg-black-75" />
						</TransitionChild>
						<TransitionChild
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div className="relative flex-1 flex flex-col w-full bg-black-75 focus:outline-none">
								<TransitionChild
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute bottom-6 right-1/2 -mr-6 pt-2">
										<button
											type="button"
											className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close Sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</TransitionChild>
								<div className="flex-1 h-0 p-6">
									<div className="px-4 flex items-center justify-center">
										<a href="/">
											<Logo className="h-8" />
										</a>
									</div>
									<nav
										className="flex-1 mt-8 max-h-[calc(100vh-32px-56px-32px-56px)] overflow-auto"
										aria-label="Sidebar"
									>
										<div className="space-y-4">
											{NAVIGATION_ITEMS.map(item => (
												<NavLink
													key={item.name}
													to={item.href}
													className={({ isActive }) =>
														classNames(
															'group flex flex-col items-center px-4 py-3 transition-all duration-300 text-xl justify-center font-medium',
															{
																'text-[#FFFFFF80]': !isActive,
																'pointer-events-none text-white-10': item.disabled,
															}
														)
													}
													reloadDocument
												>
													{({ isActive }) => (
														<>
															<IconSvg
																icon={item.icon}
																className={classNames('w-6 group-hover:text-primary', {
																	'text-primary': isActive,
																})}
																aria-hidden="true"
															/>
															<span className="group-hover:text-white">{item.name}</span>
														</>
													)}
												</NavLink>
											))}
										</div>
									</nav>
								</div>
							</div>
						</TransitionChild>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:flex lg:flex-shrink-0 min-[1440px]:w-[1440px] w-full fixed top-0 left-1/2 -translate-x-1/2">
					<div className="flex flex-col w-[232px] absolute top-0 left-0 h-screen">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex-1 flex flex-col min-h-0 px-4">
							<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
								<div className="px-4">
									<a href="/">
										<Logo className="h-7" />
									</a>
								</div>
								<nav className="flex-1 mt-8" aria-label="Sidebar">
									<div className="space-y-2">
										{NAVIGATION_ITEMS.map(item => (
											<NavLink
												key={item.name}
												to={item.href}
												className={({ isActive }) =>
													classNames(
														'group flex items-center px-4 py-3 transition-all duration-300 font-medium',
														{
															'text-[#FFFFFF80]': !isActive,
															'pointer-events-none text-white-10': item.disabled,
														}
													)
												}
											>
												{({ isActive }) => (
													<>
														<IconSvg
															icon={item.icon}
															className={classNames('mr-2 w-6 group-hover:text-primary', {
																'text-primary': isActive,
															})}
															aria-hidden="true"
														/>
														<span className="group-hover:text-white">{item.name}</span>
													</>
												)}
											</NavLink>
										))}
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col min-w-0 flex-1 lg:pl-[232px]">
					<div className="h-[72px]">
						<div className="min-[1440px]:w-[1440px] w-full fixed top-0 left-1/2 -translate-x-1/2 z-10">
							<div className="lg:flex-none flex justify-between lg:w-[calc(100%-232px)] w-full absolute right-0 top-0 bg-primary-background">
								<div className="lg:hidden">
									<div className="flex items-center sm:px-8 py-4 space-x-4 px-4">
										<div>
											<button
												type="button"
												className="h-10 w-10 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
												onClick={() => setSidebarOpen(true)}
											>
												<span className="sr-only">Open Sidebar</span>
												<Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
											</button>
										</div>
										<div>
											<a href="/">
												<Logo className="h-7" />
											</a>
										</div>
									</div>
								</div>
								<div className="lg:flex justify-start items-center px-8 py-4 text-white-75 hidden">
									{header !== null && (
										<>
											{hasBackArrow && (
												<div className="mr-2">
													<Link to={backTo ?? (-1 as any)}>
														<IconSvg icon="chevron-left" />
													</Link>
												</div>
											)}
											<div className="text-2xl h-10 flex items-center whitespace-nowrap space-x-1">
												{header.split('/').length === 1 ? (
													header
												) : (
													<>
														{header.split('/').map((item: string, index: number) => {
															return (
																<Fragment key={index}>
																	{index !== 0 && (
																		<span className="last:text-white">/</span>
																	)}
																	<span className="last:text-white">{item}</span>
																</Fragment>
															)
														})}
													</>
												)}
											</div>
										</>
									)}
								</div>
								<ComponentGuard includeRoutes={['/rep-club/events/:id']}>
									<>
										{selectedEvent &&
											HasAnyEventPermission(selectedEvent, user?.userId, [
												EventPermissionId.CanApproveAttendee,
												EventPermissionId.CanApproveMyAttendee,
											]) && (
												<div className="py-4 absolute right-[265px] sm:block hidden">
													<Link to={`/rep-club/events/${id}/approval`}>
														<div className="flex items-center justify-center w-[184px] h-[40px] font-bold bg-[#A88DE01A] border border-solid border-[#A88DE033] hover:bg-white-10 rounded-3xl">
															Manage Attendees
														</div>
													</Link>
												</div>
											)}
									</>
								</ComponentGuard>
								<div className="flex gap-7 py-4 sm:px-8 px-4 items-center">
									<div className="fixed left-1/2 translate-x-[-50%]">
										<ComponentGuard
											excludeRoutes={[
												'/slices',
												// '/slices/top-up',
												'/profile',
												'/profile/personal',
												'/profile/social',
												'/profile/location',
												'/profile/notifications',
												'/profile/information',
												'/rewards',
												'/rewards/:id',
												'/pies/:id/rewards/:rewardId',
												'/pie-hub',
												'/pie-hub/details',
												'/pie-hub/appearance',
												'/pie-hub/slices',
												'/pie-hub/summary',
												'/pie-hub/overview/edit/:id',
												'/pie-hub/rewards/create',
												'/pie-hub/rewards/:id',
												'/pie-hub/rewards/edit/:id',
												'/pie-hub/achievements/create',
												'/pie-hub/achievements/:id',
												'/pie-hub/achievements/edit/:id',
												'/trade-hub',
												'/events',
												'/home',
												'/rep-clubs',
												'/rep-clubs/:id/*',
												'/rep-club/*',
												'/my/rep-clubs/*',
												'/my/rep-clubs/create',
												'/rep-points/*',
											]}
										>
											<div className="lg:flex justify-end hidden">
												<SearchBar autoSuggestion ltr />
											</div>
										</ComponentGuard>
									</div>
									<div className="flex-none">
										<CreateActionDropdown />
									</div>
									<ComponentGuard excludeRoutes={['/profile/notifications']}>
										<div>
											<NotificationDropdown />
										</div>
									</ComponentGuard>
									<div className="flex-none">
										<ProfileDropdown />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="h-[64px] lg:hidden">
						<div className="sm:px-8 px-4 w-full fixed top-[72px] pb-4 bg-primary-background z-[2]">
							<div className="flex items-center justify-between py-1">
								<div className="flex items-center w-full justify-between gap-1">
									<ComponentGuard
										excludeRoutes={[
											'/slices',
											// '/slices/top-up',
											'/profile',
											'/profile/personal',
											'/profile/social',
											'/profile/location',
											'/profile/notifications',
											'/profile/information',
											'/rewards',
											'/rewards/:id',
											'/pies/:id/rewards/:rewardId',
											'/pie-hub',
											'/pie-hub/details',
											'/pie-hub/appearance',
											'/pie-hub/slices',
											'/pie-hub/summary',
											'/pie-hub/overview/edit/:id',
											'/pie-hub/rewards/create',
											'/pie-hub/rewards/:id',
											'/pie-hub/rewards/edit/:id',
											'/pie-hub/achievements/create',
											'/pie-hub/achievements/:id',
											'/pie-hub/achievements/edit/:id',
											'/trade-hub',
											'/events',
											'/home',
											'/rep-clubs',
											'/rep-clubs/:id/*',
											'/rep-club/*',
											'/my/rep-clubs/*',
											'/my/rep-clubs/create',
											'/rep-points/*',
										]}
									>
										<div className="flex justify-end">
											<SearchBar autoSuggestion ltr />
										</div>
									</ComponentGuard>
									<div className="flex justify-start items-center text-white-75">
										{header !== null && (
											<>
												{hasBackArrow && (
													<div className="mr-2">
														<Link to={backTo ?? (-1 as any)}>
															<IconSvg icon="chevron-left" />
														</Link>
													</div>
												)}
												<div className="sm:text-2xl flex items-center whitespace-nowrap space-x-1">
													{header.split('/').length === 1 ? (
														header
													) : (
														<>
															{header.split('/').map((item: string, index: number) => {
																return (
																	<Fragment key={index}>
																		{index !== 0 && (
																			<span className="last:text-white">/</span>
																		)}
																		<span className="last:text-white">{item}</span>
																	</Fragment>
																)
															})}
														</>
													)}
												</div>
											</>
										)}
									</div>
									<ComponentGuard includeRoutes={['/rep-club/events/:id']}>
										<>
											{selectedEvent &&
												HasAnyEventPermission(selectedEvent, user?.userId, [
													EventPermissionId.CanApproveAttendee,
													EventPermissionId.CanApproveMyAttendee,
												]) && (
													<div className="sm:hidden">
														<Link to={`/rep-club/events/${id}/approval`}>
															<div className="flex items-center justify-center w-[184px] h-[40px] font-bold bg-[#A88DE01A] border border-solid border-[#A88DE033] hover:bg-white-10 rounded-3xl">
																Manage Attendees
															</div>
														</Link>
													</div>
												)}
										</>
									</ComponentGuard>
								</div>
								{/* <a
									href="/pie-hub"
									className="rounded-3xl transition-all duration-300 bg-secondary-button w-10 h-10 hover:bg-secondary-button-hover flex justify-center items-center space-x-1 flex-shrink-0"
								>
									<IconSvg icon="information" />
								</a> */}
							</div>
						</div>
					</div>
					<main className="pb-4">
						{/* Start main area*/}
						<Outlet />
						{/* End main area */}
					</main>
					<ComponentGuard
						excludeRoutes={[
							// '/slices/top-up',
							'/profile/personal',
							'/profile/social',
							'/profile/location',
							'/rewards/:id',
							'/pies/:id/rewards/:rewardId',
							'/pie-hub',
							'/pie-hub/details',
							'/pie-hub/appearance',
							'/pie-hub/slices',
							'/pie-hub/summary',
							'/pie-hub/overview/edit/:id',
							'/pie-hub/rewards/create',
							'/pie-hub/rewards/:id',
							'/pie-hub/rewards/edit/:id',
							'/pie-hub/achievements/create',
							'/pie-hub/achievements/:id',
							'/pie-hub/achievements/edit/:id',
							'/profile/settings',
							'/rep-clubs/:id',
							'/rep-club/offers/create',
							'/my/rep-clubs',
							'/my/rep-clubs/create',
							'/my/rep-clubs/:id/offers/:sub_type',
							'/my/rep-clubs/:id/offers/way-to-earn-status/*',
							'/my/rep-clubs/:id',
						]}
					>
						<div className="lg:block hidden">
							<div className="min-[1440px]:w-[1440px] w-full fixed top-[72px] left-1/2 -translate-x-1/2">
								<div className="sm:pr-8 pr-4 lg:pt-4 pt-0 absolute right-0 top-0">
									<aside className="w-[200px]">
										<div className="space-y-2">
											<DidYouKnowBlock />
											<TrendingPiesBlock />
										</div>
									</aside>
								</div>
							</div>
						</div>
					</ComponentGuard>
				</div>
			</div>
			<UpdateTermsOfServiceAndPrivacyModal />
			<ActivatePieModal open={openActivatePieModal} setOpen={setOpenActivatePieModal} />
			<ContentModal
				open={openCongratulationsModal}
				setOpen={setOpenCongratulationsModal}
				isModal
				className="!bg-primary-panel"
			>
				<div>
					<div className="flex flex-col items-center space-y-5">
						<div className="max-w-xs">
							<div className="flex justify-center items-center">
								<PieImage
									className="w-[150px] h-[150px] rounded-xl overflow-hidden flex-shrink-0"
									images={[congratulationsModalContent?.pie?.pieAvatarUrl]}
								/>
							</div>
							<div className="pt-8">
								<h3 className="text-2xl font-medium mb-5 flex justify-center items-center gap-2">
									Congratulations!
								</h3>
								<p>
									You have successfully purchased{' '}
									{numberFormat(congratulationsModalContent?.sliceAmount || 0)} slice
									{congratulationsModalContent?.sliceAmount > 1 ? 's' : ''} of{' '}
									{congratulationsModalContent?.pie?.displayName} Pie.
								</p>
							</div>
							<div className="flex justify-center items-center mt-5">
								<img
									className="w-full"
									src="/images/common/user-guide/img-reward-graz.png"
									alt="Congratulations reward"
								/>
							</div>
						</div>
						<div className="max-w-md">
							<div>
								{congratulationsModalContent?.source === 'reward'
									? 'Each Pie has its own exclusive rewards. Ready to discover more Pies?'
									: 'When you acquire slices of a Pie, you unlock rewards. Each Pie has its own exclusive rewards.'}
							</div>
						</div>
					</div>
					<div className="mt-8 grid grid-cols-1 gap-4">
						<button
							type="button"
							className={classNames(
								'px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-primary text-white font-bold block w-full h-12 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg',
								{
									'!bg-secondary-button hover:!bg-secondary-button-hover order-last':
										congratulationsModalContent?.source === 'reward',
								}
							)}
							onClick={() => {
								setOpenCongratulationsModal(false)
								setCongratulationsModalContent(null)
								navigate('/home')
							}}
						>
							Explore More Pies
						</button>
						<button
							type="button"
							className={classNames(
								'px-6 py-3 rounded-3xl text-center transition-all duration-300 bg-primary text-white font-bold block w-full h-12 hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg',
								{
									'!bg-secondary-button hover:!bg-secondary-button-hover':
										congratulationsModalContent?.source !== 'reward',
								}
							)}
							onClick={() => {
								setOpenCongratulationsModal(false)
								setCongratulationsModalContent(null)
								navigate('/rewards')
							}}
						>
							Explore Rewards
						</button>
					</div>
				</div>
			</ContentModal>
			<ComponentGuard includeRoutes={['/profile/information', '/profile/personal']}>
				<DeleteAccountBlock openDelete={openDeleteAccount} setOpenDelete={setOpenDeleteAccount} />
			</ComponentGuard>
			{loadingLogout && (
				<div className="flex justify-center items-center py-4 w-full h-full fixed top-0 left-0 bg-primary-panel bg-opacity-90 z-[9999]">
					<FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin className="text-white" />
				</div>
			)}
			{/* {isIOS && (
				<ComponentGuard excludeRoutes={['/reward/:id', '/rewards/:id', '/pies/:id']}>
					<div className="p-4 w-full h-full fixed top-0 left-0 bg-primary-panel z-[9999] sm:hidden">
						<h1 className="text-center absolute top-0 left-0 w-full p-4">Install app</h1>
						<div className="flex flex-col gap-6 justify-center items-center w-full h-full">
							<PieImage
								className="w-[150px] h-[150px] rounded-xl overflow-hidden flex-shrink-0"
								images={[`images/logos/repbets-badge.png`]}
								forceUri
							/>
							<a
								href="https://apps.apple.com/us/app/id1623230623"
								className="px-6 rounded-3xl transition-all duration-300 bg-primary h-12 hover:bg-primary-hover w-full font-bold flex items-center justify-center"
							>
								Install app
							</a>
						</div>
					</div>
				</ComponentGuard>
			)} */}
		</div>
	)
}
