import { CountryRegionData } from 'react-country-region-selector'

import {
	AchievementCategory,
	ClubAchievementType,
	ContactInviteStatus,
	LocationUnit,
	LocationUnitLengthType,
	OfferRewardType,
	OfferType,
	PieRole,
	RedeemType,
	RewardCategory,
	RewardTimeFrameType,
	RewardType,
	UserGender,
	WinnersChosenOption,
} from 'graphql/generated/scheme'

export const GENDERS = [
	{ name: 'Female', value: UserGender.Female },
	{ name: 'Male', value: UserGender.Male },
	{ name: 'Other', value: UserGender.Other },
]

export const NAVIGATION_ITEMS =
	import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true'
		? [
				{ name: 'Home', href: '/home', icon: 'home', disabled: false },
				{ name: 'Events', href: '/events', icon: 'event', disabled: false },
				{ name: 'Offers', href: '/rewards', icon: 'rewards', disabled: true },
				{ name: 'Rep Points', href: '/rep-points/my-points', icon: 'reppoints', disabled: false },
				{ name: 'Profile', href: '/profile', icon: 'user', disabled: false },
		  ]
		: [
				{ name: 'Events', href: '/events', icon: 'event', disabled: false },
				{ name: 'Profile', href: '/profile', icon: 'user', disabled: false },
		  ]

export const HOME_TAB_ITEMS = [
	{ name: 'Featured', value: 'featured' },
	{ name: 'Newcomers', value: 'newcomers' },
	{ name: 'Watchlist', value: 'watchlist' },
]

export const TRADE_TAB_ITEMS = [
	{ name: 'Buy', value: 'buy' },
	{ name: 'Send', value: 'send' },
	{ name: 'Send to my pie', value: 'sendToPie' },
]

export const DEFAULT_INFORMATION = {
	avatarImageUrl: '/images/default/avatar.png',
	name: 'Unknown',
	rank: 0,
	iconPie: '/images/common/pie-card/image-pie-1.jpg',
	iconAssets: [
		'/images/common/pie-image/icon-1.svg',
		'/images/common/pie-image/icon-2.svg',
		'/images/common/pie-image/icon-3.svg',
		'/images/common/pie-image/icon-4.svg',
		'/images/common/pie-image/icon-5.svg',
		'/images/common/pie-image/icon-6.svg',
		'/images/common/pie-image/icon-7.svg',
	],
	rewardImageUrl: '/images/common/reward-1.jpg',
	pieImageUrl: '/images/default/pie.png',
}

export const REWARDS_TAB_ITEMS = [
	{ name: 'All', value: 'all' },
	{ name: 'Not participating', value: 'not-participating' },
	{ name: 'Participating', value: 'participating' },
	{ name: 'Awarded', value: 'awarded' },
	{ name: 'Expired', value: 'expired' },
]

export const EVENTS_TAB_ITEMS = [
	{ name: 'Explore', value: 'explore' },
	{ name: 'My Events', value: 'my-events' },
	{ name: 'Near Me', value: 'near-me' },
	{ name: 'This Week', value: 'this-week' },
]

export const TRADE_HUB_TAB_ITEMS = [
	{ name: 'Your Rep Points', value: 'your-rep-points' },
	{ name: 'Trade Requests', value: 'trade-requests' },
	{ name: 'Trade History', value: 'trade-history' },
]

export const ASSET_CATEGORY_ITEMS = [
	{ name: 'Background', value: 'background', icon: 'background' },
	{ name: 'Napkin', value: 'napkin', icon: 'napkin' },
	{ name: 'Plate', value: 'plate', icon: 'plate' },
	{ name: 'Crust', value: 'crust', icon: 'crust' },
	{ name: 'Filling', value: 'filling', icon: 'filling' },
	{ name: 'Topping', value: 'topping', icon: 'topping' },
	{ name: 'Utensil', value: 'fork', icon: 'fork' },
]

export const PIE_HUB_TAB_ITEMS = [
	{ name: 'Details', value: 'details' },
	{ name: 'Appearance', value: 'appearance' },
	{ name: 'Slices', value: 'slices' },
	{ name: 'Summary', value: 'summary' },
]

export const PIE_HUB_OVERVIEW_TAB_ITEMS = [
	{ name: 'Overview', value: 'overview' },
	{ name: 'Sales', value: 'sales' },
	{ name: 'Rewards', value: 'rewards' },
	{ name: 'Achievements', value: 'achievements' },
]

export const REP_CLUB_TAB_ITEMS =
	import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true'
		? [
				{ name: 'Details', value: 'edit', disabled: false },
				{ name: 'Rep Points', value: 'rep-points', disabled: false },
				{ name: 'Loyalty Tiers', value: 'loyalty-tiers', disabled: false },
				{ name: 'Events', value: 'events', disabled: false },
				{ name: 'Offers', value: 'offers', disabled: false },
				{ name: 'Achievements', value: 'achievements', disabled: false },
				{ name: 'Team', value: 'team', disabled: false },
		  ]
		: [{ name: 'Events', value: 'events', disabled: false }]

export const REWARD_CREATE_TAB_ITEMS = [
	{ name: 'Details', value: 'details' },
	{ name: 'Requirements', value: 'requirements' },
	{ name: 'Time Frame', value: 'time-frame' },
	{ name: 'Configurations', value: 'configurations' },
	{ name: 'Redemption', value: 'redemption' },
	{ name: 'Achievement', value: 'achievement' },
]

export const REQUIREMENTS = [
	{ name: '# of slices from your Pie', value: 'own-slices', limit: 1 },
	{ name: '# of slices from another Pie', value: 'own-slices-other', limit: 5 },
	{ name: 'Achievements', value: 'achievement-badge', limit: 1 },
]

export const TIME_FRAME = [
	{ name: 'After end date', value: WinnersChosenOption.AfterEndDate },
	{ name: 'After start date', value: WinnersChosenOption.AfterStartDate },
]

export const REWARD_TYPES = [
	{ name: 'Digital', value: RewardType.Digital },
	{ name: 'Physical', value: RewardType.Physical },
]

export const REWARD_CATEGORIES = [
	{ name: 'Invite', value: RewardCategory.Invite },
	{ name: 'Event', value: RewardCategory.AccessPass },
	{ name: 'Vip Pass', value: RewardCategory.VipPass },
	{ name: 'Race', value: RewardCategory.Race },
	{ name: 'Meet up', value: RewardCategory.MeetUp },
	{ name: 'Coupon', value: RewardCategory.Coupon },
	{ name: 'Merchandise/ Give-a-way', value: RewardCategory.MerchandiseGiveAWay },
	{ name: 'Raffle', value: RewardCategory.Raffle },
	{ name: 'Community', value: RewardCategory.Community },
	{ name: 'Exclusive Content', value: RewardCategory.ExclusiveContent },
	{ name: 'Other', value: RewardCategory.Other },
]

export const ACHIVEMENT_CATEGORIES = [
	{ name: 'Launch Day supporter', value: AchievementCategory.LaunchDaySupporter },
	{ name: 'OG Supporter', value: AchievementCategory.OgSupporter },
	{ name: 'Participation Badge', value: AchievementCategory.ParticipationBadge },
	{ name: 'Event badge', value: AchievementCategory.EventBadge },
	{ name: 'Brand badge', value: AchievementCategory.BrandBadge },
	{ name: 'Product Badge', value: AchievementCategory.ProductBadge },
	{ name: 'Community Badge', value: AchievementCategory.CommunityBadge },
	{ name: 'Tier Badge Emerald', value: AchievementCategory.TierBadgeEmerald },
	{ name: 'Tier Badge Ruby', value: AchievementCategory.TierBadgeRuby },
	{ name: 'Tier Badge Diamond', value: AchievementCategory.TierBadgeDiamond },
	{ name: 'Other', value: AchievementCategory.Other },
]

export const ACHIEVEMENT_TYPES = [
	{
		name: 'Launch Day supporter',
		value: ClubAchievementType.LaunchDaySupporter,
		icon: 'increase',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Participation Badge',
		value: ClubAchievementType.ParticipationBadge,
		icon: 'star-in-star',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Event Badge',
		value: ClubAchievementType.EventBadge,
		icon: 'calendar',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Brand Badge',
		value: ClubAchievementType.BrandBadge,
		icon: 'rep-points',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Product Badge',
		value: ClubAchievementType.ProductBadge,
		icon: 'shirt',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Community Badge',
		value: ClubAchievementType.CommunityBadge,
		icon: 'community-user',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Tier Badge',
		value: ClubAchievementType.TierBadge,
		icon: 'cup',
		description: 'Lorem ipsum dolor sit amet consectetur. Fermentum non in vitae egestas tellus arc.',
	},
	{
		name: 'None',
		value: 'none',
		icon: 'banned',
		description: 'Lorem ipsum dolor sit amet consectetur. Fermentum non in vitae egestas tellus arc.',
	},
]

export const REDEEM_TYPES = [
	{ name: 'Promo Code', value: RedeemType.PromoCode },
	{ name: 'Access Pass', value: RedeemType.QrCode },
	{ name: 'Invite Link', value: RedeemType.InviteLink },
	{ name: 'Location Information', value: RedeemType.LocationInfo },
	{ name: 'User Contact Information', value: RedeemType.UserContactInfo },
]

export const PIE_ROLES = [
	{ name: 'Admin', value: PieRole.Admin },
	{ name: 'Account Manager', value: PieRole.AccountManager },
	{ name: 'Reward Manager', value: PieRole.RewardManager },
]

export const PIE_PERMISSIONS = {
	CAN_ASSIGN_ADMINISTRATORS: 'CAN_ASSIGN_ADMINISTRATORS',
	CAN_ASSIGN_ACCOUNT_MANAGERS: 'CAN_ASSIGN_ACCOUNT_MANAGERS',
	CAN_ASSIGN_REWARD_MANAGERS: 'CAN_ASSIGN_REWARD_MANAGERS',
	CAN_TRANSFER_OWNERSHIP: 'CAN_TRANSFER_OWNERSHIP',
	CAN_CREATE_REWARDS: 'CAN_CREATE_REWARDS',
	CAN_MODIFY_EXISTING_REWARDS: 'CAN_MODIFY_EXISTING_REWARDS',
	CAN_SET_PRICE_PER_SLICE: 'CAN_SET_PRICE_PER_SLICE',
	CAN_UPGRADE_PIE: 'CAN_UPGRADE_PIE',
	CAN_SEND_SLICES: 'CAN_SEND_SLICES',
	CAN_SEND_REWARDS: 'CAN_SEND_REWARDS',
	CAN_SEND_ACHIEVEMENTS: 'CAN_SEND_ACHIEVEMENTS',
	CAN_CONNECT_SOCIAL_ACCOUNTS: 'CAN_CONNECT_SOCIAL_ACCOUNTS',
	CAN_REMOVE_SOCIAL_ACCOUNTS: 'CAN_REMOVE_SOCIAL_ACCOUNTS',
	CAN_CHANGE_PIE_NAME: 'CAN_CHANGE_PIE_NAME',
	CAN_CHANGE_PIE_APPEARANCE: 'CAN_CHANGE_PIE_APPEARANCE',
	CAN_RELEASE_MORE_SLICES_FOR_SALE: 'CAN_RELEASE_MORE_SLICES_FOR_SALE',
	CAN_CONNECT_PAYPAL_ACCOUNT: 'CAN_CONNECT_PAYPAL_ACCOUNT',
	CAN_AWARD_REWARDS: 'CAN_AWARD_REWARDS',
	CAN_CHANGE_PIE_URL: 'CAN_CHANGE_PIE_URL',
	CAN_CHANGE_PIE_BIO: 'CAN_CHANGE_PIE_BIO',
	CAN_SHARE_ACHIEVEMENTS: 'CAN_SHARE_ACHIEVEMENTS',
	CAN_SHARE_REWARDS: 'CAN_SHARE_REWARDS',
	CAN_SHARE_PIE: 'CAN_SHARE_PIE',
	CAN_VIEW_REWARDS: 'CAN_VIEW_REWARDS',
	CAN_VIEW_ACHIEVEMENTS: 'CAN_VIEW_ACHIEVEMENTS',
}

export const MEDIA_FOLDER = {
	rewardAvatars: 'reward-avatars',
	achievementAvatars: 'achievement-avatars',
}

export const EXCLUDE_COUNTRIES = ['kp', 'cu', 'ir', 'sy', 've', 'ru']

export const COUNTRY_DATA = CountryRegionData.filter(
	(item: any) => !EXCLUDE_COUNTRIES.includes(item[1].toLowerCase())
).map(item => ({ name: item[0], value: item[1] }))

export const SEARCH_TAB_ITEMS = [
	{ name: 'All', value: 'all', disabled: false },
	{ name: 'People', value: 'people', disabled: true },
	{ name: 'Pies', value: 'pies', disabled: true },
]

export const NOTIFICATION_TAB_ITEMS = [
	{ name: 'All', value: 'all' },
	{ name: 'Requests', value: 'requests' },
	{ name: 'Offers', value: 'offers' },
	{ name: 'Achievements', value: 'achievements' },
	{ name: 'Events', value: 'events' },
]

export const PIE_HUB_EDIT_TAB_ITEMS = [
	{ name: 'Details', value: 'details' },
	{ name: 'Appearance', value: 'appearance' },
	{ name: 'Slices', value: 'slices' },
	{ name: 'Upgrade', value: 'upgrade' },
	{ name: 'Manage', value: 'manage' },
]

export const NUMBER_SLICES_OPTIONS = [
	{
		slice: 10000,
		price: 0,
	},
	{
		slice: 100000,
		price: 10,
	},
	{
		slice: 1000000,
		price: 100,
	},
	{
		slice: 10000000,
		price: 1000,
	},
	{
		slice: 50000000,
		price: 5000,
	},
	{
		slice: 100000000,
		price: 10000,
	},
	{
		slice: 500000000,
		price: 50000,
	},
	{
		slice: 1000000000,
		price: 100000,
	},
]

export const MANDATORY_APPEARENCE_CATEGORIES = ['background', 'napkin', 'crust', 'filling']

export const PERCENTAGE_SPLIT_VALUE = 0.2

export const SOCIAL_CONNECTIONS = [
	{
		scheme: 'instagram',
		displayName: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com',
		hidden: import.meta.env.VITE_ENABLE_FEATURE_CONNECT_INSTAGRAM === 'true' ? false : true,
	},
	{
		scheme: 'facebook',
		displayName: 'Facebook',
		icon: 'facebook',
		url: 'https://facebook.com',
		hidden: import.meta.env.VITE_ENABLE_FEATURE_CONNECT_FACEBOOK === 'true' ? false : true,
	},
	{
		scheme: 'twitter',
		displayName: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com',
		hidden: false,
	},
	{
		scheme: 'youtube',
		displayName: 'Youtube',
		icon: 'youtube',
		url: 'https://youtube.com',
		hidden: false,
	},
	{
		scheme: 'tiktok',
		displayName: 'Tiktok',
		icon: 'tiktok',
		url: 'https://tiktok.com',
		hidden: false,
	},
	{
		scheme: 'spotify',
		displayName: 'Spotify',
		icon: 'spotify',
		url: 'https://spotify.com',
		hidden: false,
	},
	{
		scheme: 'twitch',
		displayName: 'Twitch',
		icon: 'twitch',
		url: 'https://twitch.tv',
		hidden: false,
	},
	// {
	// 	scheme: 'amazonmusic',
	// 	displayName: 'Amazon Music',
	// 	icon: 'amazonmusic',
	// 	url: 'https://music.amazon.com',
	// 	hidden: false,
	// },
].filter(item => !item.hidden)

export const EVENT_CREATE_TAB_ITEMS = [
	{ name: 'Info', value: 'info', hidden: false },
	{ name: 'Tickets', value: 'guest', hidden: false },
	{ name: 'Settings', value: 'settings', hidden: false },
	{ name: 'Questions', value: 'questions', hidden: false },
	{
		name: 'Rep Clubs',
		value: 'rep-clubs',
		hidden: import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true' ? false : true,
	},
	{ name: 'Preview', value: 'preview', hidden: false },
].filter(item => !item.hidden)

export const MINIMUM_PRICE_PER_SLICE_VALUE = 3

export const DEFAULT_EXTENDED_MINUTES = 15

export const DOCUMENT_UPLOAD_LIMIT_SIZE = 10

export const IMAGE_UPLOAD_LIMIT_SIZE = 30

export const MEASURING_LENGTH_UNITS = [
	{ name: 'mi', value: LocationUnitLengthType.Miles },
	{ name: 'km', value: LocationUnitLengthType.Kilometers },
]

export const LOCATION_UNITS = [
	{ name: 'mi', value: LocationUnit.Miles },
	{ name: 'km', value: LocationUnit.Kilometers },
]

export const DEFAULT_MAP_COORDS = {
	lat: 34.052235,
	lng: -118.243683,
}

export const DEFAULT_MAP_RADIUS = 0.5

export const DEFAULT_MAX_SLICES = 10000000

export const EMPTY_QUESTION = { question: '', isRequired: false }

export const EMPTY_REP_POINT_BUCKET = {
	pointsAmount: '',
	repPointsMultiplier: '',
	originalPrice: '',
}

export const SOCIAL_REQUIRED_CONNECTIONS = [
	{
		scheme: 'instagram',
		displayName: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com',
		hidden: import.meta.env.VITE_ENABLE_FEATURE_CONNECT_INSTAGRAM === 'true' ? false : true,
	},
	{
		scheme: 'twitter',
		displayName: 'X',
		icon: 'twitter',
		url: 'https://twitter.com',
		hidden: false,
	},
	{
		scheme: 'youtube',
		displayName: 'Youtube',
		icon: 'youtube',
		url: 'https://youtube.com',
		hidden: false,
	},
].filter(item => !item.hidden)

export const REP_CLUBS_CREATE_TAB_ITEMS = [
	{ name: 'Club Type', value: 'club-type', formTitle: 'Select Your Club Type', hidden: true },
	{ name: 'Details', value: 'details', formTitle: 'Details', hidden: false },
	{ name: 'Image', value: 'image', formTitle: 'Rep Club Image', hidden: false },
	{ name: 'Accounts', value: 'accounts', formTitle: 'Connect Accounts', hidden: false },
	{ name: 'Preview', value: 'preview', formTitle: 'Review Rep Club', hidden: false },
].filter(item => !item.hidden)
export const DEFAULT_REP_SCORE = 10

export const TICKET_TYPES = [
	{
		name: 'Add Paid Ticket',
		value: 'paid',
		icon: 'dollar',
		description: `Charge people to attend your event and set prices for ticket types or exclusive areas.`,
	},
	{
		name: 'Add Free Ticket',
		value: 'free',
		icon: 'ticket',
		description: `Offer complimentary access for guests to access your event.`,
	},
]

export const OFFER_CREATE_TAB_ITEMS = [
	{ name: 'Details', value: 'details', hidden: false },
	{ name: 'Requirements', value: 'requirements', hidden: false },
	{ name: 'Time Frame', value: 'time-frame', hidden: false },
	{ name: 'Settings', value: 'settings', hidden: false },
	{ name: 'Redemption', value: 'redemption', hidden: false },
	{ name: 'Achievement', value: 'achievement', hidden: false },
	{ name: 'Preview', value: 'preview', hidden: false },
].filter(item => !item.hidden)

export const OFFER_TYPES = [
	{
		name: 'Ways To Earn Points',
		value: OfferType.EarnPoints,
		icon: 'rep-points',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Ways To Buy Points',
		value: OfferType.BuyPoints,
		icon: 'dollar',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Ways To Earn Status',
		value: OfferType.EarnStatus,
		icon: 'star-in-star',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Ways To Reward Members',
		value: OfferType.RewardMembers,
		icon: 'rewards',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
]

export const REWARD_EVENT_TYPES = [
	{
		name: 'Invite',
		value: OfferRewardType.Invite,
		icon: 'email',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Event',
		value: OfferRewardType.Event,
		icon: 'calendar',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'VIP Pass',
		value: OfferRewardType.VipPass,
		icon: 'star-in-star',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Meet Up',
		value: OfferRewardType.MeetUp,
		icon: 'shake-hand',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Buy Now',
		value: OfferRewardType.BuyNow,
		icon: 'dollar',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Coupon',
		value: OfferRewardType.Coupon,
		icon: 'price',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Merch Giveaway',
		value: OfferRewardType.MerchGiveaway,
		icon: 'shirt',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Community',
		value: OfferRewardType.Community,
		icon: 'community-user',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Exclusive Content',
		value: OfferRewardType.ExclusiveContent,
		icon: 'exclusive',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
	{
		name: 'Other',
		value: OfferRewardType.Other,
		icon: 'other',
		description: `Welcome fans into your exclusive community! Elevate relationships with your audience, collaborate with brands, and gain insights into fan interactions.`,
	},
]

export const REP_SCORE_FILTER_OPTIONS = [
	{ name: 'All', value: null },
	{ name: '0 - 50', value: '0-50' },
	{ name: '50-80', value: '50-80' },
	{ name: '80', value: '80-' },
]

export const GENDER_FILTER_OPTIONS = [
	{ name: 'All', value: null },
	{ name: 'Male', value: UserGender.Male },
	{ name: 'Female', value: UserGender.Female },
	{ name: 'Other', value: UserGender.Other },
]

export const INVITE_STATUS_FILTER_OPTIONS = [
	{ name: 'All', value: ContactInviteStatus.All },
	{ name: 'Invite Pending', value: ContactInviteStatus.InvitePending },
	{ name: 'Invited', value: ContactInviteStatus.Invited },
	{ name: 'Not Invited', value: ContactInviteStatus.NotInvited },
]

export const AGE_RANGE_FILTER_OPTIONS = [
	{ name: 'All', value: null },
	{ name: '18-24', value: '18-24' },
	{ name: '25-34', value: '25-34' },
	{ name: '35-44', value: '35-44' },
	{ name: '45+', value: '45-' },
]

export const COUNTRY_DATA_FILTER_OPTIONS = [{ name: 'All', value: null }, ...COUNTRY_DATA]

export const REWARD_TIME_FRAME_METHOD = [
	{
		name: 'Promotion',
		value: RewardTimeFrameType.Promotion,
		icon: 'hand-speaker',
		description: `Lorem ipsum dolor sit amet consectetur. Fermentum non in vitae egestas tellus arc.`,
	},
	{
		name: 'Draw',
		value: RewardTimeFrameType.Draw,
		icon: 'ticket',
		description: `Lorem ipsum dolor sit amet consectetur. Fermentum non in vitae egestas tellus arc.`,
	},
]

export const REWARD_REQUIREMENTS = [
	{ name: 'Price', value: 'price', limit: 1 },
	{ name: 'Loyalty Status', value: 'loyalty-status', limit: 5 },
	{ name: 'Rep Points', value: 'rep-points', limit: 5 },
	{ name: 'Rep Score', value: 'rep-score', limit: 5 },
	{ name: 'Achievements', value: 'achievement', limit: 1 },
	{ name: 'None', value: 'none', limit: 1 },
]
