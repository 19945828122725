import { PropsWithChildren, Fragment, useState, useLayoutEffect } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import classNames from 'classnames'

import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'
import { ConfirmationModal, IconSvg, AvatarImage } from 'components/common'

interface Props {
	position?: string
}

export function ProfileDropdown({ position }: PropsWithChildren<Props>) {
	const navigate = useNavigate()
	const { logout } = useAuth()
	const { user, clearStore, myEvents, myClubs, refetchMyEvents } = useStore()
	const [searchParams, setSearchParams] = useSearchParams()
	const [open, setOpen] = useState<boolean>(false)

	useLayoutEffect(() => {
		refetchMyEvents()
	}, [])

	const removeQueryParams = () => {
		const param = searchParams.get('urlToken')
		if (param) {
			searchParams.delete('urlToken')
			setSearchParams(searchParams)
		}
	}

	return (
		<div>
			<Menu as="div" className="relative text-left">
				<div className="flex items-center">
					<MenuButton className="inline-flex items-center justify-between rounded-lg group">
						<div className="flex items-center min-w-0 flex-shrink-0">
							<AvatarImage
								className="h-[40px] w-[40px] rounded-full object-cover flex-shrink-0"
								src={user?.avatarUrl}
								alt={user?.userName}
							/>
						</div>
					</MenuButton>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<MenuItems
						className={classNames(
							'absolute right-0 rounded-lg shadow-box bg-primary-tile focus:outline-none mt-1 px-4 pb-6 pt-2 w-[300px] z-10 ring-1 ring-black ring-opacity-5',
							{
								'origin-top-right': position !== 'top',
								'origin-bottom-right bottom-full': position === 'top',
							}
						)}
					>
						<div className="flex justify-between items-center">
							<div className="flex items-center min-w-0">
								<AvatarImage
									className="w-[24px] h-[24px] rounded-full object-cover flex-shrink-0"
									src={user?.avatarUrl}
									alt={user?.userName}
								/>
								<div className="px-3 py-1 min-w-0">
									<p className="truncate font-medium">{user?.name}</p>
								</div>
							</div>
							<IconSvg icon="chevron-up-2" />
						</div>

						<div className="space-y-1 mt-4">
							<MenuItem>
								{({ active }) => (
									<Link
										to="/profile/information"
										className={classNames('py-3 flex space-x-2 items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
										})}
									>
										<IconSvg icon="profile" className="text-white w-6" aria-hidden="true" />
										<span>Profile information</span>
									</Link>
								)}
							</MenuItem>
							<hr className="border-white-10 my-2" />
							{import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true' && (
								<MenuItem>
									{({ active }) => (
										<Link
											to="/my/rep-clubs"
											className={classNames('py-3 flex space-x-2 items-center', {
												'text-white font-bold': active,
												'text-white-75 hover:text-white hover:font-bold': !active,
											})}
										>
											<IconSvg
												icon="repclub"
												className={classNames('w-6 text-white', {})}
												aria-hidden="true"
											/>
											<span>{myClubs?.length > 0 ? 'My Rep Clubs' : 'Create Rep Clubs'}</span>
										</Link>
									)}
								</MenuItem>
							)}
							<MenuItem>
								{({ active }) => (
									<Link
										to={
											import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true'
												? `/my/rep-clubs/${myClubs[0]?.slug}/events`
												: `/rep-club/events`
										}
										className={classNames('py-3 flex space-x-2 items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
											'pointer-events-none !text-white-10': myEvents.length === 0,
										})}
									>
										<IconSvg
											icon="event"
											className={classNames('w-6 text-white', {
												'pointer-events-none !text-white-10': myEvents.length === 0,
											})}
											aria-hidden="true"
										/>

										<span>Manage Events</span>
									</Link>
								)}
							</MenuItem>
							<hr className="border-white-10 my-2" />
							<MenuItem>
								{({ active }) => (
									<Link
										to="/profile/settings"
										className={classNames('py-3 flex space-x-2 items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
										})}
									>
										<IconSvg icon="settings" className="text-white w-6" aria-hidden="true" />
										<span>Settings</span>
									</Link>
								)}
							</MenuItem>
							<MenuItem>
								{({ active }) => (
									<Link
										to="mailto:support@repbets.com"
										className={classNames('py-3 flex space-x-2 items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
										})}
									>
										<IconSvg icon="support" className="text-white w-6" aria-hidden="true" />
										<span>Support</span>
									</Link>
								)}
							</MenuItem>
							{/* <MenuItem>
								{({ active }) => (
									<Link
										to="/profile/invites"
										className={classNames('py-3 flex space-x-2 text-sm items-center', {
											'text-white font-bold': active,
											'text-white-75 hover:text-white hover:font-bold': !active,
										})}
									>
										<IconSvg icon="profile-plus" className="text-white w-6" aria-hidden="true" />
										<span>Invite Friends</span>
									</Link>
								)}
							</MenuItem> */}
						</div>
						<hr className="border-white-10 my-2" />
						<div>
							<MenuItem>
								<button
									type="button"
									className="py-3 flex space-x-2 w-full text-white-75 hover:text-white hover:font-bold items-center"
									onClick={() => setOpen(true)}
								>
									<IconSvg icon="log-out" className="text-white w-6" aria-hidden="true" />
									<span>Log Out</span>
								</button>
							</MenuItem>
						</div>
					</MenuItems>
				</Transition>
			</Menu>
			<ConfirmationModal title="Log Out" open={open} setOpen={setOpen}>
				<div>
					<div className="mb-8">Are you sure you want to log out?</div>
					<div className="space-y-2">
						<button
							type="button"
							className="px-6 rounded-3xl text-center transition-all duration-300 bg-secondary-button text-white font-bold block w-full h-10 shadow-button backdrop-blur-lg hover:bg-secondary-button-hover disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
							onClick={() =>
								logout(null, null, () => {
									removeQueryParams()
									setOpen(false)
									clearStore()
									navigate('/auth/login', { replace: true })
								})
							}
						>
							Yes
						</button>
						<button
							type="button"
							className="px-6 rounded-3xl text-center transition-all duration-300 bg-white-10 text-white font-bold block w-full h-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
							onClick={() => setOpen(false)}
						>
							Cancel
						</button>
					</div>
				</div>
			</ConfirmationModal>
		</div>
	)
}
