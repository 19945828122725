import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useStore } from 'hooks/useStore'
import { ConfirmationModal } from 'components/common'
import { useDeleteAccountMutation } from 'graphql/generated/scheme'

interface Props {
	openDelete: boolean
	setOpenDelete: (...args: any) => any
}

export function DeleteAccountBlock({ openDelete, setOpenDelete }: Props) {
	const navigate = useNavigate()
	const { logout } = useAuth()
	const { clearStore } = useStore()
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false)
	const [deleteAccount, { data: deleteAccountData, loading: deleteAccountLoading, error: deleteAccountError }] =
		useDeleteAccountMutation()

	useEffect(() => {
		if (deleteAccountData) {
			logout(null, null, () => {
				clearStore()
				navigate('/auth/login', { replace: true })
			})
		}
		if (deleteAccountError) {
			toast.error(`Delete account failure!`)
			setOpenConfirmDelete(false)
		}
	}, [deleteAccountData, deleteAccountError])

	return (
		<>
			<div className="min-[1440px]:w-[1440px] w-full fixed top-0 left-1/2 -translate-x-1/2 h-0 z-[2]">
				<div className="absolute top-0 right-0 lg:w-[calc(100%-232px)] w-full h-0">
					<div
						className={classNames(
							'bg-primary-panel p-8 pt-[88px] -translate-y-full transition-all duration-300',
							{ '!translate-y-0': openDelete }
						)}
					>
						<div className="font-bold text-2xl">Delete account</div>
						<div className="flex justify-between items-center mt-2">
							<div className="leading-5 max-w-[448px]">
								If you delete your account you will have 90 days to reactivate or your data will be
								permanently deleted.
							</div>
							<div className="flex justify-end items-center gap-4">
								<button
									type="button"
									className="px-6 rounded-3xl transition-all duration-300 bg-white-10 font-bold h-12 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none min-w-[200px]"
									onClick={() => setOpenDelete(false)}
								>
									Cancel
								</button>
								<button
									type="button"
									className="px-6 rounded-3xl transition-all duration-300 bg-secondary-button font-bold h-12 hover:bg-secondary-button-hover min-w-[200px]"
									onClick={() => {
										setOpenDelete(false)
										setOpenConfirmDelete(true)
									}}
								>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ConfirmationModal title="Delete account" open={openConfirmDelete} setOpen={setOpenConfirmDelete}>
				<div>
					<div className="mb-8">
						We are sorry to see you go. Are you sure you want to delete your account?
					</div>
					<div className="space-y-2">
						<button
							type="button"
							className="px-6 rounded-3xl text-center transition-all duration-300 bg-secondary-button text-white font-bold block w-full h-10 shadow-button backdrop-blur-lg hover:bg-secondary-button-hover disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
							onClick={() => deleteAccount()}
							disabled={deleteAccountLoading}
						>
							{deleteAccountLoading && (
								<FontAwesomeIcon icon={['fas', 'spinner']} size="lg" spin className="mr-1" />
							)}
							Yes
						</button>
						<button
							type="button"
							className="px-6 rounded-3xl text-center transition-all duration-300 bg-white-10 text-white font-bold block w-full h-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
							onClick={() => setOpenConfirmDelete(false)}
							disabled={deleteAccountLoading}
						>
							Cancel
						</button>
					</div>
				</div>
			</ConfirmationModal>
		</>
	)
}
