import isUrlHttp from 'is-url-http'

import { DEFAULT_INFORMATION } from 'utils/constants'

export interface AvatarImageProps {
	src?: string | null
	className?: string
	alt?: string
	defaultImage?: string
}

export function AvatarImage({ src, defaultImage, ...props }: AvatarImageProps) {
	let url = src
	if (!url) {
		url = defaultImage ?? DEFAULT_INFORMATION.avatarImageUrl
	} else {
		if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
			url = `${import.meta.env.VITE_MEDIA_URL}/${src}`
		}
	}
	return <img src={url} {...props} />
}
