import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Logo } from 'components/common'
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useAuth } from 'hooks/useAuth'

export function LandingLayout() {
	const { token } = useAuth()
	return (
		<div>
			<Helmet
				bodyAttributes={{
					class: 'bg-body',
				}}
			/>
			<header>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-5 flex justify-between items-center">
					<a className="sm:block hidden" href="/">
						<Logo className="h-8" />
					</a>
					<a className="sm:hidden" href="/">
						<Logo className="h-6" />
					</a>
					<div className="flex items-center sm:space-x-10 space-x-4">
						{token ? (
							<a
								href={`/home`}
								className="text-sm px-4 rounded-[40px] h-10 flex items-center justify-center bg-primary font-medium"
							>
								Go To Home
							</a>
						) : (
							<>
								<a href={`/auth/login`} className="text-sm font-medium">
									Sign In
								</a>
								<a
									href={`/auth/sign-up`}
									className="text-sm px-4 rounded-[40px] h-10 flex items-center justify-center bg-primary font-medium"
								>
									Join Now
								</a>
							</>
						)}
					</div>
				</div>
			</header>

			<main className="min-h-screen">
				<Outlet />
			</main>

			<footer>
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<nav className="flex justify-between items-center lg:py-11 lg:flex-row lg:space-y-0 flex-col space-y-6 py-6">
						<div className="lg:block hidden">
							<span className="text-sm">&copy;{new Date().getFullYear()} RepBets</span>
						</div>
						<ul className="flex items-center justify-center lg:gap-8 gap-4 flex-wrap">
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.facebook.com/Repbets-142597437979324"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'facebook']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.instagram.com/repbets/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'instagram']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://twitter.com/repbets"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.twitch.tv/repbets"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'twitch']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.youtube.com/channel/UCghbP5BZckKVv45fpFr_bYg"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'youtube']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.linkedin.com/company/repbets/about/?viewAsMember=true"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'linkedin']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="sm:text-2xl text-xl"
									href="https://www.tiktok.com/@repbets?fromUrl=%2Frepbets&lang=en"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'tiktok']} fixedWidth />
								</a>
							</li>
							<li>
								<a
									className="lg:text-2xl text-lg"
									href="http://discord.gg/repbets"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FontAwesomeIcon icon={['fab', 'discord']} fixedWidth />
								</a>
							</li>
						</ul>
						<div className="flex justify-between items-center lg:w-auto w-full">
							<span className="text-sm lg:hidden">&copy;{new Date().getFullYear()} RepBets</span>
							<div className="space-x-6">
								<a className="text-sm underline cursor-pointer" href={`/terms-of-service`}>
									Terms of Service
								</a>
								<a className="text-sm underline cursor-pointer" href={`/privacy-policy`}>
									Privacy Policy
								</a>
							</div>
						</div>
					</nav>
				</div>
			</footer>
		</div>
	)
}
