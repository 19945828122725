import { useStore } from 'hooks/useStore'

export function DidYouKnowBlock() {
	const { didYouKnowContent } = useStore()

	return (
		<>
			<div className="p-4 rounded-2xl space-y-4 shadow-local border border-[#3D1277]">
				<h3 className="text-xl font-medium">Did You Know?</h3>
				<p className="text-sm text-white-75">
					{didYouKnowContent ?? `You can create your own Rep Club and Offers for your community.`}
				</p>
				<div className="font-bold px-6 rounded-3xl transition-all duration-300 w-full bg-secondary-button h-[40px] flex justify-center items-center space-x-1">
					Coming Soon
				</div>
			</div>
			<div className="p-4 text-center text-white-75">
				<div className="text-xs">
					<a
						className="hover:text-primary-hover transition-all duration-300"
						href={`/terms-of-service`}
						target="_blank"
					>
						Terms
					</a>
					{` and `}
					<a
						className="hover:text-primary-hover transition-all duration-300"
						href={`/privacy-policy`}
						target="_blank"
					>
						Privacy
					</a>
				</div>
				<div className="text-xs mt-2">&copy;RepBets {new Date().getFullYear()}</div>
			</div>
		</>
	)
}
