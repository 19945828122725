import { Outlet } from 'react-router-dom'
import { Logo } from 'components/common'
import { useStore } from 'hooks/useStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function AuthLayout() {
	const { loadingLogout } = useStore()

	return (
		<div className="h-screen">
			<div className="min-h-full flex items-stretch relative">
				<div className="absolute z-10 top-8 left-4 xs:left-8 hidden lg:block">
					<a href={import.meta.env.VITE_LANDING_URL}>
						<Logo className="h-7" />
					</a>
				</div>
				<div className="hidden lg:block relative w-0 flex-1 bg-auth-layout bg-no-repeat bg-cover bg-center" />
				<div className="flex-1 flex flex-col">
					<Outlet />
				</div>
			</div>
			{loadingLogout && (
				<div className="flex justify-center items-center py-4 w-full h-full fixed top-0 left-0 bg-primary-panel bg-opacity-90 z-[9999]">
					<FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin className="text-white" />
				</div>
			)}
		</div>
	)
}
