import { currencyFormat } from 'utils/common'

export function TrendingPiesBlock() {
	// TODO: Remove null when ready
	return null
	return (
		<div className="px-4 py-6 rounded-2xl space-y-6 border border-white-10">
			<h3 className="text-xl font-medium">Trending Pies</h3>
			<div className="space-y-4">
				<div className="space-y-1">
					<div className="text-secondary-text text-sm tracking-[0.03em]">Michael Ramon</div>
					<div className="font-bold">{currencyFormat(154.454)}</div>
				</div>
				<div className="space-y-1">
					<div className="text-secondary-text text-sm tracking-[0.03em]">Brittany Johnson</div>
					<div className="font-bold">{currencyFormat(154.454)}</div>
				</div>
				<div className="space-y-1">
					<div className="text-secondary-text text-sm tracking-[0.03em]">Robert Fox</div>
					<div className="font-bold">{currencyFormat(154.454)}</div>
				</div>
				<div className="space-y-1">
					<div className="text-secondary-text text-sm tracking-[0.03em]">Eleanor Robinson</div>
					<div className="font-bold">{currencyFormat(154.454)}</div>
				</div>
			</div>
		</div>
	)
}
