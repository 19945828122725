import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import loadable from '@loadable/component'

import {
	AuthLayout,
	MainLayout,
	PaymentLayout,
	LandingLayout,
	PayoutLayout,
	EmptyLayout,
	PublicLayout,
	EventLayout,
} from 'containers/layouts'
import {
	PublicGuard,
	AuthGuard,
	SocialProcessGuard,
	PieHubGuard,
	PaymentGuard,
	RecoverGuard,
	SignUp2Guard,
	MfaGuard,
	PayoutProcessGuard,
	InviteGuard,
} from 'guards'
import { useAuth } from 'hooks/useAuth'
import { ComponentClass } from 'react'

//Helper pages
const IconPage = loadable(() => lazyRetry(() => import('pages/HelperPages/Icons')))

// auth pages
const LoginPage = loadable(() => lazyRetry(() => import('pages/Auth/Login')))
const SignUpPage = loadable(() => lazyRetry(() => import('pages/Auth/SignUp')))
const SignUp2Page = loadable(() => lazyRetry(() => import('pages/Auth/SignUp2')))
const ForgotPasswordPage = loadable(() => lazyRetry(() => import('pages/Auth/ForgotPassword')))
const ResetPasswordPage = loadable(() => lazyRetry(() => import('pages/Auth/ResetPassword')))
const TwoFAVerificationPage = loadable(() => lazyRetry(() => import('pages/Auth/TwoFAVerification')))
const AuthProcessPage = loadable(() => lazyRetry(() => import('pages/Auth/Process')))
const RecoverPage = loadable(() => lazyRetry(() => import('pages/Auth/Recover')))
const ImLoginPage = loadable(() => lazyRetry(() => import('pages/Auth/im-login')))

// main pages
const PieClubPage = loadable(() => lazyRetry(() => import('pages/PieClub')))
const EventsPage = loadable(() => lazyRetry(() => import('pages/Events')))
const SearchPage = loadable(() => lazyRetry(() => import('pages/Search')))
const PieDetailPage = loadable(() => lazyRetry(() => import('pages/Pies/Detail')))
const RewardDetailPage = loadable(() => lazyRetry(() => import('pages/Rewards/Detail')))
const RewardDetailPublicPage = loadable(() => lazyRetry(() => import('pages/Rewards/DetailPublic')))
const AchievementDetailPage = loadable(() => lazyRetry(() => import('pages/Achievements/Detail')))
const SlicesPage = loadable(() => lazyRetry(() => import('pages/Slices')))
const TradePage = loadable(() => lazyRetry(() => import('pages/Trade')))
const TradeHubPage = loadable(() => lazyRetry(() => import('pages/TradeHub')))
// const TopUpPage = loadable(() => lazyRetry(() => import('pages/Slices/TopUp')))
const ProfilePage = loadable(() => lazyRetry(() => import('pages/Profile')))
const ProfileInformationPage = loadable(() => lazyRetry(() => import('pages/Profile/Information')))
const PersonalPage = loadable(() => lazyRetry(() => import('pages/Profile/Personal')))
const SocialPage = loadable(() => lazyRetry(() => import('pages/Profile/Social')))
const LocationPage = loadable(() => lazyRetry(() => import('pages/Profile/Location')))
const NotificationsPage = loadable(() => lazyRetry(() => import('pages/Profile/Notifications')))
const InvitesPage = loadable(() => lazyRetry(() => import('pages/Profile/Invites')))
const RewardsPage = loadable(() => lazyRetry(() => import('pages/Rewards')))
const PieHubPage = loadable(() => lazyRetry(() => import('pages/PieHub')))
const PieHubDetailsPage = loadable(() => lazyRetry(() => import('pages/PieHub/Details')))
const PieHubAppearancePage = loadable(() => lazyRetry(() => import('pages/PieHub/Appearance')))
const PieHubSlicesPage = loadable(() => lazyRetry(() => import('pages/PieHub/Slices')))
const PieHubSummaryPage = loadable(() => lazyRetry(() => import('pages/PieHub/Summary')))
const PieHubOverviewPage = loadable(() => lazyRetry(() => import('pages/PieHub/Overview')))
const PieHubOverviewEditPage = loadable(() => lazyRetry(() => import('pages/PieHub/Edit')))
const PieHubSalesPage = loadable(() => lazyRetry(() => import('pages/PieHub/Sales')))
const PieHubRewardsPage = loadable(() => lazyRetry(() => import('pages/PieHub/Rewards')))
// const PieHubRewardCreatePage = loadable(() => lazyRetry(() => import('pages/PieHub/Rewards/Create')))
const PieHubAchievementsPage = loadable(() => lazyRetry(() => import('pages/PieHub/Achievements')))
const PieHubAchievementCreatePage = loadable(() => lazyRetry(() => import('pages/PieHub/Achievements/Create')))
const PieHubSendPage = loadable(() => lazyRetry(() => import('pages/PieHub/Send')))
const SettingsPage = loadable(() => lazyRetry(() => import('pages/Settings')))
const PaymentCheckoutPage = loadable(() => lazyRetry(() => import('pages/Payment/Checkout')))
const PaymentBuyPage = loadable(() => lazyRetry(() => import('pages/Payment/Buy')))
const TermsOfServicePage = loadable(() => lazyRetry(() => import('pages/TermsOfService')))
const PrivacyPolicyPage = loadable(() => lazyRetry(() => import('pages/PrivacyPolicy')))
const AcceptInvitePage = loadable(() => lazyRetry(() => import('pages/AcceptInvite')))
const FacebookDataDeletionInstructionsUrlPage = loadable(() =>
	lazyRetry(() => import('pages/FacebookDataDeletionInstructionsUrl'))
)
const UnsubscribePage = loadable(() => lazyRetry(() => import('pages/Unsubscribe')))

// rep club
const HowToCreateIt = loadable(() => lazyRetry(() => import('pages/RepClubs/HowToCreateIt/HowToCreateIt')))
const RepClubCreateEdit = loadable(() => lazyRetry(() => import('pages/RepClubs/CreateEdit/CreateEdit')))
const MyRepClubDetails = loadable(() => lazyRetry(() => import('pages/RepClubs/MyRepClubDetail/MyRepClubDetail')))
const RepClubDetails = loadable(() => lazyRetry(() => import('pages/RepClubs/RepClubDetail/RepClubDetail')))
const RepClubHomePage = loadable(() => lazyRetry(() => import('pages/RepClubs')))
const RepClubEventsPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events')))
const RepClubEventCreatePage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events/Create')))
const RepClubEventDetailPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events/Detail')))
const RepClubEventApprovalPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events/Approval')))
const RepClubEventInvitePage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events/Invite')))
const RepClubEventGuestPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Events/guest')))
const RepClubTeamPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Team')))
const RepClubOffersPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Offers')))
const RepClubOffersCreatePage = loadable(() => lazyRetry(() => import('pages/RepClubs/Offers/WayToRewardMembers')))
const RepClubSelectOfferType = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/SelectOfferType/SelectOfferType'))
)
const RepClubSelectOfferSubType = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/SelectOfferSubType/SelectOfferSubType'))
)
const RepClubAchievementsPage = loadable(() => lazyRetry(() => import('pages/RepClubs/Achievements/Achivements')))
const RepClubAchievementCreatePage = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Achievements/Create/Create'))
)
const RepClubAchievementDetailPage = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Achievements/Detail/Detail'))
)
const RepClubLoyaltyTiersPage = loadable(() => lazyRetry(() => import('pages/RepClubs/LoyaltyTiers/LoyaltyTiers')))

// RepPoints
const RepClubRepPointsPage = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/RepPoints/ManageRepPoints/ManageRepPoints'))
)
const RepClubBuyPointsPage = loadable(() => lazyRetry(() => import('pages/RepClubs/RepPoints/BuyPoints/BuyPoints')))
const RepClubRepPointsTradeRequests = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/RepPoints/TradeRequests/TradeRequests'))
)
const RepClubRepPointsTradeHistory = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/RepPoints/TradeHistory/TradeHistory'))
)
const RepClubRepPointsMyPoints = loadable(() => lazyRetry(() => import('pages/RepClubs/RepPoints/MyPoints/MyPoints')))

// WayToEarnStatus
const RepClubWayToEarnStatusBuyNow = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/BuyNow/BuyNow'))
)
const RepClubWayToEarnStatusActionAmount = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/ActionAmount/ActionAmount'))
)
const RepClubWayToEarnStatusInviteOnly = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/InviteOnly/InviteOnly'))
)
const RepClubWayToEarnStatusLevelUp = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/LevelUp/LevelUp'))
)
const RepClubWayToEarnStatusPromo = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/Promo/Promo'))
)
const RepClubWayToEarnStatusStreak = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnStatus/Streak/Streak'))
)
// WayToEarnPoints
const RepClubWayToEarnPointsOnLocation = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/OnLocation/OnLocation'))
)
const RepClubWayToEarnPointsSocialAction = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/SocialAction/SocialAction'))
)
const RepClubWayToEarnPointsStreak = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/Streak/Streak'))
)
const RepClubWayToEarnPointsSurveys = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/Surveys/Surveys'))
)
const RepClubWayToEarnPointsBonus = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/Bonus/Bonus'))
)
const RepClubWayToEarnPointsDownload = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/Download/Download'))
)
const RepClubWayToEarnPointsInteractWithBrandedPost = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/InteractWithBrandedPost/InteractWithBrandedPost'))
)
const RepClubWayToEarnPointsBuyProduct = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToEarnPoints/BuyProduct/BuyProduct'))
)
// WayToBuyPoints
const RepClubWayToBuyPointsBonusBuy = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToBuyPoints/BonusBuy/BonusBuy'))
)
const RepClubWayToBuyPointsPointsBundle = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToBuyPoints/PointsBundle/PointsBundle'))
)
const RepClubWayToBuyPointsStreak = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToBuyPoints/Streak/Streak'))
)
const RepClubWayToBuyPointsTimeSpan = loadable(() =>
	lazyRetry(() => import('pages/RepClubs/Offers/WayToBuyPoints/TimeSpan/TimeSpan'))
)

//event pages
const EventRSVPPage = loadable(() => lazyRetry(() => import('pages/Events/RSVP')))

// payout pages
const PayoutProcessPage = loadable(() => lazyRetry(() => import('pages/Payout/Process')))

// other pages
const NotFoundPage = loadable(() => lazyRetry(() => import('pages/NotFound')))

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (
	componentImport: any
): Promise<ComponentClass<any, any> | { default: ComponentClass<any, any> }> {
	return new Promise((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false')
		// try to import the component
		componentImport()
			.then((component: any) => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false') // success so reset the refresh
				resolve(component)
			})
			.catch((error: any) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem('retry-lazy-refreshed', 'true') // we are now going to refresh
					return window.location.reload() // refresh the page
				}
				reject(error) // Default error behaviour as already tried refresh
			})
	})
}

export default function Router() {
	const { token } = useAuth()
	return (
		<BrowserRouter>
			<Routes>
				<Route path="qa/icons" element={<IconPage />} />
				<Route path="auth" element={<AuthLayout />}>
					<Route index element={<Navigate to="login" replace />} />
					<Route
						path="login"
						element={
							<PublicGuard>
								<LoginPage />
							</PublicGuard>
						}
					/>
					<Route
						path="im-login"
						element={
							<PublicGuard>
								<ImLoginPage />
							</PublicGuard>
						}
					/>
					<Route
						path="sign-up"
						element={
							<PublicGuard>
								<SignUpPage />
							</PublicGuard>
						}
					/>
					<Route
						path="sign-up-2"
						element={
							<SignUp2Guard>
								<SignUp2Page />
							</SignUp2Guard>
						}
					/>
					<Route
						path="forgot-password"
						element={
							<PublicGuard>
								<ForgotPasswordPage />
							</PublicGuard>
						}
					/>
					<Route
						path="reset-password"
						element={
							<PublicGuard>
								<ResetPasswordPage />
							</PublicGuard>
						}
					/>
					<Route
						path="confirm-email"
						element={
							<PublicGuard>
								<TwoFAVerificationPage />
							</PublicGuard>
						}
					/>
					<Route
						path="mfa"
						element={
							<MfaGuard>
								<TwoFAVerificationPage />
							</MfaGuard>
						}
					/>
					<Route
						path="process"
						element={
							<SocialProcessGuard>
								<AuthProcessPage />
							</SocialProcessGuard>
						}
					/>
					<Route
						path="recover"
						element={
							<RecoverGuard>
								<RecoverPage />
							</RecoverGuard>
						}
					/>
				</Route>
				<Route path="tabs/profile" element={<AuthLayout />}>
					<Route
						path="confirm-email"
						element={
							<PublicGuard>
								<TwoFAVerificationPage />
							</PublicGuard>
						}
					/>
				</Route>
				<Route path="payout" element={<PayoutLayout />}>
					<Route
						path="process"
						element={
							<PayoutProcessGuard>
								<PayoutProcessPage />
							</PayoutProcessGuard>
						}
					/>
				</Route>
				<Route path="terms-of-service" element={<LandingLayout />}>
					<Route index element={<TermsOfServicePage />} />
				</Route>
				<Route path="privacy-policy" element={<LandingLayout />}>
					<Route index element={<PrivacyPolicyPage />} />
				</Route>
				<Route path="facebook-data-deletion-instructions-url" element={<LandingLayout />}>
					<Route index element={<FacebookDataDeletionInstructionsUrlPage />} />
				</Route>
				<Route path="unsubscribe" element={<LandingLayout />}>
					<Route index element={<UnsubscribePage />} />
				</Route>
				<Route
					path="rep-club/events/create"
					element={
						<AuthGuard>
							<EventLayout />
						</AuthGuard>
					}
				>
					<Route index element={<RepClubEventCreatePage title="Create event" />} />
				</Route>
				<Route
					path="events/edit/:id"
					element={
						<AuthGuard>
							<EventLayout />
						</AuthGuard>
					}
				>
					<Route index element={<RepClubEventCreatePage title="Edit event" />} />
				</Route>
				<Route
					path="events/:id"
					element={
						<AuthGuard>
							<EventLayout />
						</AuthGuard>
					}
				>
					<Route index element={<EventRSVPPage title="Event detail" />} />
				</Route>
				<Route path="my/rep-clubs/" element={<MainLayout />}>
					<Route index element={<HowToCreateIt />} />
					<Route path=":id" element={<MyRepClubDetails />} />
					<Route
						path="create"
						element={
							<AuthGuard>
								<RepClubCreateEdit />
							</AuthGuard>
						}
					/>
					<Route path=":id/edit" element={<RepClubCreateEdit />} />
				</Route>
				<Route
					path="/"
					element={
						<AuthGuard exceptedRoutes={['/pies/:id/rewards/:rewardId']}>
							{token ? <MainLayout /> : <PublicLayout />}
						</AuthGuard>
					}
				>
					<Route
						index
						element={
							<Navigate
								to={import.meta.env.VITE_ENABLE_FEATURE_REPCLUB === 'true' ? `home` : `events`}
								replace
							/>
						}
					/>
					<Route path="home" element={<RepClubHomePage title="Home" />} />
					<Route path="events" element={<EventsPage title="Events" />} />
					<Route path="trade-hub" element={<TradeHubPage title="Trade Hub" />} />
					<Route path="pie-clubs" element={<PieClubPage title="Home" />} />
					<Route path="search" element={<SearchPage title="Search results" />} />
					<Route path="pies/:id">
						<Route index element={<PieDetailPage title="Pie" />} />
						<Route
							path="rewards/:rewardId"
							element={
								token ? <RewardDetailPage title="Reward" /> : <RewardDetailPublicPage title="Reward" />
							}
						/>
						<Route
							path="achievements/:achievementId"
							element={<AchievementDetailPage title="Achievement" />}
						/>
					</Route>
					<Route path="slices">
						<Route index element={<SlicesPage title="Slices" />} />
						{/* <Route path="top-up" element={<TopUpPage title="Add USD" />} /> */}
					</Route>

					<Route path="rep-clubs">
						<Route path=":id" element={<RepClubDetails />} />
						<Route path=":id/offers" element={<div>Offers</div>} />
						<Route path=":id/buy-points" element={<RepClubBuyPointsPage />} />
					</Route>
					<Route path="rep-points">
						<Route path="my-points" element={<RepClubRepPointsMyPoints title="My rep points" />} />
						<Route
							path="trade-history"
							element={<RepClubRepPointsTradeHistory title="Rep points trade history" />}
						/>
						<Route
							path="trade-requests"
							element={<RepClubRepPointsTradeRequests title="Rep points trade requests" />}
						/>
					</Route>
					<Route path="trade/:id" element={<TradePage title="Buy / Send" />} />
					<Route path="profile">
						<Route index element={<ProfilePage title="Profile" />} />
						<Route path="information" element={<ProfileInformationPage title="Profile information" />} />
						<Route path="personal" element={<PersonalPage title="Personal info" />} />
						<Route path="social" element={<SocialPage title="Social accounts" />} />
						<Route path="location" element={<LocationPage title="Location info" />} />
						<Route path="notifications" element={<NotificationsPage title="Notifications" />} />
						<Route path="invites" element={<InvitesPage title="Rep Club invites" />} />
						<Route path="settings" element={<SettingsPage title="Settings" />} />
					</Route>
					<Route path="rewards">
						<Route index element={<RewardsPage title="Rewards" />} />
					</Route>

					<Route path="my/rep-clubs/:club_id">
						<Route path="team" element={<RepClubTeamPage title="Manage Team" />} />
						<Route path="events">
							<Route index element={<RepClubEventsPage title="Manage Events" />} />
							<Route path=":id">
								<Route index element={<RepClubEventDetailPage title="Event" />} />
								<Route path="approval" element={<RepClubEventApprovalPage title="Event" />} />
								<Route path="invite" element={<RepClubEventInvitePage title="Event" />} />
							</Route>
						</Route>
						<Route path="team" element={<RepClubTeamPage title="Manage Team" />} />
						<Route path="rep-points" element={<RepClubRepPointsPage title="Manage Rep Points" />} />
						<Route
							path="loyalty-tiers"
							element={<RepClubLoyaltyTiersPage title="Manage Loyalty Tiers" />}
						/>
						<Route path="offers">
							<Route index element={<RepClubOffersPage title="Manage Offers" />} />
							<Route path="create" element={<RepClubOffersCreatePage title="Add Offer" />} />
							<Route
								path="select-offer-type"
								element={<RepClubSelectOfferType title="Select Offer Type" />}
							/>
							<Route path=":offer_type" element={<RepClubSelectOfferSubType />} />
							<Route
								path="way-to-earn-status/action-amount"
								element={<RepClubWayToEarnStatusActionAmount />}
							/>
							<Route path="way-to-earn-status/buy-now" element={<RepClubWayToEarnStatusBuyNow />} />
							<Route
								path="way-to-earn-status/invite-only"
								element={<RepClubWayToEarnStatusInviteOnly />}
							/>
							<Route path="way-to-earn-status/level-up" element={<RepClubWayToEarnStatusLevelUp />} />
							<Route path="way-to-earn-status/promo" element={<RepClubWayToEarnStatusPromo />} />
							<Route path="way-to-earn-status/streak" element={<RepClubWayToEarnStatusStreak />} />

							<Route path="way-to-earn-points/streak" element={<RepClubWayToEarnPointsStreak />} />
							<Route
								path="way-to-earn-points/social-action"
								element={<RepClubWayToEarnPointsSocialAction />}
							/>
							<Route path="way-to-earn-points/location" element={<RepClubWayToEarnPointsOnLocation />} />
							<Route path="way-to-earn-points/survey" element={<RepClubWayToEarnPointsSurveys />} />
							<Route path="way-to-earn-points/bonus" element={<RepClubWayToEarnPointsBonus />} />
							<Route path="way-to-earn-points/download" element={<RepClubWayToEarnPointsDownload />} />
							<Route
								path="way-to-earn-points/interact"
								element={<RepClubWayToEarnPointsInteractWithBrandedPost />}
							/>
							<Route
								path="way-to-earn-points/buy-product"
								element={<RepClubWayToEarnPointsBuyProduct />}
							/>

							<Route
								path="way-to-buy-points/points-bundle"
								element={<RepClubWayToBuyPointsPointsBundle />}
							/>
							<Route path="way-to-buy-points/bonus-buys" element={<RepClubWayToBuyPointsBonusBuy />} />
							<Route path="way-to-buy-points/time-span" element={<RepClubWayToBuyPointsTimeSpan />} />
							<Route path="way-to-buy-points/streak" element={<RepClubWayToBuyPointsStreak />} />
						</Route>
						<Route path="achievements">
							<Route index element={<RepClubAchievementsPage title="Achievements" />} />
							<Route path="create" element={<RepClubAchievementCreatePage title="Add Achievement" />} />
							<Route path=":id">
								<Route index element={<RepClubAchievementDetailPage title="Achievement" />} />
								<Route
									path="edit"
									element={<RepClubAchievementCreatePage title="Edit Achievement" />}
								/>
							</Route>
						</Route>
					</Route>

					{/* some routes are duplicated with /my/rep-clubs/... need to review and remove later */}
					<Route path="rep-club">
						<Route path="events">
							<Route index element={<RepClubEventsPage title="Manage Events" />} />
							<Route path=":id">
								<Route index element={<RepClubEventDetailPage title="Event" />} />
								<Route path="approval" element={<RepClubEventApprovalPage title="Event" />} />
								<Route path="invite" element={<RepClubEventInvitePage title="Event" />} />
								<Route path="guest" element={<RepClubEventGuestPage title="Event" />} />
							</Route>
						</Route>
						<Route path="team" element={<RepClubTeamPage title="Manage Team" />} />
						<Route path="rep-points" element={<RepClubRepPointsPage title="Manage Rep Points" />} />
						<Route
							path="loyalty-tiers"
							element={<RepClubLoyaltyTiersPage title="Manage Loyalty Tiers" />}
						/>
						<Route path="offers">
							<Route index element={<RepClubOffersPage title="Manage Offers" />} />
							<Route path="create" element={<RepClubOffersCreatePage title="Add Offer" />} />
						</Route>
						<Route path="achievements">
							<Route index element={<RepClubAchievementsPage title="Achievements" />} />
							<Route path="create" element={<RepClubAchievementCreatePage title="Add Achievement" />} />
							<Route path=":id">
								<Route index element={<RepClubAchievementDetailPage title="Achievement" />} />
								<Route
									path="edit"
									element={<RepClubAchievementCreatePage title="Edit Achievement" />}
								/>
							</Route>
						</Route>
					</Route>
					<Route path="pie-hub">
						<Route
							index
							element={
								<PieHubGuard>
									<PieHubPage title="Pie Hub" />
								</PieHubGuard>
							}
						/>
						<Route
							path="details"
							element={
								<PieHubGuard>
									<PieHubDetailsPage title="Pie details" />
								</PieHubGuard>
							}
						/>
						<Route
							path="appearance"
							element={
								<PieHubGuard>
									<PieHubAppearancePage title="Pie appearance" />
								</PieHubGuard>
							}
						/>
						<Route
							path="slices"
							element={
								<PieHubGuard>
									<PieHubSlicesPage title="Pie slices" />
								</PieHubGuard>
							}
						/>
						<Route
							path="summary"
							element={
								<PieHubGuard>
									<PieHubSummaryPage title="Pie summary" />
								</PieHubGuard>
							}
						/>
						<Route path="overview">
							<Route
								index
								element={
									<PieHubGuard>
										<PieHubOverviewPage title="Pie overview" />
									</PieHubGuard>
								}
							/>
							<Route
								path="edit/:id"
								element={
									<PieHubGuard>
										<PieHubOverviewEditPage title="Pie edit details" />
									</PieHubGuard>
								}
							/>
						</Route>
						<Route
							path="sales"
							element={
								<PieHubGuard>
									<PieHubSalesPage title="Pie sales" />
								</PieHubGuard>
							}
						/>
						<Route
							path="send/:id"
							element={
								<PieHubGuard>
									<PieHubSendPage title="Pie send" />
								</PieHubGuard>
							}
						/>
						<Route path="rewards">
							<Route
								index
								element={
									<PieHubGuard>
										<PieHubRewardsPage title="Rewards" />
									</PieHubGuard>
								}
							/>
							{/* <Route
								path="create"
								element={
									<PieHubGuard>
										<PieHubRewardCreatePage title="Create Reward" />
									</PieHubGuard>
								}
							/> */}
							<Route
								path=":id"
								element={
									<PieHubGuard>
										<RewardDetailPage title="Reward" />
									</PieHubGuard>
								}
							/>
							{/* <Route
								path="edit/:id"
								element={
									<PieHubGuard>
										<PieHubRewardCreatePage title="Edit Reward" />
									</PieHubGuard>
								}
							/> */}
						</Route>
						<Route path="achievements">
							<Route
								index
								element={
									<PieHubGuard>
										<PieHubAchievementsPage title="Achievements" />
									</PieHubGuard>
								}
							/>
							<Route
								path="create"
								element={
									<PieHubGuard>
										<PieHubAchievementCreatePage title="Add Achievement" />
									</PieHubGuard>
								}
							/>
							<Route
								path=":id"
								element={
									<PieHubGuard>
										<PieHubAchievementCreatePage title="Edit Achievement" />
									</PieHubGuard>
								}
							/>
							<Route
								path="edit/:id"
								element={
									<PieHubGuard>
										<PieHubAchievementCreatePage title="Edit Achievement" />
									</PieHubGuard>
								}
							/>
						</Route>
					</Route>
				</Route>
				<Route
					path="accept-invite/:id"
					element={
						<InviteGuard>
							<EmptyLayout />
						</InviteGuard>
					}
				>
					<Route index element={<AcceptInvitePage />} />
				</Route>
				<Route
					path="/payment"
					element={
						<PaymentGuard>
							<PaymentLayout />
						</PaymentGuard>
					}
				>
					<Route path="checkout" element={<PaymentCheckoutPage />} />
					<Route path="buy/:id" element={<PaymentBuyPage />} />
				</Route>
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	)
}
