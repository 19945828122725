import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { usePagination, DOTS } from 'hooks/usePagination'

interface Props {
	currentPage?: number
	totalCount: number
	pageSize?: number
	siblingCount?: number
	onPageChange?: (...args: any) => any
}

export function PaginationBar({
	currentPage = 1,
	totalCount,
	pageSize = 15,
	siblingCount = 1,
	onPageChange,
}: PropsWithChildren<Props>) {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	})

	if (currentPage === 0 || paginationRange.length < 2) {
		return null
	}

	const lastPage = paginationRange[paginationRange.length - 1]

	// const onNext = () => {
	// 	onPageChange?.(currentPage + 1)
	// }

	// const onPrevious = () => {
	// 	onPageChange?.(currentPage - 1)
	// }

	const onFirst = () => {
		onPageChange?.(1)
	}

	const onLast = () => {
		onPageChange?.(lastPage)
	}

	return (
		<div className="flex items-center justify-between">
			<div className="flex-1 flex justify-between sm:hidden">
				<a
					href="/abc"
					className="relative inline-flex items-center p-2 italic rounded-none rounded-tl rounded-br text-center border transition-all duration-300 bg-secondary text-white border-secondary"
				>
					Previous
				</a>
				<a
					href="/abc"
					className="ml-3 relative inline-flex items-center p-2 italic rounded-none rounded-tl rounded-br text-center border transition-all duration-300 bg-secondary text-white border-secondary"
				>
					Next
				</a>
			</div>
			<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
				<div>
					<nav className="relative z-0 inline-flex overflow-hidden" aria-label="Pagination">
						{currentPage !== 1 && (
							<button
								type="button"
								className="text-sm font-bold px-4 py-2 text-white-50 transition-all duration-300 hover:text-white shadow-[inset_0px_-1px_0px_rgba(255,255,255,0.25)] disabled:hover:text-white-50"
								onClick={onFirst}
								disabled={currentPage === 1}
							>
								First
							</button>
						)}
						{paginationRange.map((pageNumber: any, index: number) => {
							if (pageNumber === DOTS) {
								return (
									<span
										key={index + 1}
										className="text-sm font-bold px-4 py-2 text-white-50 transition-all duration-300 shadow-[inset_0px_-1px_0px_rgba(255,255,255,0.25)]"
									>
										&#8230;
									</span>
								)
							}

							return (
								<button
									key={index + 1}
									type="button"
									className={classNames(
										'text-sm font-bold px-4 py-2 text-white-50 transition-all duration-300 hover:text-white shadow-[inset_0px_-1px_0px_rgba(255,255,255,0.25)]',
										{
											'!text-white !shadow-[inset_0px_-2px_0px_#E51A7F]':
												pageNumber === currentPage,
										}
									)}
									onClick={() => onPageChange?.(pageNumber)}
									disabled={pageNumber === currentPage}
								>
									{pageNumber}
								</button>
							)
						})}
						{currentPage !== lastPage && (
							<button
								type="button"
								className="text-sm font-bold px-4 py-2 text-white-50 transition-all duration-300 hover:text-white shadow-[inset_0px_-1px_0px_rgba(255,255,255,0.25)] disabled:hover:text-white-50"
								onClick={onLast}
								disabled={currentPage === lastPage}
							>
								Last
							</button>
						)}
					</nav>
				</div>
			</div>
		</div>
	)
}
