import { PropsWithChildren, Fragment } from 'react'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { IconSvg } from 'components/common'

interface Props {
	open: boolean
	setOpen?: (...args: any) => any
	onSave?: (...args: any) => any
}

const validationSchema = Yup.object().shape({
	slice: Yup.number().typeError('You must specify a number').required('Number of slices is required'),
	price: Yup.number().typeError('You must specify a number').required('Pie price is required'),
})

export function SliceSelectCustomAmountModal({ open, setOpen, onSave }: PropsWithChildren<Props>) {
	const {
		control,
		register,
		handleSubmit,
		formState: { errors, isValid, isDirty },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur', // "onChange"
	})

	const onSubmit = (values: any) => {
		onSave?.(values)
		setOpen?.(false)
	}

	return (
		<Transition show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				onClose={() => {
					setOpen?.(false)
				}}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
					</TransitionChild>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="relative inline-block align-bottom overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[294px] sm:w-full">
							<div className="absolute top-6 right-4">
								<button
									type="button"
									onClick={() => setOpen?.(false)}
									className="w-10 h-10 rounded-full justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none hidden"
								>
									<IconSvg icon="close" className="w-6 h-6" />
								</button>
							</div>
							<div className="modal-content px-6 py-8 pb-10 bg-primary-tile rounded-2xl">
								<div className="modal-header font-medium text-xl leading-6 mb-8">
									<DialogTitle as="h3">Custom amount</DialogTitle>
								</div>
								<div className="modal-body">
									<div className="text-left">
										<form onSubmit={handleSubmit(onSubmit)}>
											<div className="space-y-4">
												<div>
													<label
														htmlFor="slice"
														className="text-white-75 font-medium block text-xs uppercase"
													>
														Number of slices
													</label>
													<div className="mt-2">
														<Controller
															control={control}
															name="slice"
															render={({ field }) => (
																<input
																	id="url"
																	type="text"
																	className="px-4 py-3 border-2 border-white-10 bg-white-10 rounded-lg text-white placeholder-white-50 placeholder:italic placeholder:font-light block w-full h-12"
																	placeholder="Add number"
																	{...field}
																	value={field.value ?? ''}
																/>
															)}
														/>
														{errors.slice && (
															<div className="text-secondary-red mt-2 text-sm">
																{errors.slice.message as string}
															</div>
														)}
													</div>
												</div>
												<div>
													<label
														htmlFor="price"
														className="text-white-75 font-medium block text-xs uppercase"
													>
														Pie price
													</label>
													<div className="mt-2">
														<label className="px-4 py-3 border-2 border-white-10 bg-white-10 rounded-lg text-white placeholder-white-50 placeholder:italic placeholder:font-light w-full h-12 flex items-center">
															<span className="mr-1">$</span>
															<input
																id="price"
																type="text"
																className="bg-transparent focus:ring-0 p-0 border-none flex-1 placeholder-white-50 placeholder:italic placeholder:font-light"
																placeholder="0"
																{...register('price')}
															/>
														</label>
														{errors.price && (
															<div className="text-secondary-red mt-2 text-sm">
																{errors.price.message as string}
															</div>
														)}
													</div>
												</div>
											</div>
										</form>
									</div>
									<div className="mt-10 space-y-2">
										<button
											onClick={handleSubmit(onSubmit)}
											className="w-full h-10 rounded-full flex justify-center items-center transition-all duration-300 bg-primary text-white font-bold hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg"
											disabled={!(isValid && isDirty)}
										>
											Save
										</button>
										<button
											onClick={() => setOpen?.(false)}
											className="w-full h-10 rounded-full flex justify-center items-center transition-all duration-300 bg-white-10 text-white font-bold shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none"
										>
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}
