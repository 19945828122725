import { useSearchParams, Navigate } from 'react-router-dom'

export function SocialProcessGuard({ children }: { children: JSX.Element; extended?: boolean }) {
	const [searchParams] = useSearchParams()
	const token = searchParams.get('token')
	const refreshToken = searchParams.get('refresh_token')
	const expiresIn = searchParams.get('expires_in')
	const socialMediaAccessToken = searchParams.get('social_media_access_token')

	if (!((token && refreshToken && expiresIn) || socialMediaAccessToken)) {
		return <Navigate to="/404" state={{ from: location }} replace />
	}
	return children
}
