import { PropsWithChildren, Fragment } from 'react'
import { Dialog, DialogBackdrop, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import classNames from 'classnames'

import { IconSvg } from 'components/common'

interface Props {
	open: boolean
	title?: string
	setOpen?: (...args: any) => any
	isModal?: boolean
	hideCloseButton?: boolean
	hideHeader?: boolean
	size?: string
	className?: string
	customTopActionBlock?: (...args: any) => any
	onClickClose?: (...args: any) => any
	suppressClickClose?: boolean
	customSize?: string
}

export function ContentModal({
	children,
	open,
	setOpen,
	title = '',
	isModal = false,
	hideCloseButton = false,
	size = '',
	className = '',
	customTopActionBlock,
	onClickClose,
	suppressClickClose,
	hideHeader = false,
	customSize = '',
}: PropsWithChildren<Props>) {
	return (
		<Transition show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-10 inset-0 overflow-y-auto"
				onClose={() => {
					if (!isModal) {
						setOpen?.(false)
					}
				}}
			>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-32 text-center sm:block sm:p-0">
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<DialogBackdrop className="fixed inset-0 bg-black-75 transition-opacity" />
					</TransitionChild>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div
							className={classNames(
								'relative inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-[448px] sm:w-full',
								{ 'sm:!max-w-4xl': size === 'lg', 'sm:!max-w-2xl': size === 'md' },
								customSize
							)}
						>
							{customTopActionBlock ? (
								customTopActionBlock(setOpen)
							) : (
								<div className="absolute top-6 right-4 z-[1]">
									<button
										onClick={() => {
											onClickClose?.()
											if (!suppressClickClose) {
												setOpen?.(false)
											}
										}}
										className={classNames(
											'w-10 h-10 rounded-full flex justify-center items-center bg-white-10 shadow-button backdrop-blur-lg hover:bg-white-25 disabled:bg-white-10 disabled:text-white-25 disabled:shadow-none',
											{ '!hidden': hideCloseButton }
										)}
									>
										<IconSvg icon="close" className="w-6 h-6" />
									</button>
								</div>
							)}
							<div
								className={classNames(
									'modal-content py-8 sm:px-[72px] px-8 bg-primary-tile rounded-2xl',
									className
								)}
							>
								{!hideHeader && (
									<div className="modal-header font-medium text-xl leading-6 mb-8">
										<DialogTitle as="h3">{title}</DialogTitle>
									</div>
								)}
								<div className="modal-body">{children}</div>
							</div>
						</div>
					</TransitionChild>
				</div>
			</Dialog>
		</Transition>
	)
}
