import { useEffect, useRef } from 'react'

export const useOutsideClick = (callback: any) => {
	const ref = useRef(null)

	useEffect(() => {
		const handleClick = (event: any) => {
			const refCurrent = ref.current as HTMLDivElement | null
			if (!refCurrent?.contains(event.target)) {
				callback()
			}
		}

		document.addEventListener('click', handleClick)

		return () => {
			document.removeEventListener('click', handleClick)
		}
	}, [])

	return ref
}
