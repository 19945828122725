import Routers from 'router'
import { Toaster, resolveValue, toast } from 'react-hot-toast'
import clsx from 'clsx'

function App() {
	return (
		<div className="App">
			<Routers />
			<Toaster
				position="top-right"
				toastOptions={{
					duration: 10000,
				}}
			>
				{(t: any) => (
					<div
						className={clsx('p-3 pr-8 rounded-md relative w-[280px]', {
							'bg-white text-danger': t.type === 'error',
							'bg-success': t.type === 'success',
						})}
					>
						<div className="absolute top-3 right-3">
							<button type="button" onClick={() => toast.dismiss(t.id)}>
								<img
									src={`/images/common/toast/icon-close${t.type === 'error' ? '-black' : ''}.svg`}
									className="w-5 h-5"
								/>
							</button>
						</div>
						<div className="flex">
							<div className="w-11 h-11 bg-gradient-to-b from-[rgba(255,255,255,0)] to-[rgba(255,255,255,0.15)] rounded-full flex justify-center items-center flex-shrink-0">
								{t.type === 'success' && <img src="/images/common/toast/icon-check.svg" />}
								{t.type === 'error' && <img src="/images/common/toast/icon-warning.svg" />}
							</div>
							<div className="pl-4 pt-1">
								{t.type === 'success' && (
									<h5 className="text-sm leading-[18px] font-bold">
										{t.title
											? t.title
											: clsx({
													'Success!': t.type === 'success',
													'Oops ...': t.type === 'error',
											  })}
									</h5>
								)}
								<p
									className={clsx('text-xs mt-1', {
										'text-black': t.type === 'error',
									})}
								>
									{resolveValue(t.message, t)}
								</p>
							</div>
						</div>
					</div>
				)}
			</Toaster>
		</div>
	)
}

export default App
