import { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet, NavLink, Link } from 'react-router-dom'
import { Dialog, DialogBackdrop, Transition, TransitionChild } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import { Logo, IconSvg } from 'components/common'
import { NAVIGATION_ITEMS } from 'utils/constants'
import {
	SearchBar,
	ProfileDropdown,
	NotificationDropdown,
	UpdateTermsOfServiceAndPrivacyModal,
} from 'containers/common'
import { useStore } from 'hooks/useStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function PaymentLayout() {
	const { header, hasBackArrow, blur, loadingLogout, user } = useStore()
	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<div className={classNames({ blur: blur })}>
			<Helmet
				bodyAttributes={{
					class: 'bg-primary-background',
				}}
			/>
			<div className="flex min-h-screen max-w-[1440px] mx-auto">
				<Transition show={sidebarOpen} as={Fragment}>
					<Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<DialogBackdrop className="fixed inset-0 bg-black-75" />
						</TransitionChild>
						<TransitionChild
							as={Fragment}
							enter="transition ease-in-out duration-300 transform"
							enterFrom="-translate-x-full"
							enterTo="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leaveFrom="translate-x-0"
							leaveTo="-translate-x-full"
						>
							<div className="relative flex-1 flex flex-col max-w-[232px] w-full bg-primary-tile focus:outline-none">
								<TransitionChild
									as={Fragment}
									enter="ease-in-out duration-300"
									enterFrom="opacity-0"
									enterTo="opacity-100"
									leave="ease-in-out duration-300"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<div className="absolute top-0 right-0 -mr-12 pt-2">
										<button
											type="button"
											className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
											onClick={() => setSidebarOpen(false)}
										>
											<span className="sr-only">Close Sidebar</span>
											<XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
										</button>
									</div>
								</TransitionChild>
								<div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
									<div className="px-4">
										<Logo className="h-7" />
									</div>
									<nav className="flex-1 mt-8" aria-label="Sidebar">
										<div className="space-y-2">
											{NAVIGATION_ITEMS.map(item => (
												<NavLink
													key={item.name}
													to={item.href}
													className={({ isActive }) =>
														`group flex items-center px-4 py-3 transition-all duration-300 font-bold ${
															isActive
																? 'text-white'
																: 'text-secondary-text hover:text-white'
														}`
													}
													reloadDocument
												>
													{({ isActive }) => (
														<>
															<IconSvg
																icon={item.icon}
																className={classNames('mr-2 w-6', {
																	'text-primary': isActive,
																	'text-secondary-text': !isActive,
																})}
																aria-hidden="true"
															/>
															{item.name}
														</>
													)}
												</NavLink>
											))}
										</div>
									</nav>
								</div>
							</div>
						</TransitionChild>
						<div className="flex-shrink-0 w-14" aria-hidden="true">
							{/* Force sidebar to shrink to fit close icon */}
						</div>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div className="hidden lg:flex lg:flex-shrink-0">
					<div className="flex flex-col w-[232px]">
						{/* Sidebar component, swap this element with another sidebar if you like */}
						<div className="flex-1 flex flex-col min-h-0 px-4">
							<div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
								<div className="px-4">
									<Logo className="h-7" />
								</div>
								<nav className="flex-1 mt-8" aria-label="Sidebar">
									<div className="space-y-2">
										{NAVIGATION_ITEMS.map(item => (
											<NavLink
												key={item.name}
												to={item.href}
												className={({ isActive }) =>
													classNames(
														'group flex items-center px-4 py-3 transition-all duration-300 font-bold',
														{
															'text-primary': isActive,
															'text-secondary-text hover:text-primary': !isActive,
															'pointer-events-none text-white-10': item.disabled,
														}
													)
												}
												reloadDocument
											>
												{() => (
													<>
														<IconSvg
															icon={item.icon}
															className={classNames('mr-2 w-6')}
															aria-hidden="true"
														/>
														{item.name}
													</>
												)}
											</NavLink>
										))}
									</div>
								</nav>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col min-w-0 flex-1">
					<div className="lg:flex-none sm:flex justify-between hidden">
						<div className="lg:hidden">
							<div className="flex items-center sm:px-8 py-4 space-x-4 px-4">
								<div>
									<button
										type="button"
										className="h-10 w-10 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
										onClick={() => setSidebarOpen(true)}
									>
										<span className="sr-only">Open Sidebar</span>
										<Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
									</button>
								</div>
								<div>
									<Logo className="h-7" />
								</div>
							</div>
						</div>
						<div className="lg:flex justify-start items-center px-8 py-4 text-white-75 hidden">
							{header !== null && (
								<>
									{hasBackArrow && (
										<div className="mr-2">
											<Link to={-1 as any}>
												<IconSvg icon="chevron-left" />
											</Link>
										</div>
									)}
									<div className="text-2xl h-10 flex items-center">{header}</div>
								</>
							)}
						</div>
						<div className="flex lg:space-x-8 space-x-4 py-4 px-8 items-center">
							<div className="fixed top-4 left-1/2 w-[170px] -ml-[85px] text-center z-[1]">
								{!!user?.userInvitesRemaining && (
									<Link
										to={`/profile/invites`}
										className="px-8 rounded-3xl transition-all duration-300 bg-primary h-[40px] hover:bg-primary-hover disabled:bg-white-25 disabled:text-white-25 disabled:backdrop-blur-lg font-bold flex items-center justify-center gap-x-2"
									>
										<div className="border-2 border-white w-5 h-5 flex items-center justify-center rounded-full text-xs font-bold">
											{user?.userInvitesRemaining || 0}
										</div>
										<div>Pie Invite{user?.userInvitesRemaining > 1 && 's'}</div>
									</Link>
								)}
							</div>
							<div className="flex justify-end">
								<SearchBar />
							</div>
							<div>
								<NotificationDropdown />
							</div>
							<div className="flex-none">
								<ProfileDropdown />
							</div>
						</div>
					</div>
					<Outlet />
				</div>
			</div>
			<UpdateTermsOfServiceAndPrivacyModal />
			{loadingLogout && (
				<div className="flex justify-center items-center py-4 w-full h-full fixed top-0 left-0 bg-primary-panel bg-opacity-90 z-[9999]">
					<FontAwesomeIcon icon={['fas', 'spinner']} size="2x" spin className="text-white" />
				</div>
			)}
		</div>
	)
}
