import { Fragment, useCallback, useEffect, useState } from 'react'
import { useSearchParams, createSearchParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { debounce } from 'lodash'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { Menu, MenuItem, MenuItems, Transition } from '@headlessui/react'

import { AvatarImage, IconSvg, PieImage, SimpleTable } from 'components/common'
import { UserTier, useSearchEventsLazyQuery } from 'graphql/generated/scheme'
import { useOutsideClick } from 'hooks/useOutsideClick'
import { format } from 'date-fns'
import { trueErrorMessage } from 'utils/error'
import { Tooltip } from '@mui/material'

interface Props {
	onHandleSubmit?: (...args: any) => any
	loading?: boolean
	autoSuggestion?: boolean
	focusAnimation?: boolean
	className?: string
	ltr?: boolean
	placeholder?: string
}

const validationSchema = Yup.object().shape({
	q: Yup.string(),
})

export function SearchBar({
	onHandleSubmit,
	loading,
	autoSuggestion,
	focusAnimation,
	className,
	ltr,
	placeholder = 'Search',
}: Props) {
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const q = searchParams.get('q')
	const [keyword, setKeyword] = useState<string | null>(q)
	const [events, setEvents] = useState<Array<any>>([])
	const [openSuggestion, setOpenSuggestion] = useState<boolean>(false)
	const [pageSize] = useState<number>(10)
	const { register, handleSubmit, reset, setFocus } = useForm({
		defaultValues: { q } as any,
		resolver: yupResolver(validationSchema),
		mode: 'onBlur',
	})
	const [getEvents, { loading: loadingGetEvents, refetch: refetchGetEvents, called: calledGetEvents }] =
		useSearchEventsLazyQuery({
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'no-cache',
			onCompleted: data => {
				setEvents(data.event?.search?.nodes || [])
			},
			onError: error => {
				toast.error(trueErrorMessage(error.message))
			},
		})
	const handleClickOutside = () => {
		setOpenSuggestion(false)
	}
	const ref = useOutsideClick(handleClickOutside)
	const debounceDropDown = useCallback(
		debounce((nextValue, isEnterKey) => {
			if (!isEnterKey) {
				if (autoSuggestion) {
					if (!loadingGetEvents) {
						if (nextValue?.trim().length > 0) {
							setOpenSuggestion(true)
							if (calledGetEvents) {
								refetchGetEvents({
									criteria: {
										keyword: nextValue,
										onlyFeatured: false,
										onlyMyEvents: false,
									},
								})
							} else {
								getEvents({
									variables: {
										first: pageSize,
										criteria: {
											keyword: nextValue,
											onlyFeatured: false,
											onlyMyEvents: false,
										},
									},
								})
							}
						} else {
							setEvents([])
						}
					}
				} else {
					if (onHandleSubmit) {
						onHandleSubmit({ q: nextValue })
					}
				}
			}
		}, 1000),
		[]
	)

	useEffect(() => {
		reset({ q: q || '' })
	}, [q])

	const handleInputOnchange = (e: any) => {
		const { value } = e.target
		setKeyword(value)
		debounceDropDown(value, e.key === 'Enter')
	}

	const onSubmit = (values: any) => {
		if (onHandleSubmit) {
			onHandleSubmit(values)
		} else {
			setOpenSuggestion(false)
			navigate({
				pathname: 'search',
				search: createSearchParams({
					q: keyword || values.q || '',
				}).toString(),
			})
		}
	}

	return (
		<div ref={ref}>
			<Menu as="div" className="relative">
				<form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
					<div className="relative">
						<div className="absolute top-1/2 left-2 -mt-3 text-[#FFFFFF80]">
							<IconSvg icon="search" />
						</div>
						<input
							className={classNames(
								'border border-solid !border-[#FFFFFF33] rounded-[20px] placeholder-opacity-60 placeholder-white-50 placeholder:italic placeholder:font-light bg-white-10 px-4 pl-9 h-[40px] lg:w-[293px] sm:w-[252px] hover:border-white-10 transition-all duration-300 w-full focus:ring-0',
								{
									'xl:focus:w-[508px]': !!focusAnimation,
									'xl:w-[508px]': !!focusAnimation && openSuggestion,
								},
								className
							)}
							placeholder={placeholder}
							type="search"
							{...register('q')}
							onKeyUp={handleInputOnchange}
							disabled={loading || loadingGetEvents}
						/>
						<button type="submit" className="invisible absolute -left-[9999]" />
						{!!keyword?.length && (
							<button
								type="reset"
								className="absolute top-1/2 right-4 -mt-3"
								onClick={() => {
									setFocus('q')
									setOpenSuggestion(false)
									onHandleSubmit?.({ q: '' })
									setTimeout(() => {
										setKeyword(null)
									}, 0)
								}}
							>
								<IconSvg icon="close" className="w-6 h-6" />
							</button>
						)}
					</div>
				</form>
				{!!autoSuggestion && (
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
						show={openSuggestion}
					>
						<MenuItems
							className={classNames(
								'absolute rounded-lg shadow-lg bg-[#302645] focus:outline-none mt-2 xl:w-[508px] lg:w-[470px] w-[300px] z-10 py-4 max-h-[90vh] overflow-auto',
								{ 'right-0': !ltr, 'left-0': ltr }
							)}
							static
						>
							<SimpleTable
								noHeader
								columns={[
									{
										content: {
											className: 'col-span-12',
											formatter: (item: any) => {
												return (
													<div className="flex space-x-3 items-start">
														<PieImage
															className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0"
															images={[item.avatarUrl]}
														/>
														<div className="min-w-0 flex-1 space-y-0.5">
															<div
																className="font-medium leading-[normal] truncate"
																title={item.displayName}
															>
																{item.displayName}
															</div>
															<div className="text-secondary-text text-xs font-normal">
																{format(
																	new Date(item.startDate),
																	'MMM d, yyyy hh:mm aa'
																)}
															</div>
															<div className="flex items-center justify-end gap-1 flex-wrap">
																<Tooltip
																	classes={{ popper: 'custom-react-tooltip-mui' }}
																	placement="top"
																	title={
																		<div className="w-36 h-36 flex-auto overflow-hidden rounded-2xl bg-primary-tile text-sm leading-6 shadow-lg ring-1 ring-white-10 flex flex-col items-center justify-center gap-2 p-4">
																			<div className="relative flex-shrink-0">
																				<AvatarImage
																					className="h-10 w-10 rounded-full object-cover"
																					src={item.createdBy?.avatarUrl}
																					alt={item.createdBy?.name}
																				/>
																				{item.createdBy?.isVerified && (
																					<div className="absolute bottom-0 right-0">
																						<IconSvg
																							icon="verified"
																							className={classNames(
																								'text-[#308DE8]',
																								{
																									'!text-yellow-400':
																										UserTier.Premium ===
																										item?.createdBy
																											?.tier,
																								}
																							)}
																						/>
																					</div>
																				)}
																			</div>
																			<div
																				className="text-sm line-clamp-2 text-center"
																				title={item.createdBy?.name as string}
																			>
																				{item.createdBy?.name}
																			</div>
																		</div>
																	}
																>
																	<div className="relative flex-shrink-0">
																		<AvatarImage
																			className="h-6 w-6 rounded-full object-cover"
																			src={item.createdBy?.avatarUrl}
																			alt={item.createdBy?.name}
																		/>
																		{item.createdBy?.isVerified && (
																			<div className="absolute bottom-0 right-0">
																				<IconSvg
																					icon="verified"
																					width={10}
																					height={10}
																					className={classNames(
																						'text-[#308DE8]',
																						{
																							'!text-yellow-400':
																								UserTier.Premium ===
																								item.createdBy.tier,
																						}
																					)}
																				/>
																			</div>
																		)}
																	</div>
																</Tooltip>
																{item.coHosts?.nodes?.map(
																	(tItem: any, index: number) => (
																		<Tooltip
																			key={index}
																			classes={{
																				popper: 'custom-react-tooltip-mui',
																			}}
																			placement="top"
																			title={
																				<div className="w-36 h-36 flex-auto overflow-hidden rounded-2xl bg-primary-tile text-sm leading-6 shadow-lg ring-1 ring-white-10 flex flex-col items-center justify-center gap-2 p-4">
																					<div className="relative flex-shrink-0">
																						<AvatarImage
																							className="h-10 w-10 rounded-full object-cover"
																							src={tItem.avatarUrl}
																							alt={tItem.name}
																						/>
																						{tItem.isVerified && (
																							<div className="absolute bottom-0 right-0">
																								<IconSvg
																									icon="verified"
																									className={classNames(
																										'text-[#308DE8]',
																										{
																											'!text-yellow-400':
																												UserTier.Premium ===
																												tItem.tier,
																										}
																									)}
																								/>
																							</div>
																						)}
																					</div>
																					<div
																						className="text-sm line-clamp-2 text-center"
																						title={tItem.name}
																					>
																						{tItem.name}
																					</div>
																				</div>
																			}
																		>
																			<div className="relative flex-shrink-0">
																				<AvatarImage
																					className="h-6 w-6 rounded-full object-cover"
																					src={tItem.createdBy?.avatarUrl}
																					alt={tItem.createdBy?.name}
																				/>
																				{tItem.createdBy?.isVerified && (
																					<div className="absolute bottom-0 right-0">
																						<IconSvg
																							icon="verified"
																							width={10}
																							height={10}
																							className={classNames(
																								'text-[#308DE8]',
																								{
																									'!text-yellow-400':
																										UserTier.Premium ===
																										tItem.createdBy
																											.tier,
																								}
																							)}
																						/>
																					</div>
																				)}
																			</div>
																		</Tooltip>
																	)
																)}
																{item.promoters?.nodes?.map(
																	(tItem: any, index: number) => (
																		<Tooltip
																			key={index}
																			classes={{
																				popper: 'custom-react-tooltip-mui',
																			}}
																			placement="top"
																			title={
																				<div className="w-36 h-36 flex-auto overflow-hidden rounded-2xl bg-primary-tile text-sm leading-6 shadow-lg ring-1 ring-white-10 flex flex-col items-center justify-center gap-2 p-4">
																					<div className="relative flex-shrink-0">
																						<AvatarImage
																							className="h-10 w-10 rounded-full object-cover"
																							src={tItem.avatarUrl}
																							alt={tItem.name}
																						/>
																						{tItem.isVerified && (
																							<div className="absolute bottom-0 right-0">
																								<IconSvg
																									icon="verified"
																									className={classNames(
																										'text-[#308DE8]',
																										{
																											'!text-yellow-400':
																												UserTier.Premium ===
																												tItem.tier,
																										}
																									)}
																								/>
																							</div>
																						)}
																					</div>
																					<div
																						className="text-sm line-clamp-2 text-center"
																						title={tItem.name}
																					>
																						{tItem.name}
																					</div>
																				</div>
																			}
																		>
																			<div className="relative flex-shrink-0">
																				<AvatarImage
																					className="h-6 w-6 rounded-full object-cover"
																					src={tItem.createdBy?.avatarUrl}
																					alt={tItem.createdBy?.name}
																				/>
																				{tItem.createdBy?.isVerified && (
																					<div className="absolute bottom-0 right-0">
																						<IconSvg
																							icon="verified"
																							width={10}
																							height={10}
																							className={classNames(
																								'text-[#308DE8]',
																								{
																									'!text-yellow-400':
																										UserTier.Premium ===
																										tItem.createdBy
																											.tier,
																								}
																							)}
																						/>
																					</div>
																				)}
																			</div>
																		</Tooltip>
																	)
																)}
															</div>
														</div>
													</div>
												)
											},
										},
									},
								]}
								row={{
									className: 'items-center',
									formatter: (index: number, item: any, htmlContent: any, row: any) => {
										return (
											<MenuItem key={index} as={Fragment}>
												{({ active }) => (
													<button
														className={classNames(
															`hover:bg-white-5 grid grid-cols-12 w-full text-left`,
															{ 'bg-white-5': active },
															row?.className
														)}
														onClick={() => {
															setOpenSuggestion(false)
															navigate(`/events/${item.slug}`)
														}}
													>
														{htmlContent}
													</button>
												)}
											</MenuItem>
										)
									},
								}}
								loading={loading || loadingGetEvents}
								currentPage={0}
								data={events}
								totalCount={10}
								pageSize={10}
								noDataText="No search results"
							/>
						</MenuItems>
					</Transition>
				)}
			</Menu>
		</div>
	)
}
